import { Expose, Transform } from "class-transformer";
import {
  Equals,
  IsArray,
  IsDate,
  IsIP,
  IsInt,
  IsNotEmpty,
  IsOptional,
  IsPositive,
  IsString,
  IsUUID,
  Validate,
} from "class-validator";
import dayjs from "dayjs";
import { ItemIdValidationConstraint } from "~/shared/validation/constraints/ItemIdValidationConstraint";

export class CommentDto {
  @Expose()
  @IsInt()
  @IsPositive()
  id: number;

  @Expose()
  @Equals("comment")
  action: "comment";

  @Expose()
  @IsUUID()
  user: string;

  @Expose()
  @IsDate()
  @Transform(({ value }) => dayjs(value).toDate())
  @Transform(({ value }) => value.toISOString(), { toPlainOnly: true })
  timestamp: Date;

  @Expose()
  @IsOptional()
  @IsIP()
  ip: string;

  @Expose()
  @IsOptional()
  @IsString()
  user_agent: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  collection: string;

  @Expose()
  @Validate(ItemIdValidationConstraint)
  item: string | number;

  @Expose()
  @IsString()
  @IsNotEmpty()
  comment: string;

  @Expose()
  @IsOptional()
  origin: null;

  @Expose()
  @IsArray()
  revisions: unknown[];
}
