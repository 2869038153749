import { type AppEvent, AppEvents } from "~/shared/lib/app-event-bus";
import {
  SystemReaction,
  findContextItemPropertyData,
  findEventPayloadItemPropertyData,
  setContextFieldFilter,
} from "~/entities/system-reactions";
import { QueryFilterOperatorsEnum } from "~/api/data-queries/types";

export default new SystemReaction({
  id: "select-floors-by-building",

  scopes: [
    AppEvents.ITEM_DATA_CHANGED,
    AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW,
    AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW,
  ],

  priority: 0,

  matcher: (event) => {
    const e = event as AppEvent<
      | AppEvents.ITEM_DATA_CHANGED
      | AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW
      | AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW
    >;

    if (e.payload.collectionName !== "building_spaces") return false;

    if (e.event === AppEvents.ITEM_DATA_CHANGED) {
      return "building_uuid" in (e.payload as any).data;
    }

    if (
      e.event === AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW ||
      e.event === AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW
    ) {
      return true;
    }

    return false;
  },

  action: async (event, context) => {
    const isRenderEvent: boolean =
      event.event === AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW ||
      event.event === AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW;

    const sourceData: any = isRenderEvent
      ? findContextItemPropertyData({ fieldPath: "building_uuid" }, context)
      : findEventPayloadItemPropertyData(
          { fieldPath: "building_uuid" },
          event as AppEvent<AppEvents.ITEM_DATA_CHANGED>,
        );

    if (!sourceData) {
      return;
    }

    setContextFieldFilter(
      {
        fieldName: "building_floors_id",
        filterPath: "building_id.uuid",
        filterRule: QueryFilterOperatorsEnum._eq,
        filterValue: sourceData,
      },
      context,
    );

    setContextFieldFilter(
      {
        fieldName: "building_parades_id",
        filterPath: "building_id.uuid",
        filterRule: QueryFilterOperatorsEnum._eq,
        filterValue: sourceData,
      },
      context,
    );
  },
});
