<script lang="ts">
  export default {};
</script>

<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M5 4H4v1h1V4zm0 6H4v1h1v-1zm-1 6h1v1H4v-1zm1-9H4v1h1V7zm-1 6h1v1H4v-1zm1 6H4v1h1v-1zM7 4h1v1H7V4zm1 6H7v1h1v-1zm-1 6h1v1H7v-1zm1-9H7v1h1V7zm-1 6h1v1H7v-1zm1 6H7v1h1v-1zm2-15h1v1h-1V4zm1 6h-1v1h1v-1zm-1 6h1v1h-1v-1zm1-9h-1v1h1V7zm-1 6h1v1h-1v-1zm1 6h-1v1h1v-1zm2-15h1v1h-1V4zm1 6h-1v1h1v-1zm-1 6h1v1h-1v-1zm1-9h-1v1h1V7zm-1 6h1v1h-1v-1zm1 6h-1v1h1v-1zm2-15h1v1h-1V4zm1 6h-1v1h1v-1zm-1 6h1v1h-1v-1zm1-9h-1v1h1V7zm-1 6h1v1h-1v-1zm1 6h-1v1h1v-1zm2-15h1v1h-1V4zm1 6h-1v1h1v-1zm-1 6h1v1h-1v-1zm1-9h-1v1h1V7zm-1 6h1v1h-1v-1zm1 6h-1v1h1v-1z"
    />
  </svg>
</template>
