import { shallowRef, ref, type ComputedRef, watch, computed } from "vue";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import type { OptionItem } from "~/components/DropdownSingleRelationRenderer/types";
import type { CollectionInterface } from "~/api/collections/entities/CollectionInterface";
import { defineQuery } from "~/api/data-queries/defines/query";
import type { QueryFilter, QueryMany } from "~/api/data-queries/types";
import { type IField, useFieldsStore } from "~/entities/field";
import { type IItem, useLazyCollectionItems } from "~/entities/item";
import type { VirtualScrollerProps } from "primevue/virtualscroller";

export function useDropdownItemsController(
  collection: ComputedRef<CollectionInterface | null>,
  fieldInfo: ComputedRef<IField | undefined>,
) {
  const fieldsStore = useFieldsStore();
  const {
    collectionItems,
    load: loadItems,
    isLoading: isItemsLoading,
  } = useLazyCollectionItems();

  const collectionFieldNames = computed<string[]>(() =>
    !!collection.value?.id
      ? fieldsStore.getAllByCollection(collection.value!.id).map((field) => field.name)
      : ["*"],
  );

  const filters = ref<QueryFilter<unknown> | null>(null);

  watch(
    () => fieldInfo.value?.meta.options?.filter,
    (newFilters) => {
      filters.value = newFilters || null;
    },
    {
      immediate: true,
    },
  );

  const search = ref<string>("");

  const searchEventListeners = {
    input: (event: InputEvent) => {
      const target = event.target as HTMLInputElement;

      search.value = target.value;
    },
  };

  const itemsRequestQuery = shallowRef<QueryMany<unknown>>(
    createItemsRequestQuery(filters.value || {}, search.value),
  );

  const options = computed<OptionItem[]>(() =>
    collectionItems.value?.map(castCollectionItemToOption),
  );

  const dropdownEventListeners = {
    "before-show": async () => {
      if (!collectionItems.value || !collectionItems.value.length) {
        await debouncedUpdateItems();
      }
    },
  };

  watch(
    () => [search.value, filters.value],
    (newData) => {
      const [newSearch, newFilters] = newData;

      itemsRequestQuery.value = createItemsRequestQuery(
        newFilters as QueryFilter<unknown>,
        newSearch as string,
      );
    },
  );

  const debouncedUpdateItems = debounce(
    async () => {
      await loadItems(collection.value?.id ?? "", itemsRequestQuery.value);
    },
    500,
    {
      leading: true,
    },
  );

  watch(
    () => collection.value,
    (newCollection, prevCollection) => {
      if (!newCollection) {
        return;
      }
      if (newCollection === prevCollection) {
        return;
      }

      debouncedUpdateItems();
    },
    {
      immediate: true,
    },
  );

  watch(
    () => itemsRequestQuery.value,
    (newQuery, prevQuery) => {
      const isQueryEqual = isEqual(newQuery, prevQuery);
      if (isQueryEqual) {
        return;
      }

      debouncedUpdateItems();
    },
  );

  const virtualScrollerOptions = shallowRef<VirtualScrollerProps>({
    lazy: true,
    showLoader: false,
    appendOnly: true,
    numToleratedItems: 0,
    loaderDisabled: true,
    orientation: "vertical",
    itemSize: 42,
  });

  watch(
    () => isItemsLoading.value,
    (isLoading) => {
      virtualScrollerOptions.value = {
        ...virtualScrollerOptions.value,
        loading: isLoading,
      };
    },
    {
      immediate: true,
    },
  );

  return {
    items: computed(() => collectionItems.value),
    options,
    search: computed(() => search.value),
    filters: computed(() => filters.value),
    virtualScrollerOptions,
    isLoading: isItemsLoading,
    updateItems: debouncedUpdateItems,
    searchEventListeners,
    dropdownEventListeners,
  };

  function createItemsRequestQuery(
    filters: QueryMany<unknown>["filter"],
    search: QueryMany<unknown>["search"] | undefined,
  ): QueryMany<unknown> {
    return defineQuery({
      search: search,
      filter: {
        ...filters,
      },
      fields: collectionFieldNames.value,
    });
  }

  function castCollectionItemToOption(item: IItem): OptionItem {
    return {
      label: String(item.id) || "",
      value: item.id,
    };
  }
}
