import routerOptions0 from "/var/intelvision/digibms-portal/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/intelvision/digibms-portal/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}