import { type IItem } from "~/entities/item";
import { Settings } from "./settings";
import type { SettingsInterface, AppSettingsResponse } from "../lib/types";

export function castItemToSettings(item: IItem): SettingsInterface {
  return new Settings(
    item.collection,
    item.data as AppSettingsResponse,
    item.meta,
    item.isNew,
  );
}
