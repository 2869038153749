import PhotoSwipe from "photoswipe";
import { convertHeicToJpeg } from "~/shared/lib/file";
import type { DropzoneExistingMockedFile } from "~/shared/ui/DropzoneUploader/lib";
import { readSlideDataFromFile } from "./read-slide-data-from-file";
import type { IPhotoSwipeBuilder } from "./types";

export class PhotoSwipeBuilder implements IPhotoSwipeBuilder {
  private photoSwipe: PhotoSwipe;

  private defaultParams = {
    initialZoomLevel: 1,
    secondaryZoomLevel: 3,
    maxZoomLevel: 5,
    wheelToZoom: true,
    zoom: true,
  };

  private imageDefaultParams = {
    width: 640,
    height: 640,
  };

  constructor() {
    this.reset();
  }

  reset() {
    this.photoSwipe = new PhotoSwipe({
      ...this.defaultParams,
    });
    return this;
  }

  addImage(file: DropzoneExistingMockedFile): this {
    const imageParams = {
      ...this.imageDefaultParams,
      src: file.dataURL,
      alt: file.name,
      meta: {
        uuid: file.upload?.uuid,
      },
    };

    if (Array.isArray(this.photoSwipe.options.dataSource)) {
      this.photoSwipe.options.dataSource.push(imageParams);
      return this;
    }

    this.photoSwipe.options.dataSource = [imageParams];
    return this;
  }

  async addHeicImage(file: File): Promise<this> {
    const convertedFile = await convertHeicToJpeg(file);

    const slideData = await readSlideDataFromFile(convertedFile);

    const imageParams = { ...this.imageDefaultParams, ...slideData };

    if (Array.isArray(this.photoSwipe.options.dataSource)) {
      this.photoSwipe.options.dataSource.push(imageParams);
      return this;
    }

    this.photoSwipe.options.dataSource = [imageParams];
    return this;
  }

  getPhotoSwipe(): PhotoSwipe {
    const result: PhotoSwipe = this.photoSwipe;
    this.reset();
    return result;
  }
}
