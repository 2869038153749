import type { TreeNode } from "primevue/tree";
import type { TreeNodeCheckboxData } from "./types";

export const defineTreeNode = (node: TreeNode): TreeNode => {
  return node;
};

export const defineTreeNodeCheckboxData = (
  data: TreeNodeCheckboxData,
): TreeNodeCheckboxData => {
  return data;
};
