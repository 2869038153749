import { getItemRoute, type ItemID } from "~/entities/item";
import type { Router } from "vue-router";

/**
 * Открывает  новую вкладку с выбранным элементом.
 *
 * @param {string} collectionName наименование коллекции.
 * @param {ItemID} itemId идентификатор элемента коллеции.
 * @param {Router} router инстанс роутера.
 */
export function routeToItem(
  collectionName: string,
  itemId: ItemID,
  router: Router,
): void {
  const routeTo = getItemRoute(collectionName, itemId);
  const routePath = router.resolve(routeTo).href;

  window.open(routePath, "_blank");
}
