/**
 *
 * @param {string} string
 * @returns {string|false} string for translate with intl or false if is not translate string
 */
export const getTranslateString = (
  string: string,
): string | [string, unknown] | false => {
  const regexSearchString = /(?<=\$t:)\w+/g;
  const translateString = regexSearchString.exec(string)?.[0] ?? null;

  if (translateString === null) {
    return false;
  }

  const regexSearchParams = /(?<=\$t:\w+\s).*$/g;
  const paramsResult = regexSearchParams.exec(string)?.[0] ?? null;

  if (!paramsResult) {
    return translateString;
  }

  return [translateString as string, paramsResult as unknown];
};
