import { useNuxtApp } from "#app";
import { logger } from "~/service/logger/logger";
import { ToastSeverities } from "~/service/toaster/configs/toasts";
import { toaster } from "~/service/toaster";

export async function requestNotificationsPermission(): Promise<boolean> {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") return true;
    return false;
  } catch (err) {
    if (err instanceof Error) {
      logger().info(
        { err },
        `[web-permissions]: notification permission was denied. Reason: ${err.message}`,
      );
    }

    const {
      $i18n: { t },
    } = useNuxtApp();

    toaster().open({
      type: ToastSeverities.WARNING,
      message: t("error.not_enough_permission.to_notify"),
    });
    return false;
  }
}
