import { Russian as FlatpickrRu } from "flatpickr/dist/l10n/ru";
import FlatpickrDefault from "flatpickr/dist/l10n/default";

const I18nPrimevueLocales = {
  "ru-RU": "ru-RU",
  "en-EN": "en-EN",
};

const getPrimevueLocale = (i18nLocale: string): string | undefined => {
  if (i18nLocale in I18nPrimevueLocales) {
    return I18nPrimevueLocales[i18nLocale as keyof typeof I18nPrimevueLocales];
  }

  return undefined;
};

const getPrimevueFallbackLocale = (): string => I18nPrimevueLocales["en-EN"];

export const getPrimevueLocaleStrings = async (i18nLocale: string): Promise<any> => {
  let primevueLocale = getPrimevueLocale(i18nLocale);
  if (primevueLocale === undefined) {
    primevueLocale = getPrimevueFallbackLocale();
  }

  const primevueLocaleStrings = await import(
    `../../../lang/primevue/${primevueLocale}.json`
  );
  return primevueLocaleStrings;
};

const I18nFlatpickLocales = {
  "ru-RU": "ru",
  "en-EN": "en",
};

const getFlatpickrLocale = (i18nLocale: string): string | undefined => {
  if (i18nLocale in I18nFlatpickLocales) {
    return I18nFlatpickLocales[i18nLocale as keyof typeof I18nFlatpickLocales];
  }

  return undefined;
};

const getFlatpickrFallbackLocale = (): string => I18nFlatpickLocales["en-EN"];

export const getFlatpickrLocaleStrings = async (i18nLocale: string): Promise<any> => {
  let flatpickrLocale = getFlatpickrLocale(i18nLocale);
  if (flatpickrLocale === undefined) {
    flatpickrLocale = getFlatpickrFallbackLocale();
  }

  switch (flatpickrLocale) {
    case I18nFlatpickLocales["en-EN"]:
      return FlatpickrDefault;

    case I18nFlatpickLocales["ru-RU"]:
      return FlatpickrRu;
  }
};

enum I18nDayjsLocales {
  "ru-RU" = "ru",
  "en-EN" = "en",
}

export const getDayjsLocale = (i18nLocale: string): string | undefined => {
  return I18nDayjsLocales[i18nLocale as keyof typeof I18nDayjsLocales] || undefined;
};

export const getDayjsFallbackLocale = (): string => "en";

/**
 * with vite dynamic dayjs locale import throws e.default is undefined
 */
// export const loadDayjsLocale = async (i18nLocale: string): Promise<void> => {
//   if (window === undefined) {
//     logger().error(
//       `unable to load dayjs locale. Window is undefined. Try to call load later on lifecycle`,
//     );
//     return;
//   }

//   const locale = getDayjsLocale(i18nLocale);

//   if (locale === undefined) {
//     logger().warn({ i18nLocale }, `not found dayjs locale for given locale`);
//     return;
//   }

//   try {
//     await import(`../../../node_modules/dayjs/locale/${locale}.js`);
//   } catch (err) {
//     logger().error({ err }, `unable to load locale ${locale}`);
//   }
// };
