import type { AuthCodeLoginResponseSuccess } from "~/entities/authentication";
import type { AuthenticationData } from "@directus/sdk";

export function castCodeAuthResultToAuthData(
  dto: AuthCodeLoginResponseSuccess,
): AuthenticationData {
  return {
    access_token: dto.accessToken,
    refresh_token: dto.refreshToken,
    expires: dto.expires,
    expires_at: dto.expiresAt,
  };
}
