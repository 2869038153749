import type { ISystemReaction } from "~/entities/system-reactions";

export default async function (): Promise<ISystemReaction[]> {
  const reactions: ISystemReaction[] = [];
  const localReactions = import.meta.glob<ISystemReaction>(["./*/index.ts"], {
    eager: true,
  });

  for (const path in localReactions) {
    const bundleReactions = Object.values(localReactions[path]);
    if (!bundleReactions.length) continue;

    reactions.push(...bundleReactions);
  }

  return reactions;
}
