import "reflect-metadata";
import { defineNuxtRouteMiddleware } from "#app";
import { iocContainer } from "~/inversify.config";
import { AppEventBus, defineAppEvent, AppEvents } from "~/shared/lib/app-event-bus";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";

export default defineNuxtRouteMiddleware((to, from) => {
  const appEventBus = iocContainer.get<AppEventBus>(INJECT_SYMBOLS.AppEventBus);

  appEventBus.dispatch(
    defineAppEvent({
      event: AppEvents.ROUTE_CHANGE,
      payload: {
        to,
        from,
      },
    }),
  );
});
