export enum NoticeSeverityEnum {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warn",
  ERROR = "error",
}

export type NoticeMessage = {
  severity: NoticeSeverityEnum;
  message: string;
};
