import { type AppEvent, AppEvents } from "~/shared/lib/app-event-bus";
import {
  SystemReaction,
  setContextItemPropertyDataToEmpty,
} from "~/entities/system-reactions";

export default new SystemReaction({
  id: "reset-floors-and-parades-on-building-change",

  priority: 0,

  scopes: [AppEvents.ITEM_DATA_CHANGED],

  matcher: (event) => {
    const e = event as AppEvent<AppEvents.ITEM_DATA_CHANGED>;
    return (
      e.payload.collectionName === "building_spaces" && "building_uuid" in e.payload.data
    );
  },

  action: async (event, context) => {
    setContextItemPropertyDataToEmpty({ fieldPath: "building_parades_id" }, context);
    setContextItemPropertyDataToEmpty({ fieldPath: "building_floors_id" }, context);
  },
});
