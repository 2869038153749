import { type Ref, watch } from "vue";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { type IField } from "~/entities/field";
import { AbstractIFrameFieldAppHandler } from "../lib/app-handlers/abstract-app-handler.abstract";

export function useIframeFieldAppHandlers(
  iframeRef: Ref<HTMLIFrameElement | null>,
  field: Ref<IField | undefined>,
) {
  const grafanaAppHandler = iocContainer.get<AbstractIFrameFieldAppHandler>(
    INJECT_SYMBOLS.IFrameFieldGrafanaAppHandler,
  );

  watch(
    () => [iframeRef.value, field.value],
    ([iframeElement, field]) => {
      if (!iframeElement || !field) {
        return;
      }

      grafanaAppHandler.handle(iframeElement as HTMLIFrameElement, field as IField);
    },
    {
      immediate: true,
    },
  );

  return {};
}
