import { useNuxtApp } from "#app";
import { type RouteLocationRaw } from "vue-router";
import { RouteParameterNames, RoutePathNames } from "~/app/router.options";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { type ItemID } from "~/entities/item";

export class ItemsCommitApplicationController {
  private _events: ControllerEvents = {};

  load(): void {
    const { $i18n } = useNuxtApp();

    this._registerEvent(
      ItemCommitEvents.CREATED,
      () => {
        toaster().success($i18n.t("item_created_success"));
      },
      (payload) => {
        if (payload.collectionName === "directus_users") {
          return {
            path: `/c/directus_users/${payload.itemId}`,
          };
        }

        return {
          name: RoutePathNames.ITEM_PAGE,
          params: {
            [RouteParameterNames.COLLECTION_NAME]: payload.collectionName,
            [RouteParameterNames.COLLECTION_ITEM_ID]: payload.itemId,
          },
        };
      },
    );

    this._registerEvent(
      ItemCommitEvents.UPDATED,
      () => {
        toaster().success($i18n.t("item_updated_success"));
      },
      null,
    );
  }

  getResponse(
    event: ItemCommitEvents,
    payload: AppControllerRequestPayload,
  ): ControllerResponse | undefined {
    const target = this._events[event];
    if (!target) {
      logger().warn(
        { event },
        `unable to get app controller response. Event not registered`,
      );
      return undefined;
    }

    return {
      command: target.command,
      view:
        typeof target.viewCommand === "function"
          ? target.viewCommand(payload)
          : target.viewCommand,
    };
  }

  private _registerEvent(
    event: ItemCommitEvents,
    command: ControllerResponse["command"],
    viewCommand:
      | ((payload: AppControllerRequestPayload) => RouteLocationRaw | string)
      | null,
  ): void {
    if (event in this._events) {
      return;
    }

    this._events[event] = defineAppControllerEvent({
      command,
      viewCommand,
    });
  }
}

const defineAppControllerEvent = (event: AppControllerEvent): AppControllerEvent => {
  return event;
};

export enum ItemCommitEvents {
  CREATED = "created",
  UPDATED = "updated",
}

export interface AppControllerRequestPayload {
  collectionName: string;
  itemId: ItemID;
}

interface AppControllerEvent {
  command: (payload: AppControllerRequestPayload) => void;
  viewCommand:
    | ((payload: AppControllerRequestPayload) => RouteLocationRaw | string)
    | null;
}

type ControllerResponse = {
  command: (payload: AppControllerRequestPayload) => void;
  view: RouteLocationRaw | string | null;
};

type ControllerEvents = {
  [key in ItemCommitEvents]?: AppControllerEvent;
};
