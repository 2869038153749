export const INJECT_SYMBOLS = {
  CollectionsQueryGatewayInterface: Symbol.for("CollectionsQueryGatewayInterface"),
  ItemsWriteGatewayInterface: Symbol.for("ItemsWriteGatewayInterface"),
  IItemsGateway: Symbol.for("IItemsGateway"),
  ItemCommitWorkInterface: Symbol.for("ItemCommitWorkInterface"),
  IRevisionGateway: Symbol.for("IRevisionGateway"),
  CommentsDataGatewayInterface: Symbol.for("CommentsDataGatewayInterface"),
  DatastudioGraphqlClient: Symbol.for("DatastudioGraphqlClient"),
  UsersGatewayInterface: Symbol.for("UsersGatewayInterface"),
  LayoutPresetsDataGatewayInterface: Symbol.for("LayoutPresetsDataGatewayInterface"),
  FeaturesRepositoryInterface: Symbol.for("FeaturesRepositoryInterface"),
  StorageRelationsCache: Symbol.for("StorageRelationsCache"),
  AppEventBus: Symbol.for("AppEventBus"),
  DatastudioRestClient: Symbol.for("DatastudioRestClient"),
  RelationsGateway: Symbol.for("RelationsGateway"),
  PortalMenuGateway: Symbol.for("PortalMenuGateway"),
  LayoutPresetsMapper: Symbol.for("LayoutPresetsMapper"),
  PermissionsMapperInterface: Symbol.for("PermissionsMapperInterface"),
  PermissionsGatewayInterface: Symbol.for("PermissionsGatewayInterface"),
  Logger: Symbol.for("Logger"),
  RolesGatewayInterface: Symbol.for("RolesGatewayInterface"),
  ItemReactionsController: Symbol.for("ItemReactionsController"),
  MessagingTokenController: Symbol.for("MessagingTokenController"),
  StorageAuthDriver: Symbol.for("StorageAuthDriver"),
  AuthGatewayInterface: Symbol.for("AuthGatewayInterface"),
  AuthByPhoneServiceInterface: Symbol.for("AuthByPhoneServiceInterface"),
  PushTokenStorageDriver: Symbol.for("PushTokenStorageDriver"),
  BreadcrumbsController: Symbol.for("IBreadcrumbsController"),
  BreadcrumbsCollectiontHandler: Symbol.for("BreadcrumbsCollectionHandler"),
  PageViewGateway: Symbol.for("PageViewGateway"),
  GrafanaDomFinder: Symbol.for("GrafanaDomFinder"),
  IFrameSizer: Symbol.for("IFrameSizer"),
  IFrameGrafanaDynamicHeightHandler: Symbol.for("IFrameGrafanaDynamicHeightHandler"),
  IFrameFieldGrafanaAppHandler: Symbol.for("IFrameFieldGrafanaAppHandler"),
  IFrameGrafanaHidePanelTitleMenusHandler: Symbol.for(
    "IFrameGrafanaHidePanelTitleMenusHandler",
  ),
};
