<template>
  <div class="flex" :style="styles">
    <span v-if="computedLabel">{{ normalizeTranslationString(computedLabel) }}</span>
    <template v-else>
      <div class="flex">
        <i v-if="fieldData" class="fa-solid fa-check"></i>
        <i v-else class="fa-solid fa-xmark"></i>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  //  ToDo: реализовать отрисовку иконок, полученных из directus

  import { computed } from "vue";
  import { normalizeTranslationString } from "~/shared/lib/string";
  import { type FieldDisplayProp } from "../types";

  const props = defineProps<FieldDisplayProp>();

  const fieldData = computed<unknown>(() => props.item.getDataProperty(props.fieldKey));

  const computedLabel = computed((): string | undefined => {
    const options = props.fieldInfo?.meta?.displayOptions;

    if (!options) {
      return undefined;
    }

    return fieldData.value ? options.labelOn : options.labelOff;
  });

  const styles = computed((): Record<string, any> => {
    const style: Record<string, any> = {};
    const options = props.fieldInfo?.meta?.displayOptions;

    if (options?.colorOn !== null && options?.colorOff !== null) {
      style["color"] = fieldData.value ? options?.colorOn : options?.colorOff;
      style["--v-icon-color"] = fieldData.value ? options?.colorOn : options?.colorOff;
    }

    return style;
  });
</script>
