<template>
  <div class="py-5 md:py-0">
    <TheHeader
      v-if="isHeaderShown"
      layout="side-menu"
      class="z-[100]"
      @logout="onLogout"
    />

    <div class="flex overflow-hidden">
      <SideBar v-if="isMenusShown" :menu="treeMenuItems" />

      <component :is="isMenusShown ? SideMenuContent : SideMenuContentFullWidth">
        <NuxtPage />
      </component>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { useRoute } from "vue-router";
  import { useI18n } from "vue-i18n";
  import { useConfirm } from "primevue/useconfirm";
  import { RouteMetaFields, RoutePathNames } from "~/app/router.options";
  import { useAuthStore } from "~/stores/auth";
  import { usePortalMenuStore } from "~/api/menu/stores/portalMenuStore";
  import { MenuDto } from "~/api/menu/dto/Menu.dto";
  import { TheHeader } from "~/widgets/header";
  import SideBar from "~/components/SideBar/SideBar.vue";
  import { useUserStore } from "~/entities/user";
  import SideMenuContent from "./SideMenuContent.vue";
  import SideMenuContentFullWidth from "./SideMenuContentFullWidth.vue";

  const route = useRoute();
  const { t } = useI18n();
  const authStore = useAuthStore();
  const usersStore = useUserStore();
  const portalMenuStore = usePortalMenuStore();
  const confirm = useConfirm();

  const treeMenuItems = computed<MenuDto[]>(() => portalMenuStore.itemsAsFormattedTree);

  const isLoginPage = computed<boolean>(() => route.name === RoutePathNames.LOGIN);

  const isAuthenticated = computed<boolean>(() => {
    return !!usersStore.me;
  });

  const isHeaderShown = computed<boolean>(() => {
    if (!isAuthenticated.value && route.meta[RouteMetaFields.IS_AUTH_REQUIRED] === false)
      return false;

    return !isLoginPage.value;
  });

  const isMenusShown = computed<boolean>(() => {
    if (!isAuthenticated.value && route.meta[RouteMetaFields.IS_AUTH_REQUIRED] === false)
      return false;

    if (route.meta[RouteMetaFields.LAYOUT_FULL_WIDTH]) {
      return false;
    }

    return !isLoginPage.value;
  });

  const onLogout = async () => {
    confirm.require({
      header: t("confirmation_header"),
      message: t("confirmation_message_logout"),
      accept: () => {
        authStore.logout();
      },
    });
  };
</script>
