<script lang="ts">
  export default {};
</script>

<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M10.588 13.412c.408.408.879.611 1.412.611.533 0 1.004-.203 1.412-.611.408-.408.611-.879.611-1.412 0-.533-.203-1.004-.611-1.412-.408-.408-.879-.612-1.412-.612-.533 0-1.004.204-1.412.612-.408.408-.612.879-.612 1.412 0 .533.204 1.004.612 1.412zM9.176 9.176C9.961 8.392 10.902 8 12 8c1.098 0 2.04.392 2.823 1.176C15.608 9.961 16 10.902 16 12c0 1.098-.392 2.04-1.177 2.823C14.04 15.608 13.098 16 12 16c-1.098 0-2.04-.392-2.824-1.177C8.392 14.04 8 13.098 8 12c0-1.098.392-2.04 1.176-2.824z"
    />
    <path d="M13 3v6h-2V3h2zM13 15v6h-2v-6h2z" />
  </svg>
</template>
