import { defineNuxtPlugin } from "#app";
import { iocContainer } from "~/inversify.config";
import type { FeaturesRepositoryInterface } from "~/api";
import { DatatableFeature } from "~/entities/datatable";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";

export default defineNuxtPlugin(async () => {
  const featuresRepository = iocContainer.get<FeaturesRepositoryInterface>(
    INJECT_SYMBOLS.FeaturesRepositoryInterface,
  );

  const datatableFeature = new DatatableFeature();
  await featuresRepository
    .addFeature(datatableFeature)
    .registerFeature(datatableFeature.id);
});
