import "reflect-metadata";
import { inject, injectable } from "inversify";
import { RouteParameterNames, RoutePathNames } from "~/app/router.options";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import type { Logger } from "pino";
import type { RouteLocationNormalizedLoaded } from "vue-router";
import type { IBreadcrumbsHandler } from "../handlers";
import type { BreadcrumbsLink } from "../../lib/types";
import type { IBreadcrumbsController } from "./types";

@injectable()
export class BreadcrumbsController implements IBreadcrumbsController {
  constructor(
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
    @inject(INJECT_SYMBOLS.BreadcrumbsCollectiontHandler)
    private readonly _collectionHandler: IBreadcrumbsHandler,
  ) {}

  handle(route: RouteLocationNormalizedLoaded): BreadcrumbsLink[] | false {
    try {
      if (route.name === RoutePathNames.HOME) return false;

      if (route.params[RouteParameterNames.COLLECTION_NAME]) {
        return this._collectionHandler.handleBreadcrumbs(route);
      }
      return false;
    } catch (error) {
      this._logger.error({ error, route }, `[BreadcrumbsController]: onHandle error`);
      return false;
    }
  }
}
