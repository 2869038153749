/* eslint-disable no-console */
import type { MessagePayload } from "firebase/messaging/sw";
import type { PushNotification } from "../../model/types";

export const createNotificationFromMessage = (
  message: MessagePayload,
): PushNotification | false => {
  if (!message.notification) {
    console.warn(
      `Can't create Push Notification. Not found "notification" property in payload.`,
      message,
    );
    return false;
  }

  return {
    body: message.notification.body || "",
    data: message.data || undefined,
    icon: message.data?.icon || "",
    lang: message.data?.lang || "",
    silent: !!message.data?.silent || null,
    tag: message.data?.tag || "",
    title: message.notification.title || "",
  };
};
