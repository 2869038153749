import { useNuxtApp } from "#app";
import { defineStore } from "pinia";
import type { PermissionsGatewayInterface } from "~/api/permissions/gateway/PermissionsGatewayInterface";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { toaster } from "~/service/toaster";
import {
  PermissionAction,
  type PermissionInterface,
} from "~/api/permissions/entity/PermissionInterface";
import { useUserStore } from "~/entities/user";
import type { Logger } from "pino";

export const PERMISSIONS_STORE_ID = "permissionsStore";

export const usePermissionsStore = defineStore(PERMISSIONS_STORE_ID, {
  state(): State {
    return {
      permissions: [],
    };
  },

  actions: {
    async initializePublic(): Promise<void> {
      const { $i18n } = useNuxtApp();
      const logger = iocContainer.get<Logger>(INJECT_SYMBOLS.Logger);

      try {
        const permissionsGateway = iocContainer.get<PermissionsGatewayInterface>(
          INJECT_SYMBOLS.PermissionsGatewayInterface,
        );

        const permissions = await permissionsGateway.getPublicPermissions();

        this.$patch({
          permissions,
        });

        logger.debug(`permissions was set for user role - public.`);
      } catch (err) {
        logger.error({ err }, `permissions wasn't set.`);
        toaster().error($i18n.t("error_fetch_system_data"));
      }
    },

    async initialize(): Promise<void> {
      const { $i18n } = useNuxtApp();
      const logger = iocContainer.get<Logger>(INJECT_SYMBOLS.Logger);

      try {
        const userStore = useUserStore();
        const userRoleId = userStore.me?.role.id as string | undefined;

        if (!userRoleId) {
          throw new Error("user role id not found.");
        }

        const permissionsGateway = iocContainer.get<PermissionsGatewayInterface>(
          INJECT_SYMBOLS.PermissionsGatewayInterface,
        );

        const permissions = await permissionsGateway.getAllByRoleId(userRoleId);

        this.$patch({
          permissions,
        });

        logger.debug(`permissions was set for user role '${userRoleId}'.`);
      } catch (err) {
        logger.error({ err }, `permissions wasn't set.`);
        toaster().error($i18n.t("error_fetch_system_data"));
      }
    },

    async deinitialize(): Promise<void> {
      this.$reset();
    },

    /**
     * Проверяет, имеет ли текущий пользователь доступ к определенному действию с коллекции
     *
     * @param {string} collectionName
     * @param {string} action
     * @return {boolean} boolean
     */
    hasCollectionPermission(collectionName: string, action: PermissionAction): boolean {
      const { isAdmin } = useUserStore();

      if (isAdmin()) return true;

      return !!this.permissions.find(
        (permission) =>
          permission.collectionName === collectionName && permission.action === action,
      );
    },

    /**
     * Возвращает массив полей, доступных для работы с текущим доступом пользователя
     *
     * @param {string} collectionName
     * @param {string} action
     * @return {string[]} string[]
     */
    getPermittedFields(collectionName: string, action: PermissionAction): string[] {
      const { isAdmin } = useUserStore();

      if (!this.permissions.length) return [];

      if (isAdmin()) return ["*"];

      return (
        this.permissions.find(
          (permission: PermissionInterface) =>
            permission.collectionName === collectionName && permission.action === action,
        )?.fields ?? []
      );
    },
    /**
     * Проверка на наличие прав доступа к определенному полю с определенным действием
     *
     * @param {string} collectionName
     * @param {string} fieldName
     * @param {string} action
     * @return {boolean} boolean
     */
    hasPermissionsOnField(
      collectionName: string,
      fieldName: string,
      action: PermissionAction,
    ): boolean {
      const { isAdmin } = useUserStore();
      if (isAdmin()) return true;

      const permittedFields = this.getPermittedFields(collectionName, action);
      if (permittedFields[0] === "*") return true;
      return permittedFields.includes(fieldName);
    },
  },
});

interface State {
  permissions: PermissionInterface[];
}
