import { type SlideData } from "photoswipe";
import { convertFileToDataURL } from "~/shared/lib/file";

export const readSlideDataFromFile = async (file: File): Promise<SlideData> => {
  const dataURL = await convertFileToDataURL(file);

  return {
    src: dataURL,
    alt: file.name,
    meta: {
      // @ts-expect-error
      uuid: file.upload?.uuid,
    },
  };
};
