import { logger } from "~/service/logger/logger";

export async function registerServiceWorker(): Promise<
  ServiceWorkerRegistration | undefined
> {
  if ("serviceWorker" in window.navigator) {
    try {
      return await window.navigator.serviceWorker.register(
        import.meta.env.MODE === "production"
          ? "/service-worker.js"
          : "/dev-sw.js?dev-sw",
        { type: import.meta.env.MODE === "production" ? "classic" : "module" },
      );
    } catch (err) {
      if (err instanceof Error) {
        logger().error(`[app]: service worker wasn't register. Reason: ${err!.message}`);
      }
      return undefined;
    }
  } else {
    logger().error(
      `[app]: service worker wasn't register. Reason: browser doesn't support service workers.`,
    );
    return undefined;
  }
}
