/**
 *
 * @param {string} dateString
 * @param {boolean} strict строгий режим включает проверку на наличие наносекунд и
 *  таймзоны
 * @returns {boolean}
 */
export function isISOString(dateString: string, strict: boolean = true): boolean {
  const notStrictISORegex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(\.\d{3})?(Z)?/gm;
  const strictISORegex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})(\.\d{3})(Z)/gm;

  return new RegExp(strict ? strictISORegex : notStrictISORegex).test(dateString);
}
