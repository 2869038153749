<template>
  {{ displayData }}
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { type FieldDisplayProp } from "../types";

  const props = defineProps<FieldDisplayProp>();

  const fieldData = computed<unknown>(() => props.item.getDataProperty(props.fieldKey));

  const displayData = computed<string>(() => {
    const rawData = fieldData.value;

    if (Array.isArray(rawData)) return JSON.stringify(rawData);

    if (typeof rawData === "object" && !!rawData && "currentJunctionItemIds" in rawData)
      return JSON.stringify(rawData.currentJunctionItemIds);

    if (typeof rawData === "object") return JSON.stringify(rawData);

    return rawData as string;
  });
</script>

<style scoped></style>
