import { useNuxtApp } from "#app";
import { getTranslateString } from "~/api/intl/utils/parsers";

/**
 *
 * @param {string[]} rawErrors error messages
 * @returns {string[]} translated errors
 * @example
 *  ```javascript
 *  const result = createTranslatedErrors(["$t:locale_string", "$t:locale_string say, hi", "t:locale_string"])
 *
 *  expect(result[0]).toBe("locale_string");
 *  expect(result[1]).toEqual(["locale_string", "say, hi"]);
 *  expect(result[2]).not.toBeTruthy()
 *  ```
 */
export const createTranslatedErrors = (rawErrors: string[]): string[] => {
  const { $i18n } = useNuxtApp();

  return rawErrors.map((error) => {
    const translateResult = getTranslateString(error);

    if (translateResult === false) {
      return "unknown_validation_error_message";
    }

    if (typeof translateResult === "string") {
      return $i18n.t(translateResult);
    }

    return `${$i18n.t(translateResult[0])} ${translateResult[1]}`;
  });
};
