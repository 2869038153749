import CustomIconDirectus from "./directus.vue";
import CustomIconBookmarkSave from "./bookmark_save.vue";
import CustomIconBox from "./box.vue";
import CustomIconCommitNode from "./commit_node.vue";
import CustomIconGrid1 from "./grid_1.vue";
import CustomIconGrid2 from "./grid_2.vue";
import CustomIconGrid3 from "./grid_3.vue";
import CustomIconGrid4 from "./grid_4.vue";
import CustomIconGrid5 from "./grid_5.vue";
import CustomIconGrid6 from "./grid_6.vue";
import CustomIconSignalWifi1Bar from "./signal_wifi_1_bar.vue";
import CustomIconSignalWifi2Bar from "./signal_wifi_2_bar.vue";
import CustomIconSignalWifi3Bar from "./signal_wifi_3_bar.vue";
import CustomIconFlipHorizontal from "./flip_horizontal.vue";
import CustomIconFlipVertical from "./flip_vertical.vue";
import CustomIconFolderMove from "./folder_move.vue";
import CustomIconFolderLock from "./folder_lock.vue";
import CustomIconLogout from "./logout.vue";

export const components: Record<string, any> = {
  CustomIconDirectus,
  CustomIconBookmarkSave,
  CustomIconBox,
  CustomIconCommitNode,
  CustomIconGrid1,
  CustomIconGrid2,
  CustomIconGrid3,
  CustomIconGrid4,
  CustomIconGrid5,
  CustomIconGrid6,
  CustomIconSignalWifi1Bar,
  CustomIconSignalWifi2Bar,
  CustomIconSignalWifi3Bar,
  CustomIconFlipHorizontal,
  CustomIconFlipVertical,
  CustomIconFolderMove,
  CustomIconFolderLock,
  CustomIconLogout,
};
