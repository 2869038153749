import { inject, injectable } from "inversify";
import { type DirectusClient, type RestClient, readCollections } from "@directus/sdk";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { logger } from "~/service/logger/logger";
import { castCollectionFromDataStudioApiToEntity } from "../casters/castCollectionsFromDataStudioApi";
import type { CollectionInterface } from "../entities/CollectionInterface";
import type { Collection as DataStudioCollection } from "@directus/types";
import type { CollectionsQueryGatewayInterface } from "./CollectionsQueryGatewayInterface";

@injectable()
export class CollectionsQueryGateway implements CollectionsQueryGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private readonly _datastudioClient: DirectusClient<any> & RestClient<any>,
  ) {}

  /**
   *
   * @throws {Error}
   */
  async getAll(): Promise<CollectionInterface[]> {
    try {
      const rawCollections = await this._datastudioClient.request(readCollections());

      return (
        (rawCollections as DataStudioCollection[])?.map(
          castCollectionFromDataStudioApiToEntity,
        ) ?? []
      );
    } catch (err) {
      logger().error({ err }, `unable to receive all collections`);
      throw err;
    }
  }
}
