<template>
  <div v-if="relationInfo && relatedCollection && field">
    <Menu v-if="displayItems.length">
      <Menu.Button :as="Button" class="capitalize">
        {{ $t("datatable_elements_count") }}: {{ displayItems.length }}
      </Menu.Button>

      <Menu.Items class="max-h-48 overflow-auto">
        <Menu.Item
          v-for="item in displayItems"
          :key="item.id"
          as="div"
          class="flex items-center justify-between mb-1 cursor-default"
          @click.stop.prevent="() => {}"
        >
          <RenderTemplate
            :collectionName="relatedCollection.id"
            :item="item"
            :fieldInfo="field"
            :defaultTemplate="displayTemplate ?? ''"
            :context="props.context"
          />

          <Button
            v-if="canNavigateToRelationalItem(relatedCollection)"
            :variant="'soft-secondary'"
            class="ml-2"
            @click="
              routeToItem(
                relatedCollection.id,
                item.getDataProperty(nestedItemPrimaryFieldPath!),
                $router,
              )
            "
          >
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </Button>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  </div>
</template>

<script setup lang="ts">
  import { toRef, computed } from "vue";
  import { useFieldsStore } from "~/entities/field";
  import { useRelationO2M } from "~/api/relations/composables/useRelationO2M";
  import { canNavigateToRelationalItem } from "~/api/collections/utils/availability";
  import { routeToItem } from "~/entities/item";
  import { transformNestedDataToItem } from "~/api/relations/utils/transform-nested-data-to-item";
  import { RenderTemplate } from "~/entities/render-template";
  import Button from "~/shared/ui/Button";
  import Menu from "~/shared/ui/Menu";
  import { type FieldDisplayProp } from "../types";

  const props = defineProps<FieldDisplayProp>();

  const fieldsStore = useFieldsStore();

  const collectionName = toRef(props.collectionName);
  const fieldName = toRef(props.fieldInfo!.name);

  const field = computed(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );

  const { relationInfo, relatedCollection } = useRelationO2M(collectionName, fieldName);

  const nestedItemPrimaryFieldPath = computed<string | undefined>(() => {
    if (!relatedCollection.value || !relationInfo.value) return undefined;

    const primaryField = fieldsStore.getPrimaryField(relatedCollection.value.id)!.name;
    const junctionField = relationInfo.value.junctionField!.name;

    return `${junctionField}.${primaryField}`;
  });

  const displayItems = computed(() => {
    const targetCollection = relatedCollection.value;
    if (!targetCollection) return [];

    const relatedItems: Record<string, any>[] =
      props.item.getDataProperty(props.fieldInfo!.name)?.currentJunctionItemIds ?? [];

    return relatedItems.map((data) =>
      transformNestedDataToItem(targetCollection.id, data),
    );
  });

  const displayTemplate = computed(() => {
    return (
      field.value?.meta.displayOptions?.template ||
      relatedCollection.value?.meta.displayTemplate ||
      `{{ ${relatedCollection.value?.getPrimaryFieldInfo()!.id} }}`
    );
  });
</script>
