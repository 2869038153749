import type { ColumnProps } from "primevue/column";
import type { ColumnInterface } from "./interfaces";
import type { DataTableFilterMetaData } from "primevue/datatable";

export const defineColumnInterface = (
  columnInterface: ColumnInterface,
): ColumnInterface => columnInterface;

export const defineColumnFilter = (filter: Omit<DataTableFilterMetaData, "value">) => {
  return {
    columnFilter: filter,
  };
};

export const defineColumnProps = (props: ColumnProps): { columnProps: ColumnProps } => {
  return {
    columnProps: props,
  };
};
