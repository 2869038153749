<template>
  <div class="flex flex-col justify-start items-center mt-5 mb-5">
    <span v-if="hasTitle" class="text-xs text-slate-700">
      {{ props.field.meta.options.title }}
    </span>

    <div class="bg-slate-400/50 w-full h-0.5"></div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { type FieldFormInterfaceProps } from "../types";

  const props = defineProps<FieldFormInterfaceProps>();

  const hasTitle = computed<boolean>(() => !!props.field.meta.options?.title);
</script>

<style scoped></style>
