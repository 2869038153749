import { Item } from "~/entities/item";
import type { SettingsInterface, AppSettingsResponse } from "../lib/types";

export class Settings extends Item<AppSettingsResponse> implements SettingsInterface {
  get defaultLanguage(): string {
    return this.getDataProperty("default_languge");
  }

  get projectName(): string {
    return this.getDataProperty("project_name");
  }

  get projectUrl(): string {
    return this.getDataProperty("project_url");
  }

  get projectLogoId(): string | null {
    return this.getDataProperty("project_logo") || null;
  }

  get authLoginAttempts(): number {
    return this.getDataProperty("auth_login_attempts");
  }
}
