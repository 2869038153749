export class ItemCommitError extends Error {
  constructor(public readonly options: ItemCommitErrorOptions) {
    super(options.message);
  }
}

export interface ItemCommitErrorOptions {
  message: string;
  data: unknown;
}
