import { defineStore } from "pinia";
import { iocContainer } from "~/inversify.config";
import { type IItemsGateway, type ItemID } from "~/entities/item";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { logger } from "~/service/logger/logger";
import { App } from "./app";
import { castItemToApp } from "./mapper/cast-item-to-app";

interface State {
  apps: App[];
}

const STORE_ID = "appsStore";

export const useAppsStore = defineStore(STORE_ID, {
  state(): State {
    return {
      apps: [],
    };
  },

  getters: {
    getOneById:
      (state) =>
      (id: ItemID): App | undefined =>
        state.apps.find((app): app is App => app.id === id),
  },

  actions: {
    async initialize(): Promise<void> {
      const itemsGateway = iocContainer.get<IItemsGateway>(INJECT_SYMBOLS.IItemsGateway);

      try {
        const response = await itemsGateway.getManyByQuery("apps", {
          fields: ["*"],
        });
        const apps: App[] = response.data.map(castItemToApp);

        this.$patch({
          // @ts-expect-error
          apps,
        });

        logger().debug(`initialized 'apps' store.`);
      } catch (err) {
        // todo: обработка ошибки
      }
    },

    async deinitialize() {
      this.$reset();
    },
  },
});
