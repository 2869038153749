import Dropzone, { type DropzoneFile } from "dropzone";
import type { IItem } from "~/entities/item";
import { castItemFileToBlob } from "./cast-item-file-to-blob";

export const castItemFileToDropzoneFile = async (
  item: IItem,
  token: string,
): Promise<DropzoneFile | undefined> => {
  const fileBlob = await castItemFileToBlob(item, token);

  if (fileBlob === undefined) return undefined;

  const uuid = item.getDataProperty("id") as string;
  const fileName = item.getDataProperty("title") as string;
  const contentType = item.getDataProperty("type");

  const dropzoneFile = new File([fileBlob as Blob], fileName, {
    type: contentType,
  }) as DropzoneFile;

  if (!dropzoneFile) {
    return;
  }

  dropzoneFile.upload = {};

  if (item.getMetaProperty("upload")) {
    dropzoneFile.upload = {
      ...item.meta.upload,
    };
  }

  dropzoneFile.upload.uuid = uuid;
  dropzoneFile.status = item.getMetaProperty("status") || Dropzone.SUCCESS;

  return dropzoneFile;
};
