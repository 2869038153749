import { usePermissionsStore } from "~/stores/permissions";
import { PermissionAction } from "~/api/permissions/entity/PermissionInterface";

interface IUrlParserResponse {
  collection?: string;
  action?: PermissionAction;
  id?: string;
}

export const isUrlPermitted = (url: string): boolean => {
  const { hasCollectionPermission } = usePermissionsStore();

  let parsedResult: IUrlParserResponse = {};
  const isLocalUrl = url.startsWith("/c/");
  if (!isLocalUrl) {
    return true;
  }

  const parsedUrl: string[] = url.replace("/c/", "").split("/");

  parsedResult = {
    collection: parsedUrl[0],
    action: parsedUrl?.[1] as PermissionAction,
    id: parsedUrl?.[2],
  };

  return hasCollectionPermission(
    parsedResult.collection as string,
    parsedResult.action ?? PermissionAction.READ,
  );
};
