<script lang="ts">
  export default {};
</script>

<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M6 3H3v3h3V3zm0 5H3v3h3V8zm-3 5h3v3H3v-3zm3 5H3v3h3v-3zM8 3h3v3H8V3zm3 5H8v3h3V8zm-3 5h3v3H8v-3zm3 5H8v3h3v-3zm2-15h3v3h-3V3zm3 5h-3v3h3V8zm-3 5h3v3h-3v-3zm3 5h-3v3h3v-3zm2-15h3v3h-3V3zm3 5h-3v3h3V8zm-3 5h3v3h-3v-3zm3 5h-3v3h3v-3z"
    />
  </svg>
</template>
