import gql from "graphql-tag";

export const USER_COLLECTION_LAYOUT_PRESETS_QUERY = gql`
  query UserLayoutPresets($collectionName: String!, $userId: String!, $roleId: String!) {
    presets(
      filter: {
        collection: { _eq: $collectionName }
        _or: [
          { user: { id: { _eq: $userId } } }
          { role: { id: { _eq: $roleId } } }
          { user: { id: { _null: true } }, role: { id: { _null: true } } }
        ]
      }
    ) {
      id
      user {
        id
      }
      role {
        id
      }
      collection
      layout
      layout_query
    }
  }
`;

export const USER_LAYOUT_PRESETS_QUERY = gql`
  query UserPresets($userId: String!, $roleId: String!) {
    presets(
      filter: {
        _or: [
          { user: { id: { _eq: $userId } } }
          { role: { id: { _eq: $roleId } } }
          { user: { id: { _null: true } }, role: { id: { _null: true } } }
        ]
      }
    ) {
      id
      user {
        id
      }
      role {
        id
      }
      collection
      layout
      layout_query
    }
  }
`;
