import { injectable } from "inversify";

@injectable()
export class IFrameSizer {
  constructor() {}

  setHeightFromCustomSelector(
    iframeElement: HTMLIFrameElement,
    heightElement: HTMLElement,
    extraHeight?: number,
  ): void {
    const internalHeight = heightElement.scrollHeight;

    iframeElement.height = !!extraHeight
      ? (internalHeight + extraHeight).toString()
      : internalHeight.toString();
  }
}
