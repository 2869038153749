/* eslint-disable no-console */
import {
  PushNotificationAction,
  type IPushNotificationController,
  type PushNotification,
  type PushNotificationClickable,
} from "../types";
import { OpenItemViewNotificationHandler } from "../handlers";

export class PushNotificationController implements IPushNotificationController {
  constructor() {}

  async handle(notification: PushNotification): Promise<void> {
    if (!notification.data.click_action) {
      console.error(
        { notification },
        "[push-notification-controller]: There is no  'click-action' key in notification.data",
      );
      return;
    }

    await this._handleClick(notification as PushNotificationClickable);
  }

  private async _handleClick(notification: PushNotificationClickable): Promise<void> {
    const action = notification.data.click_action;

    switch (action) {
      case PushNotificationAction.OPEN_ITEM_VIEW:
        const handler = new OpenItemViewNotificationHandler();
        await handler.handle(notification);
        break;

      default:
        console.error(
          { notification },
          `[push-notification-controller]: There is no handler for action - ${action}`,
        );
        break;
    }
  }
}
