import { defineStore } from "pinia";

const LOCALE_STORE_ID = "localeStore";

export const useLocaleStore = defineStore(LOCALE_STORE_ID, {
  state() {
    return {};
  },

  actions: {
    async initialize(): Promise<void> {},

    async deinitialize(): Promise<void> {},
  },
});
