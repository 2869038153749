import { type ISystemReaction } from "./interfaces";
import { useSystemReactionsStore } from "./store";

export async function registerSystemReactions(
  reactions: ISystemReaction[],
): Promise<void> {
  const { registerReaction } = useSystemReactionsStore();

  for (const reaction of reactions) {
    registerReaction(reaction);
  }
}
