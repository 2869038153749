<template>
  <div v-if="formattedData">
    {{ formattedData }}
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { ref, watchEffect, onBeforeUnmount, watch, unref } from "vue";
  import { Subscription, filter, interval } from "rxjs";
  import dayjs, { Dayjs } from "dayjs";
  import { transformUnicodeToDayjsFormat } from "~/service/dayjs/transforms/fromUnicode";
  import { useFieldsStore } from "~/entities/field";
  import { type FieldDisplayProp } from "../types";

  const props = defineProps<FieldDisplayProp>();

  const fieldsStore = useFieldsStore();

  const fieldInfo = computed(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );

  const fieldData = computed<Dayjs | false>(() => {
    const data = props.item.getDataProperty(props.fieldKey);

    if (!data) {
      return false;
    }

    if (data instanceof Number && !data) return false;
    if (data instanceof String && !data.length) return false;
    return dayjs(data);
  });

  const isRelative = computed<boolean>(
    () => fieldInfo.value?.meta.displayOptions?.relative ?? false,
  );

  const fieldDisplayFormat = computed<string | false>(
    () => fieldInfo.value?.meta.displayOptions?.format ?? false,
  );
  const format = computed<string>(() => fieldDisplayFormat.value || "DD/MM/YYYY");

  const transformDateToRelative = (date: Dayjs): string => {
    return date.fromNow();
  };

  const transformDateToFormat = (date: Dayjs, format: string): string => {
    const dayjsFormat = transformUnicodeToDayjsFormat(format);
    return date.format(dayjsFormat);
  };

  const formattedData = ref<string | false>(false);

  const updateFormattedData = (): void => {
    const currentData = unref(fieldData);

    if (currentData === false) {
      formattedData.value = false;
      return;
    }

    const newData = isRelative.value
      ? transformDateToRelative(currentData)
      : transformDateToFormat(currentData, format.value);

    formattedData.value = newData;
  };

  watch(
    () => [fieldData.value, isRelative.value],
    () => {
      updateFormattedData();
    },
    {
      immediate: true,
    },
  );

  const relativeUpdate$ = interval(60000).pipe(filter(() => isRelative.value));
  let relativeUpdateSub: Subscription | null = null;

  watchEffect(() => {
    if (isRelative.value && !relativeUpdateSub) {
      relativeUpdateSub = relativeUpdate$.subscribe(() => {
        updateFormattedData();
      });
    }

    if (!isRelative.value && !!relativeUpdateSub) {
      relativeUpdateSub.unsubscribe();
    }
  });

  onBeforeUnmount(() => {
    relativeUpdateSub?.unsubscribe();
  });
</script>
