import { default as indexSqZMP0tS3wMeta } from "/var/intelvision/digibms-portal/src/pages/app/index.ts?macro=true";
import { default as app_45pagePNpYNUjQ2hMeta } from "/var/intelvision/digibms-portal/src/pages/app/ui/app-page.vue?macro=true";
import { default as TheCollectionBasePagexMUZSrgqVlMeta } from "/var/intelvision/digibms-portal/src/pages/collection/TheCollectionBasePage.vue?macro=true";
import { default as TheHomePageH2spUwJMg8Meta } from "/var/intelvision/digibms-portal/src/pages/home/TheHomePage.vue?macro=true";
import { default as TheItemBasePageRJcMXJr91vMeta } from "/var/intelvision/digibms-portal/src/pages/items/TheItemBasePage.vue?macro=true";
import { default as TheItemCreatePageS8dCVDUfbPMeta } from "/var/intelvision/digibms-portal/src/pages/items/TheItemCreatePage.vue?macro=true";
import { default as indexXiv86qCcJjMeta } from "/var/intelvision/digibms-portal/src/pages/login/index.ts?macro=true";
import { default as index5pDTcvXuDsMeta } from "/var/intelvision/digibms-portal/src/pages/login/ui/index.ts?macro=true";
import { default as login_45agreement6k5kjeKQTaMeta } from "/var/intelvision/digibms-portal/src/pages/login/ui/login-agreement.vue?macro=true";
import { default as TheLoginPage2r2DadBMjcMeta } from "/var/intelvision/digibms-portal/src/pages/login/ui/TheLoginPage.vue?macro=true";
import { default as indexMVkouSCvH1Meta } from "/var/intelvision/digibms-portal/src/pages/profile/api/index.ts?macro=true";
import { default as useProfileInfoTaeN0P2ONXMeta } from "/var/intelvision/digibms-portal/src/pages/profile/api/useProfileInfo.ts?macro=true";
import { default as indexjKI8xhvqrPMeta } from "/var/intelvision/digibms-portal/src/pages/profile/index.ts?macro=true";
import { default as ProfilePagetmEAIsJ6XhMeta } from "/var/intelvision/digibms-portal/src/pages/profile/ui/ProfilePage.vue?macro=true";
import { default as index7oKjDLKFvVMeta } from "/var/intelvision/digibms-portal/src/pages/reset-user-password-page/index.ts?macro=true";
import { default as ResetUserPasswordPageodYf1ohR08Meta } from "/var/intelvision/digibms-portal/src/pages/reset-user-password-page/ui/ResetUserPasswordPage.vue?macro=true";
export default [
  {
    name: indexSqZMP0tS3wMeta?.name ?? "app",
    path: indexSqZMP0tS3wMeta?.path ?? "/app",
    meta: indexSqZMP0tS3wMeta || {},
    alias: indexSqZMP0tS3wMeta?.alias || [],
    redirect: indexSqZMP0tS3wMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/app/index.ts").then(m => m.default || m)
  },
  {
    name: app_45pagePNpYNUjQ2hMeta?.name ?? "app-ui-app-page",
    path: app_45pagePNpYNUjQ2hMeta?.path ?? "/app/ui/app-page",
    meta: app_45pagePNpYNUjQ2hMeta || {},
    alias: app_45pagePNpYNUjQ2hMeta?.alias || [],
    redirect: app_45pagePNpYNUjQ2hMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/app/ui/app-page.vue").then(m => m.default || m)
  },
  {
    name: TheCollectionBasePagexMUZSrgqVlMeta?.name ?? "collection-TheCollectionBasePage",
    path: TheCollectionBasePagexMUZSrgqVlMeta?.path ?? "/collection/TheCollectionBasePage",
    meta: TheCollectionBasePagexMUZSrgqVlMeta || {},
    alias: TheCollectionBasePagexMUZSrgqVlMeta?.alias || [],
    redirect: TheCollectionBasePagexMUZSrgqVlMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/collection/TheCollectionBasePage.vue").then(m => m.default || m)
  },
  {
    name: TheHomePageH2spUwJMg8Meta?.name ?? "home-TheHomePage",
    path: TheHomePageH2spUwJMg8Meta?.path ?? "/home/TheHomePage",
    meta: TheHomePageH2spUwJMg8Meta || {},
    alias: TheHomePageH2spUwJMg8Meta?.alias || [],
    redirect: TheHomePageH2spUwJMg8Meta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/home/TheHomePage.vue").then(m => m.default || m)
  },
  {
    name: TheItemBasePageRJcMXJr91vMeta?.name ?? "items-TheItemBasePage",
    path: TheItemBasePageRJcMXJr91vMeta?.path ?? "/items/TheItemBasePage",
    meta: TheItemBasePageRJcMXJr91vMeta || {},
    alias: TheItemBasePageRJcMXJr91vMeta?.alias || [],
    redirect: TheItemBasePageRJcMXJr91vMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/items/TheItemBasePage.vue").then(m => m.default || m)
  },
  {
    name: TheItemCreatePageS8dCVDUfbPMeta?.name ?? "items-TheItemCreatePage",
    path: TheItemCreatePageS8dCVDUfbPMeta?.path ?? "/items/TheItemCreatePage",
    meta: TheItemCreatePageS8dCVDUfbPMeta || {},
    alias: TheItemCreatePageS8dCVDUfbPMeta?.alias || [],
    redirect: TheItemCreatePageS8dCVDUfbPMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/items/TheItemCreatePage.vue").then(m => m.default || m)
  },
  {
    name: indexXiv86qCcJjMeta?.name ?? "login",
    path: indexXiv86qCcJjMeta?.path ?? "/login",
    meta: indexXiv86qCcJjMeta || {},
    alias: indexXiv86qCcJjMeta?.alias || [],
    redirect: indexXiv86qCcJjMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/login/index.ts").then(m => m.default || m)
  },
  {
    name: index5pDTcvXuDsMeta?.name ?? "login-ui",
    path: index5pDTcvXuDsMeta?.path ?? "/login/ui",
    meta: index5pDTcvXuDsMeta || {},
    alias: index5pDTcvXuDsMeta?.alias || [],
    redirect: index5pDTcvXuDsMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/login/ui/index.ts").then(m => m.default || m)
  },
  {
    name: login_45agreement6k5kjeKQTaMeta?.name ?? "login-ui-login-agreement",
    path: login_45agreement6k5kjeKQTaMeta?.path ?? "/login/ui/login-agreement",
    meta: login_45agreement6k5kjeKQTaMeta || {},
    alias: login_45agreement6k5kjeKQTaMeta?.alias || [],
    redirect: login_45agreement6k5kjeKQTaMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/login/ui/login-agreement.vue").then(m => m.default || m)
  },
  {
    name: TheLoginPage2r2DadBMjcMeta?.name ?? "login-ui-TheLoginPage",
    path: TheLoginPage2r2DadBMjcMeta?.path ?? "/login/ui/TheLoginPage",
    meta: TheLoginPage2r2DadBMjcMeta || {},
    alias: TheLoginPage2r2DadBMjcMeta?.alias || [],
    redirect: TheLoginPage2r2DadBMjcMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/login/ui/TheLoginPage.vue").then(m => m.default || m)
  },
  {
    name: indexMVkouSCvH1Meta?.name ?? "profile-api",
    path: indexMVkouSCvH1Meta?.path ?? "/profile/api",
    meta: indexMVkouSCvH1Meta || {},
    alias: indexMVkouSCvH1Meta?.alias || [],
    redirect: indexMVkouSCvH1Meta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/profile/api/index.ts").then(m => m.default || m)
  },
  {
    name: useProfileInfoTaeN0P2ONXMeta?.name ?? "profile-api-useProfileInfo",
    path: useProfileInfoTaeN0P2ONXMeta?.path ?? "/profile/api/useProfileInfo",
    meta: useProfileInfoTaeN0P2ONXMeta || {},
    alias: useProfileInfoTaeN0P2ONXMeta?.alias || [],
    redirect: useProfileInfoTaeN0P2ONXMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/profile/api/useProfileInfo.ts").then(m => m.default || m)
  },
  {
    name: indexjKI8xhvqrPMeta?.name ?? "profile",
    path: indexjKI8xhvqrPMeta?.path ?? "/profile",
    meta: indexjKI8xhvqrPMeta || {},
    alias: indexjKI8xhvqrPMeta?.alias || [],
    redirect: indexjKI8xhvqrPMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/profile/index.ts").then(m => m.default || m)
  },
  {
    name: ProfilePagetmEAIsJ6XhMeta?.name ?? "profile-ui-ProfilePage",
    path: ProfilePagetmEAIsJ6XhMeta?.path ?? "/profile/ui/ProfilePage",
    meta: ProfilePagetmEAIsJ6XhMeta || {},
    alias: ProfilePagetmEAIsJ6XhMeta?.alias || [],
    redirect: ProfilePagetmEAIsJ6XhMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/profile/ui/ProfilePage.vue").then(m => m.default || m)
  },
  {
    name: index7oKjDLKFvVMeta?.name ?? "reset-user-password-page",
    path: index7oKjDLKFvVMeta?.path ?? "/reset-user-password-page",
    meta: index7oKjDLKFvVMeta || {},
    alias: index7oKjDLKFvVMeta?.alias || [],
    redirect: index7oKjDLKFvVMeta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/reset-user-password-page/index.ts").then(m => m.default || m)
  },
  {
    name: ResetUserPasswordPageodYf1ohR08Meta?.name ?? "reset-user-password-page-ui-ResetUserPasswordPage",
    path: ResetUserPasswordPageodYf1ohR08Meta?.path ?? "/reset-user-password-page/ui/ResetUserPasswordPage",
    meta: ResetUserPasswordPageodYf1ohR08Meta || {},
    alias: ResetUserPasswordPageodYf1ohR08Meta?.alias || [],
    redirect: ResetUserPasswordPageodYf1ohR08Meta?.redirect,
    component: () => import("/var/intelvision/digibms-portal/src/pages/reset-user-password-page/ui/ResetUserPasswordPage.vue").then(m => m.default || m)
  }
]