import { defineStore } from "pinia";
import { logger } from "~/service/logger/logger";
import type { FirebaseApp } from "firebase/app";

type FirebaseState = {
  firebaseApp: FirebaseApp | undefined;
};

export const useFirebaseStore = defineStore("firebase", {
  state: (): FirebaseState => ({
    firebaseApp: undefined,
  }),

  getters: {
    currentFirebaseApp(state: FirebaseState): FirebaseApp | undefined {
      if (!state.firebaseApp) {
        logger().warn(
          "[firebase-store]: firebase not initialized. You should call setFirebaseApp before getFirebaseApp",
        );
        return undefined;
      }
      return state.firebaseApp;
    },
  },
  actions: {
    setFirebaseApp(app: FirebaseApp): void {
      this.$patch({
        firebaseApp: app,
      });
      logger().debug(`[firebase-store]: initialized 'firebase' store.`);
    },
  },
});
