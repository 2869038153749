import { showError, useNuxtApp } from "#app";
import { defineStore } from "pinia";
import type { CollectionInterface } from "~/api/collections/entities/CollectionInterface";
import { iocContainer } from "~/inversify.config";
import type { CollectionsQueryGatewayInterface } from "~/api/collections/gateway/CollectionsQueryGatewayInterface";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { logger } from "~/service/logger/logger";

const COLLECTIONS_STORE_ID: string = "collectionsStore";

interface State {
  collections: Record<CollectionInterface["id"], CollectionInterface>;
}

export const useCollecitonsStore = defineStore(COLLECTIONS_STORE_ID, {
  state(): State {
    return {
      collections: {},
    };
  },

  getters: {
    getCollection(state) {
      return (id: CollectionInterface["id"]): CollectionInterface | undefined =>
        state.collections[id];
    },
  },

  actions: {
    async initialize() {
      const collectionsDataGateway = iocContainer.get<CollectionsQueryGatewayInterface>(
        INJECT_SYMBOLS.CollectionsQueryGatewayInterface,
      );

      const { $i18n } = useNuxtApp();

      try {
        const collections = await collectionsDataGateway.getAll();
        const collectionsMap = collections.reduce<
          Record<CollectionInterface["id"], CollectionInterface>
        >((acc, collection) => {
          acc[collection.id] = collection;
          return acc;
        }, {});

        this.$patch({
          collections: collectionsMap,
        });

        logger().debug(`initialized 'collections' store.`);
      } catch (err) {
        showError({
          statusCode: 500,
          statusMessage: `${$i18n.t("fatal_error_network_resources")}. 'collections'.`,
          fatal: true,
        });
      }
    },

    async deinitialize() {
      this.$reset();
    },
  },
});
