import { initializeApp } from "@firebase/app";
import { logger } from "~/service/logger/logger";
import { useFirebaseStore } from "../model";
import { appConfigCompiled } from "./config";

export const initializeFirebaseApp = async (): Promise<void> => {
  if (!appConfigCompiled.projectId) {
    logger().warn(
      "[Initialize-firebase-app]: invalid firebase config, push messaging isn't available. For enable firebase messaging you should set corresponding env variables.",
    );
    return;
  }
  try {
    const firebaseStore = useFirebaseStore();
    const firebaseApp = initializeApp(appConfigCompiled);
    firebaseApp && firebaseStore.setFirebaseApp(firebaseApp);
  } catch (error) {
    logger().error(
      { error },
      "[Initialize-firebase-app]: Firebase initialization error.",
    );
  }
};
