import { injectable } from "inversify";

@injectable()
export class GrafanaDomFinder {
  getBodyElement(iframeElement: HTMLIFrameElement): HTMLElement | null {
    return iframeElement.contentDocument?.querySelector(this.bodySelector) || null;
  }

  getDashboardContainerElement(iframeElement: HTMLIFrameElement): HTMLElement | null {
    return (
      iframeElement.contentDocument?.querySelector(this.dashboardContainerSelector) ||
      null
    );
  }

  getStylesContainerElement(iframeElement: HTMLIFrameElement): HTMLElement | null {
    return iframeElement.contentWindow?.document.head || null;
  }

  get bodySelector(): string {
    return `body.app-grafana`;
  }

  get dashboardContainerSelector(): string {
    return `.react-grid-layout`;
  }
}
