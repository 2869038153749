import validate from "/var/intelvision/digibms-portal/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45auth_45global from "/var/intelvision/digibms-portal/src/middleware/1.auth.global.ts";
import _2_45app_45initialization_45global from "/var/intelvision/digibms-portal/src/middleware/2.appInitialization.global.ts";
import _3_45permissions_45global from "/var/intelvision/digibms-portal/src/middleware/3.permissions.global.ts";
import dispatch_45routing_45to_45event_45bus_45global from "/var/intelvision/digibms-portal/src/middleware/dispatchRoutingToEventBus.global.ts";
export const globalMiddleware = [
  validate,
  _1_45auth_45global,
  _2_45app_45initialization_45global,
  _3_45permissions_45global,
  dispatch_45routing_45to_45event_45bus_45global
]
export const namedMiddleware = {}