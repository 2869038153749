<template>
  <div class="pristine-error text-danger mt-2">
    <ul>
      <li v-for="(error, idx) in props.errors" :key="idx">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  interface Props {
    errors: string[];
  }

  const props = defineProps<Props>();
</script>

<style scoped></style>
