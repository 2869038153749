import { Field } from "~/entities/field";
import type {
  DefaultType,
  FieldItem,
  FieldMetaTranslationType,
  FieldSchemaType,
} from "@directus/sdk";
import type {
  IField,
  IFieldMetaOptions,
  IFieldMeta,
  IFieldSchema,
  IFieldTranslation,
} from "../lib/types";

/**
 *
 * @param translation
 * @returns
 */
const castFieldTranslation = (
  translation: FieldMetaTranslationType,
): IFieldTranslation => ({
  language: translation.language,
  translation: translation.translation,
});

/**
 *
 * @param schema
 * @returns
 */
const castFieldSchema = (schema: FieldSchemaType): IFieldSchema => ({
  comment: schema.comment,
  defaultValue: schema.default_value,
  hasAutoIncrement: schema.has_auto_increment,
  maxLength: schema.max_length,
  numericPrecision: schema.numeric_precision,
  numericScale: schema.numeric_scale,
});

const castFieldOptions = (options: DefaultType): IFieldMetaOptions => {
  return options;
};

const transformMetaValidation = (field: FieldItem): IFieldMeta["validation"] => {
  const message = field.meta.validation_message;
  const rules = field.meta.validation;

  return {
    message,
    rules,
  };
};

/**
 *
 * @param fieldData
 * @returns
 */
export const castFieldInfoFromDataStudioApiToEntity = (fieldData: FieldItem): IField => {
  if (fieldData.meta === null) {
    return new Field(
      fieldData.field,
      fieldData.field,
      fieldData.type,
      fieldData.collection,
      {},
    );
  }

  const fieldMeta: IFieldMeta = {
    display: fieldData.meta.display,
    displayOptions: fieldData.meta.display_options,
    interface: fieldData.meta.interface,
    isHidden: fieldData.meta.hidden,
    isReadonly: fieldData.meta.readonly,
    isRequired: fieldData.meta.required,
    note: fieldData.meta.note,
    options:
      fieldData.meta.options === null ? {} : castFieldOptions(fieldData.meta.options),
    sortPosition: fieldData.meta.sort ?? 0,
    translations:
      fieldData.meta.translations?.map((translation) =>
        castFieldTranslation(translation),
      ) ?? [],
    isPrimaryKey: fieldData.schema?.is_primary_key ?? false,
    isNullable: fieldData.schema?.is_nullable ?? true,
    isUnique: fieldData.schema?.is_unique ?? false,
    schema: fieldData.schema === null ? null : castFieldSchema(fieldData.schema),
    validation: transformMetaValidation(fieldData),
    special: fieldData.meta.special,
    conditions: fieldData.meta.conditions,
    width: fieldData.meta.width,
  };

  return new Field(
    fieldData.meta.id,
    fieldData.field,
    fieldData.type,
    fieldData.collection,
    fieldMeta,
  );
};
