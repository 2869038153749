import { inject, injectable } from "inversify";
import { Subscription, filter, map, tap } from "rxjs";
import { AppEventBus, AppEvents } from "~/shared/lib/app-event-bus";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { logger } from "~/service/logger/logger";
import { useSystemReactionsStore } from "../store";
import { type IReactionsController } from "./types";

@injectable()
export class MessagingTokenController implements IReactionsController {
  private _listenerSubscription: Subscription | undefined;

  constructor(
    @inject(INJECT_SYMBOLS.AppEventBus)
    private readonly _eventBus: AppEventBus,
  ) {}

  destroy(): void {
    if (this._listenerSubscription) this._listenerSubscription.unsubscribe();
  }

  registerEventBusListeners(): void {
    const $actionProcessor = this._eventBus.observe().pipe(
      filter((event) => event.event === AppEvents.PUSH_TOKEN_RECIEVED),
      map((event) => {
        const systemReactionsStore = useSystemReactionsStore();
        return {
          event,
          reactions: systemReactionsStore.getAllByAppEvent(event),
        };
      }),
      filter((data) => !!data.reactions.length),
      map((data) => {
        return {
          event: data.event,
          reactions: data.reactions.sort((a, b) => a.priority - b.priority),
        };
      }),
      tap(async (data) => {
        for await (const reaction of data.reactions) {
          await reaction.action(data.event, {
            utils: {
              AppEvents: AppEvents,
              Logger: logger(),
            },
          });
        }
      }),
    );

    this._listenerSubscription = $actionProcessor.subscribe();
  }
}
