import { useNuxtApp } from "#app";
import { type IField } from "./types";

export const getFieldPlaceholder = (field: IField) => {
  const {
    $i18n: { t },
  } = useNuxtApp();

  const placeholder = field.meta.options.placeholder;

  if (!placeholder) {
    return t(field.name);
  }

  return placeholder;
};
