<template>
  <div v-if="!!relationInfo">
    <Skeleton v-if="isItemLoading" height="2rem" width="5rem"></Skeleton>

    <template v-else>
      <div
        v-if="!!item && !!relatedCollection && !!relationFieldInfo"
        class="flex items-center justify-between mb-1"
      >
        <RenderTemplate
          :collectionName="relatedCollection.id || ''"
          :item="item"
          :fieldInfo="relationFieldInfo"
          :defaultTemplate="defaultRenderTemplate"
          :context="props.context"
        />

        <Button
          v-if="canNavigateToRelationalItem(relatedCollection)"
          :variant="'soft-secondary'"
          class="ml-2"
          @click="routeToItem(relatedCollection.id, item.id, router)"
        >
          <i class="fa-solid fa-arrow-up-right-from-square"></i>
        </Button>
      </div>

      <DataNull v-else />
    </template>
  </div>
</template>

<script setup lang="ts">
  import { shallowRef, watch, computed, ref } from "vue";
  import { useRouter } from "vue-router";
  import { watchDebounced } from "@vueuse/shared";
  import Skeleton from "primevue/skeleton";
  import { useFieldsStore } from "~/entities/field";
  import { useRelationM2O } from "~/api/relations/composables/useRelationsM2O";
  import type { CollectionInterface } from "~/api/collections/entities/CollectionInterface";
  import { canNavigateToRelationalItem } from "~/api/collections/utils/availability";
  import Button from "~/shared/ui/Button";
  import DataNull from "~/components/DataNull/data-null.vue";
  import { RenderTemplate } from "~/entities/render-template";
  import { transformNestedDataToItem } from "~/api/relations/utils/transform-nested-data-to-item";
  import { routeToItem, useLazyCollectionItem, type IItem } from "~/entities/item";
  import type { FieldDisplayProp } from "../types";

  const props = defineProps<FieldDisplayProp>();

  const router = useRouter();
  const fieldsStore = useFieldsStore();

  const relationFieldInfo = computed(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );

  const { relationInfo, relatedCollection } = useRelationM2O(
    computed(() => props.collectionName),
    relationFieldInfo,
  );

  const itemIdForFetch = computed<(string | number) | null>(() => {
    const itemId = props.item.getDataProperty(props.fieldKey);
    return !!itemId ? itemId : null;
  });

  const withRemoteFetch = ref<boolean>(false);

  const {
    load: loadItem,
    collectionItem: relatedItem,
    isLoading: isItemLoading,
  } = useLazyCollectionItem();

  watchDebounced(
    () => [relatedCollection.value, itemIdForFetch.value, withRemoteFetch.value],
    async ([collection, itemId, fetchEnabled]) => {
      if (!collection || !itemId || !fetchEnabled) return;

      await loadItem((collection as CollectionInterface).id, String(itemId));
    },
    {
      immediate: true,
      debounce: 500,
    },
  );

  watch(
    () => relatedItem.value,
    (newItem) => {
      item.value = newItem ?? undefined;
    },
  );

  const item = shallowRef<IItem | undefined>(undefined);

  watch(
    () => props.context?.data,
    (newData) => {
      withRemoteFetch.value = false;
      if (!newData) {
        withRemoteFetch.value = true;
        return;
      }

      if (!relatedCollection.value) {
        return;
      }

      const relationalData = newData?.getDataProperty(props.fieldKey) || {};
      item.value = !!relationalData
        ? transformNestedDataToItem(relatedCollection.value!.id, relationalData)
        : undefined;
    },
    {
      immediate: true,
    },
  );

  const defaultRenderTemplate = computed<string>(() => {
    const fieldTemplate = relationFieldInfo.value?.meta.displayOptions?.template;
    if (!!fieldTemplate) return fieldTemplate;

    if (!relatedCollection.value) return "";

    const collectionTemplate = relatedCollection.value.meta?.displayTemplate;
    const primaryField = relatedCollection.value.getPrimaryFieldInfo();

    return collectionTemplate || `{{ ${primaryField?.name} }}`;
  });
</script>

<style scoped></style>
