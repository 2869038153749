<template>
  <SideMenuLayout />

  <ConfirmDialog />
</template>

<script setup lang="ts">
  import "notyf/notyf.min.css";
  import "primeicons/primeicons.css";
  import "@fortawesome/fontawesome-free/css/all.css";
  import "~/assets/css/app.css";
  import { useNuxtApp, useHead } from "#app";
  import { onBeforeMount, computed } from "vue";
  import ConfirmDialog from "primevue/confirmdialog";
  import { usePrimeVue } from "primevue/config";
  import flatpickr from "flatpickr";
  import dayjs from "dayjs";
  import duration from "dayjs/plugin/duration";
  import timezone from "dayjs/plugin/timezone";
  import utc from "dayjs/plugin/utc";
  import relativeTime from "dayjs/plugin/relativeTime";
  import weekOfYearPlugin from "dayjs/plugin/weekOfYear";
  import "dayjs/locale/ru";
  import {
    getDayjsFallbackLocale,
    getDayjsLocale,
    getFlatpickrLocaleStrings,
    getPrimevueLocaleStrings,
  } from "~/api/intl/utils/vendors";
  import SideMenuLayout from "~/layouts/SideMenu/SideMenu.vue";
  import { createHeadTitleWithPrefix } from "~/shared/lib/head";

  dayjs.extend(timezone);
  dayjs.extend(duration);
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(weekOfYearPlugin);

  onBeforeMount(() => {
    dayjs.tz.setDefault("Europe/Moscow");
  });

  onBeforeMount(async () => {
    const { $i18n } = useNuxtApp();

    const primevue = usePrimeVue();

    const primevueLocaleStrings = await getPrimevueLocaleStrings($i18n.locale.value);
    primevue.config.locale = primevueLocaleStrings;

    const flatpickrLocaleString = await getFlatpickrLocaleStrings($i18n.locale.value);
    flatpickr.localize(flatpickrLocaleString);

    dayjs.locale(getDayjsLocale($i18n.locale.value) || getDayjsFallbackLocale());
  });

  useHead({
    title: computed(() => createHeadTitleWithPrefix("")),
  });
</script>
