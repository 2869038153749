import { type IField } from "~/entities/field";

export enum ActivityAction {
  UPDATE = "update",
  CREATE = "create",
}

export interface Revision {
  id: string;
  activity: Activity;
  collection: string;
  item: string;
  delta: { [key: string]: unknown };
  data: { [key: string]: unknown };
}

export interface Activity {
  id: string;
  action: ActivityAction;
  item: string | number;
  date: Date | null;
  user: ActivityUser;
}

export interface ActivityUser {
  id: string;
  first_name: string;
  last_name: string;
  avatar: {
    id: string;
    title: string;
  } | null;
}

export type FieldRevision = {
  fieldInfo: IField;
  data: FieldRevisionData;
};

type FieldRevisionData = {
  current?: unknown;
  previous?: unknown;
  initial?: unknown;
};

export const defineRevision = (revision: Revision): Revision => {
  return revision;
};
