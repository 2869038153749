import gql from "graphql-tag";

export const GetAllRelationsQuery = gql`
  query GetAllRelationsQuery {
    relations {
      collection
      field
      related_collection
      schema {
        constraint_name
        table
        column
        foreign_key_table
        foreign_key_column
        on_update
        on_delete
      }
      meta {
        id
        many_collection
        many_field
        one_collection
        one_field
        one_collection_field
        one_allowed_collections
        junction_field
        sort_field
        one_deselect_action
      }
    }
  }
`;
