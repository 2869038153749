import { IsNotEmpty, IsOptional, IsUUID } from "class-validator";

export class MenuDto {
  @IsUUID()
  uuid: string;

  @IsOptional()
  sort: number | null;

  @IsOptional()
  @IsUUID()
  parent_uuid: string | null;

  @IsNotEmpty()
  label: string;

  url: string;

  icon: string;

  childrens: MenuDto[];
}
