// @ts-nocheck

/**
 * Can processing both remote and local (base64) resources
 */

export async function getFileFromUrl(
  url: string,
  filename: string,
  mimeType = "image/jpeg",
): Promise<File> {
  if (url.startsWith("data:")) {
    // eslint-disable-next-line prefer-const
    let arr = url.split(","),
      // eslint-disable-next-line prefer-const
      mime = arr[0].match(/:(.*?);/)[1],
      // eslint-disable-next-line prefer-const
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      // eslint-disable-next-line prefer-const
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }
  const res = await fetch(url);
  const data = await res.blob();
  return new File([data], filename, { type: data.type || mimeType });
}
