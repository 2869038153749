const retrieveDayjsFormatSymbolFromUnicode = (unicodeSymbol: string): string | false => {
  return UnicodeToDayjsFormatsMatch[unicodeSymbol] || false;
};

export const transformUnicodeToDayjsFormat = (format: string): string => {
  const UNICODE_FORMAT_REGEX =
    /\[([^\]]+)]|Y{1,4}|y{1,4}|M{1,4}|d{1,2}|E{1,4}|i{1,3}|H{1,2}|k{1,2}|m{1,2}|s{1,2}|SSS/g;

  return format.replace(UNICODE_FORMAT_REGEX, (match, $1) => {
    return $1 || retrieveDayjsFormatSymbolFromUnicode(match) || "";
  });
};

enum YearUnicodeToDayjsFormatMatch {
  y = "YY",
  yy = "YY",
  yyy = "YY",
  yyyy = "YYYY",
  Y = "YYYY",
  YY = "YY",
  YYY = "YYY",
  YYYY = "YYYY",
}

enum MonthUnicodeToDayjsFormatMatch {
  M = "M",
  MM = "MM",
  MMM = "MMM",
  MMMM = "MMMM",
}

enum DayUnicodeToDayjsFormatMatch {
  d = "D",
  dd = "DD",
  E = "ddd",
  EE = "ddd",
  EEE = "ddd",
  EEEE = "dddd",
  i = "d",
  ii = "d",
  iii = "ddd",
}

enum HourUnicodeToDayjsFormatMatch {
  H = "H",
  HH = "HH",
  k = "h",
  kk = "hh",
}

enum MinuteUnicodeToDayjsFormatMatch {
  m = "m",
  mm = "mm",
}

enum SecondUnicodeToDayjsFormatMatch {
  s = "s",
  ss = "ss",
  SSS = "sss",
}

const UnicodeToDayjsFormatsMatch = Object.assign(
  YearUnicodeToDayjsFormatMatch,
  MonthUnicodeToDayjsFormatMatch,
  DayUnicodeToDayjsFormatMatch,
  HourUnicodeToDayjsFormatMatch,
  MinuteUnicodeToDayjsFormatMatch,
  SecondUnicodeToDayjsFormatMatch,
);
