<template>
  <PMultiselect v-bind="props" ref="refMultiSelect" v-on="emitListeners">
    <template #removetokenicon="slotProps">
      <slot name="removetokenicon" v-bind="slotProps" class="">
        <div class="multiselect-chip__button multiselect-chip__button--action">
          <Button
            variant="transparent"
            text
            size="small"
            :rounded="false"
            @click="slotProps.onClick"
          >
            <i class="fa-solid fa-xmark"></i>
          </Button>
        </div>
      </slot>
    </template>

    <template v-if="!!slots.value" #value="slotProps">
      <slot name="value" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.chip" #chip="slotProps">
      <slot name="chip" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.indicator" #indicator>
      <slot name="indicator"></slot>
    </template>

    <template v-if="!!slots.header" #header="slotProps">
      <slot name="header" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.footer" #footer="slotProps">
      <slot name="footer" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.option" #option="slotProps">
      <slot name="option" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.optiongroup" #optiongroup="slotProps">
      <slot name="optiongroup" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.emptyfilter" #emptyfilter>
      <slot name="emptyfilter"></slot>
    </template>

    <template v-if="!!slots.empty" #empty>
      <slot name="empty"></slot>
    </template>

    <template v-if="!!slots.content" #content="slotProps">
      <slot name="content" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.loader" #loader="slotProps">
      <slot name="loader" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.headercheckboxicon" #headercheckboxicon="slotProps">
      <slot name="headercheckboxicon" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.filtericon" #filtericon="slotProps">
      <slot name="filtericon" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.closeicon" #closeicon="slotProps">
      <slot name="closeicon" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.itemcheckboxicon" #itemcheckboxicon="slotProps">
      <slot name="itemcheckboxicon" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.loadingicon" #loadingicon="slotProps">
      <slot name="loadingicon" v-bind="slotProps"></slot>
    </template>

    <template v-if="!!slots.dropdownicon" #dropdownicon="slotProps">
      <slot name="dropdownicon" v-bind="slotProps"></slot>
    </template>
  </PMultiselect>
</template>

<script setup lang="ts">
  import { useSlots, ref, watchEffect } from "vue";
  import PMultiselect, {
    type MultiSelectEmits as PMultiSelectEmits,
  } from "primevue/multiselect";
  import Button from "~/shared/ui/Button";
  import type { MultiSelectEmits, MultiSelectProps } from "../lib/MultiSelect.types";

  const slots = useSlots();
  const props = defineProps<MultiSelectProps>();
  const emit = defineEmits<MultiSelectEmits>();

  const refMultiSelect = ref<any>(null);

  const exposedMethods: {
    show: (isFocus: boolean) => void;
    hide: (isFocus: boolean) => void;
  } = {
    show: () => {},
    hide: () => {},
  };

  watchEffect(() => {
    if (!refMultiSelect.value) {
      exposedMethods.show = () => {};
      exposedMethods.hide = () => {};
      return;
    }

    exposedMethods.show = refMultiSelect.value.show;
    exposedMethods.hide = refMultiSelect.value.hide;
  });

  defineExpose(exposedMethods);

  const emitListeners: PMultiSelectEmits = {
    "update:modelValue": (value) => {
      emit("update:modelValue", value);
    },

    change: (event) => {
      emit("change", event);
    },

    focus: (event) => {
      emit("focus", event);
    },

    blur: (event) => {
      emit("blur", event);
    },

    "before-show": () => {
      emit("before-show");
    },

    "before-hide": () => {
      emit("before-hide");
    },

    show: () => {
      emit("show");
    },

    hide: () => {
      emit("hide");
    },

    filter: (event) => {
      emit("filter", event);
    },

    "selectall-change": (event) => {
      emit("selectall-change", event);
    },
  };
</script>
