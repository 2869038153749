import type { NoticeMessage } from "./types";

/**
 *
 * @param notice
 * @returns
 */
export const defineNoticeMessage = (notice: NoticeMessage): NoticeMessage => {
  return notice;
};
