<script setup lang="ts">
  import { useNuxtApp, useError, navigateTo } from "#app";
  import { computed } from "vue";
  import { H3Error } from "h3";
  import isNil from "lodash/isNil";
  import errorIllustration from "~/assets/images/error-illustration.svg";
  import Button from "~/shared/ui/Button";
  import { RoutePathNames } from "./app/router.options";

  const {
    $i18n: { t },
  } = useNuxtApp();
  const error = useError();

  const statusCode = computed<number | undefined>(() => {
    if (isNil(error.value) || error.value === undefined) return undefined;

    if (error.value instanceof H3Error) {
      return error.value.statusCode;
    }

    return undefined;
  });

  const errorMessage = computed<string>(() => {
    if (isNil(error.value) || error.value === undefined)
      return t("error_message_unknown");

    if (error.value instanceof H3Error) {
      const message = error.value.statusMessage;
      return message !== undefined ? message : error.value.message;
    }

    return error.value.message;
  });

  const navigateToHome = async () => {
    await navigateTo({
      name: RoutePathNames.HOME,
    });
  };

  const reloadPage = () => {
    if (typeof window === "undefined") return;

    window.location.reload();
  };

  const isFatal = computed<boolean>(() => {
    if (isNil(error.value) || error.value === undefined) return false;

    if (error.value instanceof H3Error) {
      return error.value.fatal;
    }

    return false;
  });
</script>

<template>
  <div class="py-2">
    <div class="container">
      <div
        class="flex flex-col items-center justify-center h-screen text-center error-page lg:flex-row lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <img
            alt="Midone Tailwind HTML Admin Template"
            class="w-[450px] h-48 lg:h-auto"
            :src="errorIllustration"
          />
        </div>

        <div class="mt-10 text-dark lg:mt-0">
          <div class="font-medium intro-x text-8xl">
            {{ $t("code") }} {{ statusCode }}
          </div>

          <div class="mt-5 text-xl font-medium intro-x lg:text-3xl">
            {{ $t("error_page_title") }}
          </div>

          <div class="mt-3 text-lg intro-x">{{ $t("reason") }}: {{ errorMessage }}</div>

          <Button
            v-if="!isFatal"
            variant="primary"
            class="px-4 py-3 mt-10 mr-3 intro-x dark:border-darkmode-400 dark:text-slate-200"
            @click="navigateToHome"
          >
            {{ $t("return_to_homepage") }}
          </Button>

          <Button
            v-else
            variant="primary"
            class="px-4 py-3 mt-10 mr-3 intro-x dark:border-darkmode-400 dark:text-slate-200"
            @click="reloadPage"
          >
            {{ $t("reload_page") }}
          </Button>

          <Button
            :variant="'soft-secondary'"
            class="px-4 py-3 mt-10 intro-x dark:border-darkmode-400 dark:text-slate-200"
            disabled
          >
            {{ $t("report_a_problem") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
