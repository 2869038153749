import { defineStore } from "pinia";

export const AppStoreID: string = "app";

export type AppStoreState = {
  initialized: boolean;
};

export const useAppStore = defineStore(AppStoreID, {
  state: (): AppStoreState => ({
    initialized: false,
  }),

  actions: {
    async deinitialize(): Promise<void> {
      this.$reset();
    },
  },
});
