import Dropzone, { type DropzoneFile } from "dropzone";
import { type IItem, type IItemMeta, Item, type ItemAnyData } from "~/entities/item";
import { FILES_COLLECTION_NAME, ItemFileMetadataKeys } from "../lib/constants";

export const castDropzoneQueuedFileToItemFile = (dropzoneFile: DropzoneFile): IItem => {
  const data: ItemAnyData = {
    id: dropzoneFile.upload?.uuid,
    storage: null,
    filename_disk: null,
    filename_download: null,
    title: dropzoneFile.name,
    type: dropzoneFile.type,
    folder: null,
    uploaded_by: null,
    uploaded_on: null,
    modified_by: null,
    modified_on: null,
    filesize: dropzoneFile.size,
  };

  const metadata: IItemMeta = {
    [ItemFileMetadataKeys.FILE_DATA]: dropzoneFile,
    [ItemFileMetadataKeys.IS_UPLOADED]: false,
    status: dropzoneFile.status || Dropzone.SUCCESS,
    upload: {
      ...dropzoneFile.upload,
    },
  };

  const item = new Item(FILES_COLLECTION_NAME, data, metadata);

  return item;
};
