<template>
  <textarea
    :id="props.field.name"
    :name="props.field.name"
    :value="props.item.getDataProperty(field.name)"
    :readonly="props.field.meta.isReadonly"
    :disabled="props.field.meta.isReadonly"
    :placeholder="getFieldPlaceholder(field)"
    :class="[
      'form-control',
      {
        'border-danger': !isValid && isTouched,
      },
    ]"
    @blur="setTouched"
    @input="onInput"
    @change="onChange"
  ></textarea>

  <ValidationMessage v-if="!!errors.length" :errors="errors" />
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import {
    FieldInterfaceEmitId,
    type FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "~/api/field-interfaces/emits";
  import ValidationMessage from "~/shared/ui/ValidationMessage";
  import { useFieldValidation, getFieldPlaceholder } from "~/entities/field";
  import type { FieldFormInterfaceProps } from "../../types";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const onInput = (evt: Event) => {
    const input = evt.target as HTMLInputElement;

    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: input.value,
      }),
    );
  };

  const onChange = (evt: Event) => {
    const input = evt.target as HTMLInputElement;

    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: input.value,
      }),
    );
  };

  const { isValid, errors, isTouched, setTouched } = useFieldValidation(
    computed(() => props.item),
    computed(() => props.field),
  );
</script>

<style scoped></style>
