import { useNuxtApp, createError } from "#app";
import { defineStore } from "pinia";
import { print } from "graphql";
import type { RelationInterface } from "~/api/relations/entity/RelationInterface";
import { toaster } from "~/service/toaster";
import { GetAllRelationsQuery } from "~/service/data-studio/features/relations/graphql/queries";
import { castRelationFromApiResponseToEntity } from "~/api/relations/casters/datastudioCasters";
import { logger } from "~/service/logger/logger";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";
import type { Relation } from "@directus/types";

export const RelationsStoreID: string = "relations";

interface RelationsState {
  relations: RelationInterface[];
}

export const useRelationsStore = defineStore(RelationsStoreID, {
  state: (): RelationsState => ({
    relations: [],
  }),

  actions: {
    async initialize(): Promise<void> {
      const { $i18n } = useNuxtApp();
      const { client } = useDataStudioClient();

      try {
        const response = await client.query<{ relations: Relation[] }>(
          print(GetAllRelationsQuery),
          undefined,
          "system",
        );

        const relations: RelationInterface[] = response.relations.map(
          castRelationFromApiResponseToEntity,
        );

        this.$patch({
          relations,
        });
      } catch (err) {
        if (err instanceof Error) {
          logger().fatal(
            {
              message: err.message,
              error: err,
            },
            `Can't set Relations`,
          );
        }

        const errorMessage: string = $i18n.t("fatal_error_network_resources");

        toaster().error(errorMessage);

        throw createError({
          statusCode: 500,
          message: errorMessage,
          fatal: true,
        });
      }
    },

    async deinitilize(): Promise<void> {
      this.$reset();
    },
  },

  getters: {
    /**
     *
     * @param state
     * @returns
     */
    getRelationsByField() {
      return (collectionName: string, fieldName: string) => {
        const relations = this.getRelationsForCollection(collectionName).filter(
          (relation) => {
            const isCollectionRelation =
              relation.collectionName === collectionName &&
              relation.fieldName === fieldName;

            const isRelatedRelation =
              relation.relatedCollection === collectionName &&
              relation.meta?.oneField === fieldName;

            return isCollectionRelation || isRelatedRelation;
          },
        );

        if (!relations.length) return [];

        const firstRelationInList = relations[0];
        const isM2M = firstRelationInList.meta?.junctionField !== null;

        if (!isM2M) return relations;

        const junctionRelation = this.relations.find(
          (relation) =>
            relation.collectionName === firstRelationInList.collectionName &&
            relation.fieldName === firstRelationInList.meta?.junctionField,
        );

        if (!junctionRelation) return relations;

        relations.push(junctionRelation);
        return relations;
      };
    },

    /**
     *
     * @param state
     * @returns
     */
    getRelationsForCollection: (state) => (collectionName: string) => {
      return state.relations.filter(
        (relation) =>
          relation.collectionName === collectionName ||
          relation.relatedCollection === collectionName,
      );
    },
  },
});
