import merge from "lodash/merge";
import isPlainObject from "lodash/isPlainObject";
import { useUserStore } from "~/entities/user";
import { parseFilter as parseFilterShared, validateFilter } from "~/entities/filter";
import { type IItem, type ItemID } from "~/entities/item";
import { Field } from "./field";
import type { IField, IFieldMeta } from "../lib";
import type { Accountability, Filter } from "@directus/types";

/**
 *
 */
export function applyConditions(item: IItem, field: IField): IField {
  if (!field.meta || !Array.isArray(field.meta?.conditions)) return field;

  const dataWithUnwrappedRelational: Record<string, any> = {};
  for (const fieldKey in item.data) {
    const fieldData = item.data[fieldKey];

    if (isRelationalData(fieldData)) {
      dataWithUnwrappedRelational[fieldKey] = getRelationalDataForValidation(fieldData);
      continue;
    }

    dataWithUnwrappedRelational[fieldKey] = fieldData;
  }

  const conditions = field.meta.conditions!;

  const matchingCondition = conditions.find((condition) => {
    const rule = parseFilter(condition.rule);
    const errors = validateFilter(dataWithUnwrappedRelational, rule, {
      requireAll: true,
    });
    return !errors.length;
  });

  if (matchingCondition) {
    const newMeta: Partial<IFieldMeta> = merge({}, field.meta || {}, {
      isReadonly: matchingCondition.readonly || false,
      options: matchingCondition.options || {},
      isHidden: matchingCondition.hidden || false,
      isRequired: matchingCondition.required || false,
    });

    return new Field(field.id, field.name, field.type, field.collectionName, newMeta);
  }

  return field;
}

/**
 *
 * @param filter
 */
function parseFilter(filter: Filter | null): Filter {
  const userStore = useUserStore();

  if (!userStore.me) return filter ?? {};
  if (!userStore.me.id) return filter ?? {};

  const accountability: Accountability = {
    role: userStore.me.role.id,
    // @ts-expect-error
    user: userStore.me.id,
  };

  return parseFilterShared(filter, accountability) ?? {};
}

/**
 *
 * @param data
 */
function isRelationalData(data: any): boolean {
  const isRelationalData =
    isPlainObject(data) &&
    Object.keys(data).every((key) =>
      [
        "create",
        "currentJunctionItemIds",
        "newRelatedItemIds",
        "removeJunctionItemIds",
      ].includes(key),
    );

  return isRelationalData;
}

/**
 *
 * @param data
 */
function getRelationalDataForValidation(data: {
  create: ItemID[];
  currentJunctionsItemIds: ItemID[];
  newRelatedItemIds: ItemID[];
  removeJunctionItemIds: ItemID[];
}): ItemID[] | null {
  return !!data.currentJunctionsItemIds?.length ? data.currentJunctionsItemIds : null;
}
