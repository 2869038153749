import {
  type ValidationArguments,
  ValidatorConstraint,
  type ValidatorConstraintInterface,
  isInt,
  isUUID,
} from "class-validator";

@ValidatorConstraint({ name: "ItemIdValidationConstraint", async: false })
export class ItemIdValidationConstraint implements ValidatorConstraintInterface {
  validate(value: string | number): boolean {
    if (typeof value === "string") {
      return isUUID(value);
    }

    return isInt(value) && value > 0;
  }

  defaultMessage(validationArguments: ValidationArguments): string {
    if (typeof validationArguments.value === "string") return "item ID should be UUID";

    return "item ID should be positive integer";
  }
}
