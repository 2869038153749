import type { IFieldForm, FieldManyRelationalData } from "../lib/types";

export const defineFieldManyData = (
  data: FieldManyRelationalData,
): FieldManyRelationalData => {
  return data;
};

export const defineFieldFormInterface = (fieldFormInterface: IFieldForm): IFieldForm => {
  return fieldFormInterface;
};
