import { inject, injectable } from "inversify";
import { Subscription, filter, map, tap } from "rxjs";
import { AppEventBus, AppEvents } from "~/shared/lib/app-event-bus";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import type { IField } from "~/entities/field";
import { logger } from "~/service/logger/logger";
import type { IItem } from "~/entities/item";
import { useSystemReactionsStore } from "../store";
import type { IItemReactionsController } from "./types";

@injectable()
export class ItemReactionsController implements IItemReactionsController {
  private _item: IItem | undefined;

  private _fields: IField[];

  private _listenerSubscription: Subscription | undefined;

  constructor(
    @inject(INJECT_SYMBOLS.AppEventBus)
    private readonly _eventBus: AppEventBus,
  ) {
    this._fields = [];
    this.registerEventBusListeners();
  }

  setItem(item: IItem): this {
    this._item = item;
    return this;
  }

  setFields(fields: IField[]): this {
    this._fields = fields;
    return this;
  }

  destroy(): void {
    if (this._listenerSubscription) this._listenerSubscription.unsubscribe();
  }

  registerEventBusListeners(): void {
    const $actionProcessor = this._eventBus.observe().pipe(
      filter(() => !!this._item),
      filter((event) =>
        [
          AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW,
          AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW,
          AppEvents.ITEM_DATA_CHANGED,
          AppEvents.ITEM_UPDATED,
        ].includes(event.event),
      ),
      map((event) => {
        const systemReactionsStore = useSystemReactionsStore();
        return {
          event,
          reactions: systemReactionsStore.getAllByAppEvent(event),
        };
      }),
      filter((data) => !!data.reactions.length),
      map((data) => {
        return {
          event: data.event,
          reactions: data.reactions.sort((a, b) => a.priority - b.priority),
        };
      }),
      tap(async (data) => {
        // выполнение по приоритету
        for await (const reaction of data.reactions) {
          await reaction.action(data.event, {
            data: {
              item: this._item!,
              fields: this._fields,
            },
            utils: {
              AppEvents: AppEvents,
              Logger: logger(),
            },
          });
        }
      }),
    );

    this._listenerSubscription = $actionProcessor.subscribe();
  }
}
