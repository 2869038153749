<template>
  <div :class="['w-full', hasCardWrapper ? 'intro-y box overflow-hidden' : '']">
    <h3
      v-if="hasCardWrapper && !!cardTitle"
      class="text-xl font-medium intro-y px-3 pt-3 pb-4"
    >
      {{ cardTitle }}
    </h3>

    <component
      :is="renderingDisplayComponent"
      v-if="renderingDisplayComponent !== undefined"
      v-bind="props"
    ></component>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { useFieldsStore } from "~/entities/field";
  import { type FieldDisplayProp } from "../types";

  const props = defineProps<FieldDisplayProp>();

  const fieldsStore = useFieldsStore();

  const field = computed(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );

  const renderingDisplayComponent = computed<string | undefined>(() => {
    return field.value?.meta?.interface === "iv-iframe" ? "display-iframe" : undefined;
  });

  const hasCardWrapper = computed<boolean>(
    () => field.value?.meta?.displayOptions?.hasCardWrapper || false,
  );

  const cardTitle = computed<string>(
    () => field.value?.meta?.displayOptions?.cardTitle || "",
  );
</script>
