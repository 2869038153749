<template>
  <div>
    <Alert
      v-if="!relationInfo"
      :variant="'secondary'"
      :dismissible="false"
      class="flex items-center"
    >
      <Lucide icon="AlertCircle" class="w-6 h-6 mr-2" />

      {{ $t("field_relationship_not_setup") }}
    </Alert>

    <template v-else>
      <PTreeSelect
        v-if="isRelatedToTreeView"
        :options="treeNodes"
        :modelValue="treeViewModelValue"
        display="chip"
        selectionMode="checkbox"
        :loading="isRelatedItemsLoading"
        :placeholder="isRelatedItemsLoading ? $t('items_is_loading') : ''"
        :tabindex="String(props.field.meta.sortPosition)"
        :aria-label="getFieldLabel(props.field)"
        :emptyMessage="$t('field_select_empty_items')"
        class="w-full"
        inputClass="form-select sm:mt-2 sm:mr-2"
        @update:modelValue="onUpdateModelValue"
        v-on="dropdownEventListeners"
      >
        <template #value="slotProps">
          <template v-if="isArray(slotProps.value)">
            <div
              v-for="node of slotProps.value"
              :key="node.key"
              class="p-treeselect-token"
            >
              <span class="p-treeselect-token-label">
                <div
                  v-if="!!relatedItemsHashMap[node.data] && !!node.data"
                  class="flex items-center justify-between p-1"
                >
                  <RenderTemplate
                    :collectionName="relationInfo.relatedCollection ?? ''"
                    :item="relatedItemsHashMap[node.data]"
                    :fieldInfo="props.field"
                    :defaultTemplate="relatedRenderTemplate ?? ''"
                  />

                  <Button
                    v-if="
                      !!relatedCollection &&
                      canNavigateToRelationalItem(relatedCollection)
                    "
                    variant="transparent"
                    size="small"
                    @click="
                      routeToItem(
                        relatedCollection!.id,
                        relatedItemsHashMap[node.data].id,
                        router,
                      )
                    "
                  >
                    <i class="fa-solid fa-arrow-up-right-from-square"></i>
                  </Button>
                </div>

                <template v-else>
                  {{ node.data }}
                </template>
              </span>
            </div>
          </template>

          <template v-else>
            {{ slotProps.value }}
          </template>
        </template>
      </PTreeSelect>

      <MultiSelect
        v-else
        optionValue="value"
        optionLabel="label"
        dataKey="value"
        :options="stableOptions"
        :virtualScrollerOptions="virtualScrollerOptions"
        :editable="false"
        :emptySelectionMessage="$t('field_select_empty_selection')"
        :emptyFilterMessage="$t('field_select_filter_empty_items')"
        :emptyMessage="$t('field_select_empty_items')"
        :tabindex="props.field.meta.sortPosition"
        :aria-label="getFieldLabel(props.field)"
        :class="['w-full']"
        inputClass="form-select"
        :modelValue="stableModelValue"
        display="chip"
        :loading="isRelatedItemsLoading"
        :placeholder="isRelatedItemsLoading ? $t('items_is_loading') : ''"
        :disabled="isRelatedItemsLoading || props.field.meta.isReadonly"
        @update:modelValue="onUpdateModelValue"
        v-on="dropdownEventListeners"
      >
        <template #header>
          <div class="p-dropdown-header">
            <div class="p-dropdown-filter-container">
              <input
                type="text"
                autocomplete="off"
                role="search"
                :value="search"
                :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
                v-on="searchEventListeners"
              />

              <span :class="['p-dropdown-filter-icon', 'pi pi-search']" />
            </div>
          </div>
        </template>

        <template #chip="slotProps">
          <div
            v-if="!!relatedItemsHashMap[slotProps.value] && !!slotProps.value"
            class="flex items-center justify-between p-1"
          >
            <RenderTemplate
              :collectionName="relationInfo.relatedCollection ?? ''"
              :item="relatedItemsHashMap[slotProps.value]"
              :fieldInfo="props.field"
              :defaultTemplate="relatedRenderTemplate ?? ''"
            />
            <div class="multiselect-chip__button">
              <Button
                v-if="
                  !!relatedCollection && canNavigateToRelationalItem(relatedCollection)
                "
                size="small"
                :variant="'transparent'"
                class="border-none drop-shadow-none shadow-none"
                @click="
                  routeToItem(
                    relatedCollection!.id,
                    relatedItemsHashMap[slotProps.value].id,
                    router,
                  )
                "
              >
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </Button>
            </div>
          </div>

          <template v-else>
            {{ slotProps.value }}
          </template>
        </template>

        <template #option="slotProps">
          <RenderTemplate
            v-if="!!relatedItemsHashMap[slotProps.option.value]"
            :collectionName="relationInfo.relatedCollection ?? ''"
            :item="relatedItemsHashMap[slotProps.option.value]"
            :fieldInfo="props.field"
            :defaultTemplate="relatedRenderTemplate ?? ''"
          ></RenderTemplate>

          <template v-else>
            {{ slotProps.option.label }}
          </template>
        </template>
      </MultiSelect>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { useRouter } from "vue-router";
  import difference from "lodash/difference";
  import isArray from "lodash/isArray";
  import PTreeSelect from "primevue/treeselect";
  import { useRelationsStore } from "~/stores/relations";
  import { useRelationM2M } from "~/api/relations/composables/useRelationM2M";
  import { useRelationMultiple } from "~/api/relations/composables/useRelationMultiple";
  import type { CollectionInterface } from "~/api/collections/entities/CollectionInterface";
  import type { QueryMany } from "~/api/data-queries/types";
  import type { RelationInterface } from "~/api/relations/entity/RelationInterface";
  import {
    type FieldManyRelationalData,
    defineFieldManyData,
    addRelatedPrimaryKeyToFields,
    getFieldLabel,
  } from "~/entities/field";
  import {
    adjustFieldsForDisplays,
    getFieldsFromTemplate,
  } from "~/api/field-displays/utils";
  import { canNavigateToRelationalItem } from "~/api/collections/utils/availability";
  import { MultiSelect } from "~/shared/ui/MultiSelect";
  import { RenderTemplate } from "~/entities/render-template";
  import { createTextFromDisplayTemplate } from "~/entities/render-template";
  import { useDropdownItemsController } from "~/service/dropdown-items/composables/useDropdownItemsController";
  import type { TreeNodeCheckboxData } from "~/service/treeview/types";
  import { defineTreeNode, defineTreeNodeCheckboxData } from "~/service/treeview/defines";
  import Button from "~/shared/ui/Button";
  import { routeToItem, type IItem, type ItemID } from "~/entities/item";
  import Alert from "~/shared/ui/Alert";
  import Lucide from "~/shared/ui/Lucide";
  import {
    FieldInterfaceEmitId,
    type FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "../../emits";
  import type { FieldFormInterfaceProps } from "../../types";
  import type { TreeNode } from "primevue/tree";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const router = useRouter();

  type TreeModelValue = {
    [key: string | number]: { checked: boolean; partialChecked: boolean };
  };

  type LinearModelValue = (string | number)[];

  const { relationInfo, relatedCollection } = useRelationM2M(
    computed(() => props.collection.id),
    computed(() => props.field),
  );

  const handleUpdateManyData = (
    event: TreeModelValue | LinearModelValue,
  ): FieldManyRelationalData => {
    const newData = event;

    const currentJunctionItemIds = (
      props.item.getDataProperty(props.field.name) as FieldManyRelationalData
    ).currentJunctionItemIds;

    /**
     * @note start shared functional
     */
    const relatedCollectionPrimaryFieldName = relatedCollection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    )?.name;
    const relatedItemIdExpression = `${relationInfo.value?.fieldName}.${relatedCollectionPrimaryFieldName}`;
    /**
     * @note end shared functional
     */

    const newDataJunctionItems = initialJunctionItems.value.filter((junctionItem) => {
      const relatedItemId = junctionItem.getDataProperty(relatedItemIdExpression);

      if (newData instanceof Array) {
        return relatedItemId !== undefined && newData.includes(relatedItemId);
      }

      return relatedItemId !== undefined && Object.keys(newData).includes(relatedItemId);
    });

    const newDataJunctionItemIds = newDataJunctionItems.map((item) => item.id);

    const newDataJunctionItemRelatedIds = newDataJunctionItems.map((item) =>
      item.getDataProperty(relatedItemIdExpression),
    );

    const junctionItemIdsForRemove = difference(
      currentJunctionItemIds,
      newDataJunctionItemIds,
    );

    const updatedCurrentJunctionItemIds: (string | number)[] = newDataJunctionItemIds;

    const relatedItemIdsForCreateJunctionRelation = difference(
      newData instanceof Array ? newData : Object.keys(newData),
      newDataJunctionItemRelatedIds,
    );

    return defineFieldManyData({
      currentJunctionItemIds: updatedCurrentJunctionItemIds,
      newRelatedItemIds: relatedItemIdsForCreateJunctionRelation,
      removeJunctionItemIds: junctionItemIdsForRemove,
      create: [],
    });
  };

  const onUpdateModelValue = (event: TreeModelValue | LinearModelValue) => {
    const newData = handleUpdateManyData(event);

    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: newData,
      }),
    );
  };

  const {
    items: relatedCollectionItems,
    options: stableOptions,
    search,
    isLoading: isRelatedItemsLoading,
    virtualScrollerOptions,
    dropdownEventListeners,
    searchEventListeners,
  } = useDropdownItemsController(
    computed(() => relatedCollection.value),
    computed(() => props.field),
  );

  const renderTemplate = computed(() => {
    if (!relationInfo.value) return null;

    if (props.field.meta.options?.template) return props.field.meta.options.template;

    if (relationInfo.value.junctionCollection?.meta.displayTemplate)
      return relationInfo.value.junctionCollection.meta.displayTemplate;

    let relatedDisplayTemplate = relatedCollection.value?.meta.displayTemplate;
    if (relatedDisplayTemplate) {
      const regex = /({{.*?}})/g;
      const parts = relatedDisplayTemplate.split(regex).filter((p) => p);

      for (const part of parts) {
        if (part.startsWith("{{") === false) continue;
        const key = part.replace(/{{/g, "").replace(/}}/g, "").trim();
        const newPart = `{{${relationInfo.value.fieldName}.${key}}}`;

        relatedDisplayTemplate = relatedDisplayTemplate.replace(part, newPart);
      }

      return relatedDisplayTemplate;
    }

    const relatedCollectionPrimaryFieldName = relatedCollection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    )?.name;

    return `{{${relationInfo.value.fieldName}.${relatedCollectionPrimaryFieldName}}}`;
  });

  const fields = computed(() => {
    if (!relationInfo.value) return [];

    const displayFields = adjustFieldsForDisplays(
      getFieldsFromTemplate(renderTemplate.value ?? ""),
      relationInfo.value.junctionCollection as CollectionInterface,
    );

    return addRelatedPrimaryKeyToFields(
      relationInfo.value.junctionCollection as CollectionInterface,
      displayFields,
    );
  });

  const itemsRequestQuery = computed<QueryMany<unknown>>(() => {
    const query = {
      limit: -1,
      fields: fields.value || ["id"],
    };

    if (!relationInfo.value) return query;

    return query;
  });

  const { fetchedItems: initialJunctionItems } = useRelationMultiple(
    computed(() => props.item.id),
    relationInfo,
    itemsRequestQuery,
  );

  const relatedItemsHashMap = computed<Record<ItemID, IItem>>(() =>
    relatedCollectionItems.value.reduce<Record<ItemID, IItem>>((hashMap, item) => {
      if (item.id in hashMap) return hashMap;

      hashMap[item.id] = item;
      return hashMap;
    }, {}),
  );

  const relatedRenderTemplate = computed(
    () =>
      relatedCollection.value?.meta?.displayTemplate ||
      props.collection.meta.displayTemplate ||
      `{{${
        props.collection.fieldsInfo.find((fieldInfo) => fieldInfo.meta.isPrimaryKey)?.name
      }}}`,
  );

  const relatedPrimaryFieldExpression = computed<string>(() => {
    const junctionFieldName = relationInfo.value?.junctionField?.name;
    const relatedCollectionPrimaryFieldKey = relatedCollection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    )?.name;

    if (!junctionFieldName || !relatedCollectionPrimaryFieldKey) return "";

    return `${junctionFieldName}.${relatedCollectionPrimaryFieldKey}`;
  });

  const stableModelValue = computed<(string | number)[]>(() => {
    const fieldData = props.item.getDataProperty(
      props.field.name,
    ) as FieldManyRelationalData;

    const selectedJunctionItemIds = initialJunctionItems.value
      .filter((junctionItem) =>
        fieldData.currentJunctionItemIds.includes(junctionItem.id),
      )
      .map((junctionItem) =>
        junctionItem.getDataProperty(relatedPrimaryFieldExpression.value),
      )
      .filter((relatedItemId) => relatedItemId !== undefined);

    const selectedRelatedItemIds = new Set<string | number>(selectedJunctionItemIds);

    if (!!fieldData.newRelatedItemIds.length) {
      for (const relatedItemId of fieldData.newRelatedItemIds) {
        selectedRelatedItemIds.add(relatedItemId);
      }
    }

    return Array.from(selectedRelatedItemIds);
  });

  /**
   *
   * @note TreeSelect logic
   *
   */
  const relationsStore = useRelationsStore();

  const relationWithPotentialTreeView = computed<RelationInterface | undefined>(() =>
    relationsStore.relations.find((relation) => {
      return (
        relation.collectionName === relatedCollection.value?.id &&
        relation.relatedFieldName === relationInfo.value?.relatedFieldName
      );
    }),
  );

  const isRelatedToTreeView = computed<boolean>(() => {
    if (!relatedCollection.value) return false;

    const relationToPotentialTreeView = relationWithPotentialTreeView.value;
    if (!relationToPotentialTreeView) return false;

    const potentialTreeViewField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === relationToPotentialTreeView.meta?.oneField,
    );

    return potentialTreeViewField?.meta.interface === "list-o2m-tree-view";
  });

  /**
   * @note Non optimized algorithm. Excessive consumption of memory
   */
  const treeNodes = computed<TreeNode[]>(() => {
    if (!relatedCollectionItems.value || !relatedCollection.value) return [];

    const childrensField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.interface === "list-o2m-tree-view",
    );
    if (!childrensField) return [];

    const childrenDataExpression = `${childrensField.name}.currentJunctionItemIds`;

    const parentFieldName = relationWithPotentialTreeView.value?.fieldName ?? undefined;
    if (parentFieldName === undefined) return [];

    const parentField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === parentFieldName,
    );
    if (!parentField) return [];

    const items = relatedCollectionItems.value;

    const itemsAsTreeNodesMap: { [key: string]: TreeNode } = {};

    for (const item of items) {
      const label =
        createTextFromDisplayTemplate(item, relatedRenderTemplate.value) ??
        String(item.id);

      itemsAsTreeNodesMap[item.id] = defineTreeNode({
        key: String(item.id),
        label,
        data: item.id,
        children: [],
        meta: {
          parentId: item.getDataProperty(parentField.name),
          childrenIds: item.getDataProperty(childrenDataExpression),
        },
      });
    }

    const itemsTreeNodes = Object.values(itemsAsTreeNodesMap);

    for (let i = 0; i < itemsTreeNodes.length; i++) {
      const currentItem = itemsTreeNodes[i];

      const parentId = currentItem.meta.parentId;
      if (!parentId) continue;

      itemsAsTreeNodesMap[parentId].children?.push(currentItem);
    }

    return itemsTreeNodes.filter((treeNode) => {
      return !treeNode.meta.parentId;
    });
  });

  const treeViewModelValue = computed<{ [key: string]: TreeNodeCheckboxData }>(() => {
    const data: { [key: string]: TreeNodeCheckboxData } = {};

    for (const modelValue of stableModelValue.value) {
      data[modelValue] = defineTreeNodeCheckboxData({
        checked: true,
        partialChecked: false,
      });
    }

    return data;
  });
</script>

<style scoped></style>
