import { useNuxtApp } from "#app";
import { ref, computed } from "vue";
import { toaster } from "~/service/toaster";
import type { QueryMany } from "~/api/data-queries/types";
import type { IItem, IItemsGateway } from "~/entities/item";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";

// todo: AbortController
export const useLazyCollectionItems = () => {
  const itemsGateway = iocContainer.get<IItemsGateway>(INJECT_SYMBOLS.IItemsGateway);

  const { $i18n } = useNuxtApp();

  const collectionItems = ref<IItem[]>([]);
  const meta = ref<{ filter_count: number }>();

  const isLoading = ref<boolean>(false);

  const load = async (
    collectionName: string,
    query: QueryMany<unknown>,
  ): Promise<void> => {
    try {
      isLoading.value = true;
      const response = await itemsGateway.getManyByQuery(collectionName, query);

      collectionItems.value = response.data;
      meta.value = response.meta;
    } catch (err) {
      toaster().error($i18n.t("server_error_fetch_collection"));
    } finally {
      isLoading.value = false;
    }
  };

  return {
    collectionItems,
    meta: computed(() => meta.value),
    isLoading: computed(() => isLoading.value),
    load,
  };
};
