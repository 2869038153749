import {
  SystemReaction,
  setContextFieldFilter,
  findContextItemPropertyData,
  findEventPayloadItemPropertyData,
} from "~/entities/system-reactions";
import {
  type AppEvent,
  AppEvents,
  type AppEventsPayload,
} from "~/shared/lib/app-event-bus";
import { QueryFilterOperatorsEnum } from "~/api/data-queries/types";

export default new SystemReaction({
  id: "set-declarant-space-options",

  scopes: [
    AppEvents.ITEM_DATA_CHANGED,
    AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW,
    AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW,
  ],

  priority: 1,

  matcher: (event) => {
    const e = event as AppEvent<
      | AppEvents.ITEM_DATA_CHANGED
      | AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW
      | AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW
    >;

    if (e.payload.collectionName !== "requests") return false;

    const sourceFieldName = "declarant_id";

    if (e.event === AppEvents.ITEM_DATA_CHANGED) {
      return (
        sourceFieldName in
        (e.payload as AppEventsPayload[AppEvents.ITEM_DATA_CHANGED]).data
      );
    }

    if (
      event.event === AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW ||
      event.event === AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW
    )
      return true;

    return false;
  },

  action: async (event, context) => {
    const isRenderEvent: boolean =
      event.event === AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW ||
      event.event === AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW;

    const sourceData: any = isRenderEvent
      ? findContextItemPropertyData({ fieldPath: "declarant_id" }, context)
      : findEventPayloadItemPropertyData(
          { fieldPath: "declarant_id" },
          event as AppEvent<AppEvents.ITEM_DATA_CHANGED>,
        );

    if (!sourceData) {
      // todo: убрать фильтр по заявителю
      return;
    }

    setContextFieldFilter(
      {
        fieldName: "declarant_address",
        filterPath: "premises_users.directus_users_id",
        filterRule: QueryFilterOperatorsEnum._eq,
        filterValue: sourceData,
      },
      context,
    );
  },
});
