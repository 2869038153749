import heic2any from "heic2any";

export const convertHeicToJpeg = async (heicFile: File) => {
  const convertedBlob = await heic2any({
    blob: heicFile,
    toType: "image/jpg",
    quality: 1, // cuts the quality and size by half
  });

  return new File([convertedBlob as Blob], heicFile.name, {
    // @ts-expect-error
    type: convertedBlob.type,
  });
};
