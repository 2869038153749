<template>
  <img
    v-tooltip.right="{
      value: computedUserName,
      disabled: !withTooltip || !computedUserName,
    }"
    class="rounded-full"
    src="~/assets/images/avatar-placeholder.svg"
    :alt="computedUserName"
  />
</template>

<script setup lang="ts">
  import { computed, withDefaults } from "vue";

  interface Props {
    userName?: string;
    withTooltip?: Boolean;
  }

  const { userName, withTooltip } = withDefaults(defineProps<Props>(), {
    withTooltip: () => true,
  });

  const computedUserName = computed<string | undefined>(() => {
    return userName ?? undefined;
  });
</script>
