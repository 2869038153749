import "reflect-metadata";
import { logger } from "~/service/logger/logger";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import type { CollectionsQueryGatewayInterface } from "~/api/collections/gateway/CollectionsQueryGatewayInterface";
import { CollectionsQueryGateway } from "~/api/collections/gateway/CollectionsQueryGateway";
import type { CommentsDataGatewayInterface } from "~/api/comments/gateway/CommentsDataGatewayInterface";
import CommentsDataGateway from "~/api/comments/gateway/CommentsDataGateway";
import { type UsersGatewayInterface, UserGateway } from "~/entities/user";
import type { LayoutPresetsDataGatewayInterface } from "~/api/layout-presets/gateways/LayoutPresetsDataGatewayInterface";
import { LayoutPresetsDataGateway } from "~/api/layout-presets/gateways/LayoutPresetsDataGateway";
import { AppEventBus } from "~/shared/lib/app-event-bus";
import { RelationsGateway } from "~/api/relations/gateway/RelationsGateway";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";
import type { MenuGatewayInterface } from "~/api/menu/gateway/MenuGatewayInterface";
import { PortalMenuGateway } from "~/api/menu/gateway/PortalMenuGateway";
import { LayoutPresetsMapper } from "~/api/layout-presets/mappers/layout-presets.mapper";
import type { PermissionsMapperInterface } from "~/api/permissions/mapper/PermissionsMapperInterface";
import { PermissionsDirectusMapper } from "~/api/permissions/mapper/PermissionsDirectusMapper";
import type { PermissionsGatewayInterface } from "~/api/permissions/gateway/PermissionsGatewayInterface";
import { PermissionsDirectusGateway } from "~/api/permissions/gateway/PermissionsDirectusGateway";
import { type RolesGatewayInterface, RolesGateway } from "~/entities/role";
import {
  ItemsWriteGateway,
  ItemCommitWork,
  ItemsGateway,
  type ItemsWriteGatewayInterface,
  type ItemCommitWorkInterface,
  type IItemsGateway,
} from "~/entities/item";
import {
  type IItemReactionsController,
  ItemReactionsController,
} from "~/entities/system-reactions";
import { LocalStorageAuthDriver, type StorageAuthDriver } from "~/shared/api/directus";
import {
  type AuthGatewayInterface,
  AuthGateway,
  type AuthByPhoneServiceInterface,
  AuthByPhoneService,
} from "~/entities/authentication";
import {
  LocalStorageDriver,
  type IPushTokenStorageDriver,
} from "~/shared/api/firebase/lib/driver";
import {
  MessagingTokenController,
  type IReactionsController,
} from "~/entities/system-reactions";
import {
  type IBreadcrumbsController,
  BreadcrumbsController,
  type IBreadcrumbsHandler,
  BreadcrumbsCollectionHandler,
} from "~/features/breadcrumbs/model";
import { type IPageViewsGateway, PageViewsGateway } from "~/entities/pageviews";
import {
  GrafanaDynamicHeightHandler,
  GrafanaDomFinder,
  IFrameSizer,
  type IFrameHandler,
  GrafanaHidePanelTitleMenusHandler,
} from "~/shared/lib/iframe";
import { GrafanaAppHandler } from "~/api/field-displays/iframe/lib/app-handlers/grafana-app.handler";
import { AbstractIFrameFieldAppHandler } from "~/api/field-displays/iframe/lib/app-handlers/abstract-app-handler.abstract";
import { type IRevisionGateway, RevisionGateway } from "~/entities/revision";
import type { Logger } from "pino";
import type {
  GraphqlClient as DataStudioGraphqlClient,
  RestClient as DataStudioRestClient,
} from "@directus/sdk";
import type { Container } from "inversify";

export function loadInversionBindings(container: Container) {
  container
    .bind<CollectionsQueryGatewayInterface>(
      INJECT_SYMBOLS.CollectionsQueryGatewayInterface,
    )
    .to(CollectionsQueryGateway);

  container
    .bind<ItemsWriteGatewayInterface>(INJECT_SYMBOLS.ItemsWriteGatewayInterface)
    .to(ItemsWriteGateway);

  container.bind<IItemsGateway>(INJECT_SYMBOLS.IItemsGateway).to(ItemsGateway);

  container
    .bind<ItemCommitWorkInterface>(INJECT_SYMBOLS.ItemCommitWorkInterface)
    .to(ItemCommitWork);

  container.bind<IRevisionGateway>(INJECT_SYMBOLS.IRevisionGateway).to(RevisionGateway);

  container
    .bind<CommentsDataGatewayInterface>(INJECT_SYMBOLS.CommentsDataGatewayInterface)
    .to(CommentsDataGateway);

  container
    .bind<DataStudioGraphqlClient<any>>(INJECT_SYMBOLS.DatastudioGraphqlClient)
    .toDynamicValue(() => {
      const { client } = useDataStudioClient();
      return client;
    });

  container
    .bind<UsersGatewayInterface>(INJECT_SYMBOLS.UsersGatewayInterface)
    .to(UserGateway);

  container
    .bind<LayoutPresetsMapper>(INJECT_SYMBOLS.LayoutPresetsMapper)
    .to(LayoutPresetsMapper);
  container
    .bind<LayoutPresetsDataGatewayInterface>(
      INJECT_SYMBOLS.LayoutPresetsDataGatewayInterface,
    )
    .to(LayoutPresetsDataGateway);

  container
    .bind<AppEventBus>(INJECT_SYMBOLS.AppEventBus)
    .toDynamicValue(() => new AppEventBus())
    .inSingletonScope();

  container
    .bind<DataStudioRestClient<any>>(INJECT_SYMBOLS.DatastudioRestClient)
    .toDynamicValue(() => {
      const { client } = useDataStudioClient();
      return client;
    });

  container.bind<RelationsGateway>(INJECT_SYMBOLS.RelationsGateway).to(RelationsGateway);

  container
    .bind<MenuGatewayInterface>(INJECT_SYMBOLS.PortalMenuGateway)
    .to(PortalMenuGateway);

  container
    .bind<PermissionsMapperInterface>(INJECT_SYMBOLS.PermissionsMapperInterface)
    .to(PermissionsDirectusMapper);

  container
    .bind<PermissionsGatewayInterface>(INJECT_SYMBOLS.PermissionsGatewayInterface)
    .to(PermissionsDirectusGateway);

  container.bind<Logger>(INJECT_SYMBOLS.Logger).toDynamicValue(() => logger());

  container
    .bind<RolesGatewayInterface>(INJECT_SYMBOLS.RolesGatewayInterface)
    .to(RolesGateway);

  container
    .bind<IItemReactionsController>(INJECT_SYMBOLS.ItemReactionsController)
    .to(ItemReactionsController);

  container
    .bind<IReactionsController>(INJECT_SYMBOLS.MessagingTokenController)
    .to(MessagingTokenController);

  container
    .bind<StorageAuthDriver>(INJECT_SYMBOLS.StorageAuthDriver)
    .to(LocalStorageAuthDriver);

  container
    .bind<AuthGatewayInterface>(INJECT_SYMBOLS.AuthGatewayInterface)
    .to(AuthGateway);

  container
    .bind<AuthByPhoneServiceInterface>(INJECT_SYMBOLS.AuthByPhoneServiceInterface)
    .to(AuthByPhoneService);

  container
    .bind<IPushTokenStorageDriver>(INJECT_SYMBOLS.PushTokenStorageDriver)
    .to(LocalStorageDriver);

  container
    .bind<IBreadcrumbsController>(INJECT_SYMBOLS.BreadcrumbsController)
    .to(BreadcrumbsController);

  container
    .bind<IBreadcrumbsHandler>(INJECT_SYMBOLS.BreadcrumbsCollectiontHandler)
    .to(BreadcrumbsCollectionHandler);

  container.bind<IPageViewsGateway>(INJECT_SYMBOLS.PageViewGateway).to(PageViewsGateway);

  container.bind<GrafanaDomFinder>(INJECT_SYMBOLS.GrafanaDomFinder).to(GrafanaDomFinder);

  container.bind<IFrameSizer>(INJECT_SYMBOLS.IFrameSizer).to(IFrameSizer);

  container
    .bind<IFrameHandler>(INJECT_SYMBOLS.IFrameGrafanaDynamicHeightHandler)
    .to(GrafanaDynamicHeightHandler);

  container
    .bind<AbstractIFrameFieldAppHandler>(INJECT_SYMBOLS.IFrameFieldGrafanaAppHandler)
    .to(GrafanaAppHandler);

  container
    .bind<IFrameHandler>(INJECT_SYMBOLS.IFrameGrafanaHidePanelTitleMenusHandler)
    .to(GrafanaHidePanelTitleMenusHandler);
}
