import "reflect-metadata";
import { useRelationsStore } from "~/stores/relations";
import { useAppStore } from "~/stores/app";
import { useSettingsStore } from "~/entities/settings";
import { useAuthStore } from "~/stores/auth";
import { useUserStore } from "~/entities/user";
import { useFieldsStore } from "~/entities/field";
import { useAppControllersStore } from "~/stores/appControllers";
import { useLocaleStore } from "~/stores/locale";
import { useSocketConnectionsStore } from "~/stores/socket-connections";
import { usePresetsStore } from "~/stores/presets";
import { useCollecitonsStore } from "~/stores/collections";
import { usePermissionsStore } from "~/stores/permissions";
import { usePortalMenuStore } from "~/api/menu/stores/portalMenuStore";
import { useRolesStore } from "~/entities/role";
import { useAppsStore } from "~/entities/apps";
import { initializeFirebaseApp } from "~/shared/api/firebase";
import { logger } from "../logger/logger";

export const initialize = async () => {
  const appStore = useAppStore();

  if (appStore.initialized) return;

  logger().debug(`Starting app initialization`);

  await useRolesStore().initialize();
  await useAuthStore().initialize();
  await useCollecitonsStore().initialize();
  await useFieldsStore().initialize();
  await useRelationsStore().initialize();
  await useSettingsStore().initialize();
  await useAppControllersStore().initialize();
  await useLocaleStore().initialize();
  await useSocketConnectionsStore().initialize();
  await usePortalMenuStore().initialize();
  await usePresetsStore().initialize();
  await usePermissionsStore().initialize();
  await useAppsStore().initialize();
  await initializeFirebaseApp();

  appStore.$patch({
    initialized: true,
  });

  logger().debug(`Finished app initialization`);
};

export const deinitialize = async () => {
  logger().debug(`Starting app deinitialization.`);

  await Promise.allSettled([
    useFieldsStore().deinitialize(),
    useRelationsStore().deinitilize(),
    useAuthStore().deinitialize(),
    useUserStore().deinitialize(),
    useAppStore().deinitialize(),
    useAppControllersStore().deinitialize(),
    useLocaleStore().deinitialize(),
    useSocketConnectionsStore().deinitialize(),
    usePortalMenuStore().deinitialize(),
    usePresetsStore().deinitialize(),
    useCollecitonsStore().deinitialize(),
    usePermissionsStore().deinitialize(),
    useRolesStore().deinitialize(),
    useSettingsStore().deinitilize(),
  ]);

  logger().debug(`Finished app deinitialization`);
};
