import { useNuxtApp } from "#app";
import { unref } from "vue";
import { type IField, useFieldsStore } from "~/entities/field";
import type { CollectionInterface, CollectionMetaInterface } from "./CollectionInterface";

export default class Collection implements CollectionInterface {
  constructor(
    private _id: string,
    private _meta: CollectionMetaInterface,
  ) {}

  get id(): string {
    return this._id;
  }

  get meta(): CollectionMetaInterface {
    return this._meta;
  }

  get label(): string {
    if (!this._meta.translations.length) return this._id;

    const { $i18n } = useNuxtApp();
    const localeId = unref($i18n.locale);
    const foundTranslation = this._meta.translations.find(
      (translation) => translation.language === localeId,
    );

    return foundTranslation?.translation || this._id;
  }

  // todo: избавиться от этого метода. Получение через fieldsStore
  get fieldsInfo(): IField[] {
    const fieldsStore = useFieldsStore();
    return fieldsStore.fieldsInfo.filter((field) => field.collectionName === this._id);
  }

  // todo: избавиться от этого метода. Получение через fieldsStore
  getPrimaryFieldInfo(): IField | undefined {
    return this.fieldsInfo.find((fieldInfo) => fieldInfo.meta.isPrimaryKey);
  }
}
