import { defineNuxtPlugin } from "#app";
import { registerExtensions } from "~/api/extensions";
import { FeaturesRepository, type FeaturesRepositoryInterface } from "~/api";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { iocContainer } from "~/inversify.config";

export default defineNuxtPlugin((nuxtApp) => {
  registerExtensions(nuxtApp.vueApp);

  iocContainer
    .bind<FeaturesRepositoryInterface>(INJECT_SYMBOLS.FeaturesRepositoryInterface)
    .toDynamicValue(() => new FeaturesRepository(nuxtApp.vueApp))
    .inSingletonScope();
});
