<template>
  <div>
    <slot name="customPreviewsContainer"></slot>

    <div
      ref="refDropzone"
      :class="classDropzoneContainer"
      class="dropzone"
      :style="styleDropzoneContainer"
    >
      <div class="dz-default dz-message">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from "#i18n";
  import { onMounted, reactive, ref, watch, watchEffect, type PropType } from "vue";
  import Dropzone, { type DropzoneFile } from "dropzone";
  import { logger } from "~/service/logger/logger";
  import { createStubFilePreviewBlob } from "~/shared/lib/file";
  import { dropzoneDefaultPreviewHtml } from "./dropzoneDefaultPreviewHtml";
  import type { DropzoneExistingMockedFile, IDrozoneUploaderEmits } from "../lib/types";

  export type QueueController = {
    start: Function | null;
    queuedFiles: DropzoneFile[];
  };

  export type FileController = {
    open: Function | null;
    hideSingleProgress: (file: DropzoneFile) => void;
    showSingleProgress: (file: DropzoneFile) => void;
    _hiddenInput: HTMLInputElement | null;
  };

  const emit = defineEmits<IDrozoneUploaderEmits>();

  const props = defineProps({
    acceptedFiles: {
      type: Array as PropType<string[] | string>,
      required: true,
      default: [".png", ".jpg", ".jpeg"],
    },
    isDownloadAllowed: {
      type: Boolean,
      required: false,
      default: true,
    },
    urlUpload: {
      type: String,
      required: true,
      default: "",
    },
    existingFile: {
      type: [Object, Array] as PropType<DropzoneFile | DropzoneFile[] | null>,
      required: false,
      default: [],
    },
    uploadParamName: {
      type: String,
      required: false,
      default: "file",
    },
    renameFileCb: {
      type: Function as PropType<(file: File) => string>,
      required: false,
      default: (file: File) => file.name,
    },
    maxFiles: {
      type: Number,
      required: false,
      default: 1,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewsContainer: {
      type: Object as PropType<HTMLElement | null>,
      required: false,
      default: null,
    },
    previewTemplate: {
      type: String,
      required: false,
      default: null,
    },
    customOptions: {
      type: Object,
      required: false,
      default: {},
    },
    isHideProgressBeforeUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    thumbnailClickCb: {
      type: Function as PropType<
        (evt: MouseEvent, previewMockFile: DropzoneExistingMockedFile) => void
      >,
      required: false,
      default: (): void => {},
    },
    classDropzoneContainer: {
      type: [Object, Array],
      required: false,
      default: [],
    },
    styleDropzoneContainer: {
      type: Object,
      required: false,
      default: {},
    },
    responseParser: {
      type: Function as PropType<(response: unknown) => Object>,
      required: false,
      default: (response: unknown): Object | unknown => {
        if (typeof response === "string") {
          return JSON.parse(response);
        }

        return response;
      },
    },
  });

  const { t } = useI18n();

  const refDropzone = ref<HTMLElement | null>(null);

  const dropzoneInstance = ref<Dropzone | null>(null);

  /**
   * Controller functions sets after dropzone instance creates
   */
  const queueController: QueueController = {
    start: null,

    queuedFiles: [],
  };

  const fileController: FileController = reactive({
    open: () => void 0,

    hideSingleProgress: (file: DropzoneFile) => {
      const progressElement =
        file.previewElement.querySelector<HTMLElement>(`.dz-progress`);
      if (!progressElement) return;

      progressElement.style.visibility = "hidden";
    },

    showSingleProgress: (file: DropzoneFile) => {
      const progressElement =
        file.previewElement.querySelector<HTMLElement>(`.dz-progress`);
      if (!progressElement) return;

      progressElement.style.visibility = "visible";
    },
    _hiddenInput: null,
  });

  const dropzoneInitialConfig = {
    url: props.urlUpload,
    paramName: props.uploadParamName,
    addRemoveLinks: true,
    uploadMultiple: false,
    clickable: !props.isDisabled,
    autoProcessQueue: false,
    maxFiles: props.maxFiles,
    acceptedFiles:
      props.acceptedFiles instanceof Array
        ? props.acceptedFiles.join(",")
        : props.acceptedFiles,
    renameFile: props.renameFileCb,
    parallelUploads: 1,
  };

  const makeFilesReadonly = () => {
    dropzoneInstance.value?.files.forEach((file) => {
      file.previewElement.querySelector(`[data-dz-remove]`)?.remove();
    });
  };

  watchEffect(() => {
    if (!!props.isDisabled) {
      makeFilesReadonly();
    }
  });

  /**
   * Render existing file to previews
   */
  const renderExistingFile = (
    dropzone: Dropzone,
    existingFile: DropzoneExistingMockedFile,
  ) => {
    existingFile.isMocked = true;

    dropzone.files.push(existingFile);

    dropzone.emit("addedfile", existingFile);

    dropzone.createThumbnail(
      existingFile,
      dropzone.options.thumbnailWidth,
      dropzone.options.thumbnailHeight,
      dropzone.options.thumbnailMethod,
      true,
      async (thumbnail: Event | string) => {
        if (thumbnail instanceof Event) {
          handleFallbackThumbnail();
          return;
        }

        dropzone.emit("thumbnail", existingFile, thumbnail);

        function handleFallbackThumbnail() {
          const canvasW = dropzone.options.thumbnailWidth;
          const canvasH = dropzone.options.thumbnailHeight;

          if (!canvasH || !canvasW) {
            logger().error("Error on calculation canvas");
            return;
          }

          const svgH = canvasH / 1.5;
          const svgW = canvasW / 1.5;

          const previewBlob = createStubFilePreviewBlob(svgW, svgH);

          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = canvasW;
          canvas.height = canvasH;

          const offsetL = (canvasW - svgW) / 2;
          const offsetT = (canvasH - svgH) / 2;

          const url = URL.createObjectURL(previewBlob);
          const image = document.createElement("img");
          image.src = url;

          image.onload = () => {
            ctx?.drawImage(image, offsetL, offsetT);

            dropzone.emit("thumbnail", existingFile, canvas.toDataURL("image/png"));
          };
          return;
        }
      },
    );
  };

  const isFileAlreadyExisting = (file: DropzoneFile): boolean => {
    return (
      dropzoneInstance.value?.files.findIndex(
        (existingFile) => existingFile.upload?.uuid === file.upload?.uuid,
      ) !== -1
    );
  };

  const setRemoveLockFilesMutation = (files: DropzoneFile[]) => {
    for (const dropzoneFile of files) {
      // @ts-expect-error
      dropzoneFile.hasRemoveLock = true;
    }
  };

  const unsetRemoveLockFilesMutation = (files: DropzoneFile[]) => {
    for (const dropzoneFile of files) {
      // @ts-expect-error
      dropzoneFile.hasRemoveLock = false;
    }
  };

  let resetSyncController: any = null;

  const resetFiles = () => {
    const promise = new Promise<boolean>((resolve, reject) => {
      if (!dropzoneInstance.value?.files.length) {
        resolve(true);
        return;
      }
      setRemoveLockFilesMutation(dropzoneInstance.value.files);

      resetSyncController = { resolve, reject };

      dropzoneInstance.value?.removeAllFiles();
    }).finally(() => {
      if (dropzoneInstance.value === null) return;
      unsetRemoveLockFilesMutation(dropzoneInstance.value.files);
    });

    return promise;
  };

  watch(
    () => [dropzoneInstance.value, props.existingFile],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ([newDropzone, newExistingFile]) => {
      if (dropzoneInstance.value === null) {
        return;
      }

      await resetFiles();

      if (newExistingFile instanceof Array) {
        const filesForRendering = newExistingFile.filter((file) => !!file);

        for (const file of filesForRendering) {
          if (isFileAlreadyExisting(file)) continue;

          renderExistingFile(dropzoneInstance.value, file as DropzoneExistingMockedFile);
        }

        return;
      }

      if (newExistingFile === null || newExistingFile === undefined) return;

      // @ts-expect-error
      if (isFileAlreadyExisting(newExistingFile)) {
        return;
      }

      renderExistingFile(
        dropzoneInstance.value,
        newExistingFile as DropzoneExistingMockedFile,
      );
    },
    {
      immediate: true,
      deep: false,
    },
  );

  const onDropzoneError = function (file: DropzoneFile, message: string | Object) {
    emit("dropzone:error", { file, message });
  };

  const onDropzoneAddedFile = function (this: Dropzone, file: DropzoneFile) {
    if (this.files.length > props.maxFiles) {
      this.emit("error", file, { error: "DROPZONE_ERROR_MAX_FILES_REACHED" });
      return;
    }

    if (props.isHideProgressBeforeUpload) {
      fileController.hideSingleProgress(file);
    }

    props.isDownloadAllowed && addDownloadLinkToPreview(file);
    /**
     * Incorrect queue filling fix
     */
    setTimeout(() => {
      queueController.queuedFiles = this.getQueuedFiles();

      emit("dropzone:addedFile", {
        addedFile: file,
        queuedFiles: this.getQueuedFiles(),
        rejectedFiles: this.getRejectedFiles(),
        acceptedFiles: this.getAcceptedFiles(),
        uploadingFiles: this.getUploadingFiles(),
      });
    }, 500);

    function addDownloadLinkToPreview(file: DropzoneFile): void {
      const previewRemoveLinkEl = file.previewElement.querySelector("[data-dz-remove]");

      if (!!previewRemoveLinkEl) {
        const downloadUrl = window.URL.createObjectURL(file);

        previewRemoveLinkEl.insertAdjacentHTML(
          "beforebegin",
          `<a href="${downloadUrl}" download="${file.name}">${t("download")}</a>`,
        );
      }
    }
  };

  const onDropzoneComplete = function (file: DropzoneFile) {
    emit("dropzone:complete", { file });
  };

  const onDropzoneProcessing = function (file: DropzoneFile) {
    if (props.isHideProgressBeforeUpload) fileController.showSingleProgress(file);
  };

  const onDropzoneRemovedFile = function (this: Dropzone, file: DropzoneFile) {
    // if (file.hasRemoveLock) {
    //   return;
    // }

    emit("dropzone:removed", { file });
  };

  const onDropzoneSending = function (
    file: DropzoneFile,
    xhr: XMLHttpRequest,
    formData: any,
  ) {
    emit("dropzone:sending", { file, xhr, formData });
  };

  const onDropzoneSuccess = function (
    this: Dropzone,
    file: DropzoneFile,
    response: unknown,
  ) {
    const defaultParser = (response: any): Object => {
      if (typeof response === "string") {
        return JSON.parse(response);
      }
      return response;
    };

    try {
      const parsedResponse = props.responseParser
        ? props.responseParser(response)
        : defaultParser(response);

      emit("dropzone:success", { file, serverResponse: parsedResponse });

      queueController.queuedFiles = [];
    } catch (error) {
      this.emit("error", file, {
        error: "DROPZONE_ERROR_LOADING_FILE",
        event: "onsuccess",
        // @ts-expect-error
        message: error?.message,
      });
    }
  };

  const onDropzoneCanceled = function () {};

  const onDropzoneMaxFilesExceeded = function (file: DropzoneFile) {
    emit("dropzone:maxfilesexceeded", { addedFile: file });
  };

  const onDropzoneQueueComplete = function () {
    emit("dropzone:queueComplete");
  };

  /**
   * Container preview slot availability fix
   */
  const fixPreviewsContainerNullable = async (): Promise<void> => {
    return new Promise((res) => {
      setTimeout(() => {
        return res();
      }, 500);
    });
  };

  const onThumbnailAddedRegisterCustomClickListener = (
    mockFile: DropzoneExistingMockedFile,
  ) => {
    if (!props.thumbnailClickCb || !mockFile.previewElement) return;

    mockFile.previewElement
      .querySelector(".dz-details")
      .addEventListener("click", (evt: MouseEvent) => {
        evt.preventDefault();
        props.thumbnailClickCb(evt, mockFile);
      });
  };

  const onReset = () => {
    if (resetSyncController === null) return;

    resetSyncController.resolve(true);
  };

  onMounted(async () => {
    if (props.previewTemplate) await fixPreviewsContainerNullable();

    dropzoneInstance.value = new Dropzone(refDropzone.value as HTMLElement, {
      ...dropzoneInitialConfig,
      previewsContainer: props.previewsContainer,
      previewTemplate: props.previewTemplate
        ? props.previewTemplate
        : dropzoneDefaultPreviewHtml,
      ...props.customOptions,
    });

    queueController.start = dropzoneInstance.value.processQueue.bind(
      dropzoneInstance.value,
    );

    fileController.open = () => refDropzone.value?.click();

    dropzoneInstance.value.on("queuecomplete", onDropzoneQueueComplete);
    dropzoneInstance.value.on("error", onDropzoneError);
    dropzoneInstance.value.on("addedfile", onDropzoneAddedFile);
    dropzoneInstance.value.on("complete", onDropzoneComplete);
    dropzoneInstance.value.on("processing", onDropzoneProcessing);
    dropzoneInstance.value.on("removedfile", onDropzoneRemovedFile);
    dropzoneInstance.value.on("sending", onDropzoneSending);
    dropzoneInstance.value.on("success", onDropzoneSuccess);
    dropzoneInstance.value.on("canceled", onDropzoneCanceled);
    dropzoneInstance.value.on("maxfilesexceeded", onDropzoneMaxFilesExceeded);
    dropzoneInstance.value.on("thumbnail", onThumbnailAddedRegisterCustomClickListener);
    dropzoneInstance.value.on("reset", onReset);
  });

  defineExpose({
    fileController,
    queueController,
  });
</script>
