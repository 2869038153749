import { type AppEvent, AppEvents } from "~/shared/lib/app-event-bus";
import {
  SystemReaction,
  setContextItemPropertyDataToEmpty,
} from "~/entities/system-reactions";

export default new SystemReaction({
  id: "reset-declarant-on-has-declarant-change",

  priority: 0,

  scopes: [AppEvents.ITEM_DATA_CHANGED],

  matcher: (event) => {
    const e = event as AppEvent<AppEvents.ITEM_DATA_CHANGED>;
    return e.payload.collectionName === "requests" && "has_declarant" in e.payload.data;
  },

  action: async (event, context) => {
    setContextItemPropertyDataToEmpty({ fieldPath: "declarant_id" }, context);
  },
});
