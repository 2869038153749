export interface PermissionInterface {
  roleId: string;
  collectionName: string;
  action: PermissionAction;
  fields: string[] | ["*"];
  validation: Record<string, any>;
  presets: Record<string, any>;
  isSystem: boolean;
}

export enum PermissionAction {
  CREATE = "create",
  UPDATE = "update",
  READ = "read",
  DELETE = "delete",
  SHARE = "share",
}
