import { injectable } from "inversify";
import type { IField } from "~/entities/field";
import type { IIFrameFieldHandler } from "./types";

@injectable()
export abstract class AbstractIFrameFieldAppHandler {
  private _nextHandler: IIFrameFieldHandler;

  setNext(handler: IIFrameFieldHandler): IIFrameFieldHandler {
    this._nextHandler = handler;
    return handler;
  }

  handle(iframeElement: HTMLIFrameElement, field: IField): void {
    if (this._nextHandler) {
      return this._nextHandler.handle(iframeElement, field);
    }

    return;
  }
}
