import { Item } from "~/entities/item";

export class App extends Item<AppExternalData | AppInternalData> {
  get title(): string {
    return this.getDataProperty("title");
  }

  get description(): string {
    return this.getDataProperty("description") || "";
  }

  get isExternal(): boolean {
    return this.getDataProperty("is_external");
  }

  get pageUri(): string {
    if (this.isExternal) {
      return this.getDataProperty("external_uri");
    }

    return `/app/${this.id}`;
  }

  get appUri(): string {
    if (this.isExternal) {
      return this.getDataProperty("external_uri");
    }

    return this.getDataProperty("internal_path");
  }
}

export type AppBaseData = {
  id: string;
  sort: number;
  title: string;
  description?: string;
  icon_class: string;
  is_external: boolean;
};

export type AppExternalData = AppBaseData & {
  external_uri: string;
};

export type AppInternalData = AppBaseData & {
  internal_path: string;
};
