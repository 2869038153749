import isNil from "lodash/isNil";
import Collection from "../entities/Collection";
import type { CollectionMetaTranslationType } from "@directus/sdk";
import type { Collection as DataStudioCollection } from "@directus/types";
import type {
  CollectionInterface,
  CollectionMetaInterface,
  CollectionTranslationInterface,
} from "../entities/CollectionInterface";

/**
 *
 * @param translation
 * @returns
 */
const castCollectionTranslationToMeta = (
  translation: CollectionMetaTranslationType,
): CollectionTranslationInterface => ({
  language: translation.language,
  translation: translation.translation,
});

/**
 *
 */
export const castCollectionFromDataStudioApiToEntity = (
  collectionData: DataStudioCollection,
): CollectionInterface => {
  if (isNil(collectionData.meta)) {
    return new Collection(collectionData.collection, {} as CollectionMetaInterface);
  }

  const collectionMeta: CollectionMetaInterface = {
    color: collectionData.meta!.color,
    isHidden: collectionData.meta!.hidden,
    isSingletone: collectionData.meta!.singleton,
    note: collectionData.meta!.note,
    sortField: collectionData.meta!.sort_field,
    sortPosition: collectionData.meta!.sort,
    translations:
      collectionData.meta!.translations?.map((translation) =>
        castCollectionTranslationToMeta(translation),
      ) ?? [],
    displayTemplate: collectionData.meta!.display_template,
    archiveField: collectionData.meta!.archive_field,
    archiveAppFilter: collectionData.meta!.archive_app_filter,
    archiveValue: collectionData.meta!.archive_value,
    unarchiveValue: collectionData.meta!.unarchive_value,
  };

  return new Collection(collectionData.collection, collectionMeta);
};
