<script lang="ts">
  export default {};
</script>

<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path d="M11 2H2v9h9V2zm0 11H2v9h9v-9zm2-11h9v9h-9V2zm9 11h-9v9h9v-9z" />
  </svg>
</template>
