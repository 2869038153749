import type { CollectionInterface } from "~/api/collections/entities/CollectionInterface";
import type { IField } from "~/entities/field";

export interface RelationInterface {
  collectionName: string;

  fieldName: string;

  relatedCollection: string | null;

  relatedFieldName: string | null;

  meta: RelationMetaInterface | null;
}

export enum RelationTypes {
  MANY_TO_ONE = "m2o",
  MANY_TO_MANY = "m2m",
  ONE_TO_MANY = "o2m",
  MANY_TO_ANY = "m2a",
}

export interface RelationManyInterface extends RelationInterface {
  type: RelationTypes;

  junctionCollection: CollectionInterface | undefined;

  junctionField: IField | undefined;

  reverseJunctionField: IField | undefined;

  junction: RelationInterface | undefined;
}

export interface RelationMetaInterface {
  id: number;

  manyCollection: string;

  manyField: string;

  oneCollection: string | null;

  oneField: string | null;

  oneCollectionField: string | null;

  oneAllowedCollections: string | null;

  oneDeselectAction: string;

  junctionField: string | null;

  sortField: string | null;

  isSystem?: boolean;
}
