<template>
  <Popover>
    <div class="flex items-center">
      <template v-if="!!selectedIcon">
        <icon :name="selectedIcon" class="mr-2" />

        <Lucide
          icon="X"
          class="w-5 h-5 cursor-pointer mr-2"
          @click="() => onUpdateModelValue('')"
        />
      </template>

      <FormInput v-model="searchQuery" @blur="onBlur" @focus="onFocus" />
    </div>

    <transition name="fade-fast">
      <div v-show="isOpened" class="w-full absolute z-30 top-[100%] left-0">
        <HeadlessPopoverPanel
          static
          style="{
            contentVisibility: isOpened ? 'visible' : 'hidden'
          }"
          class="max-h-60 overflow-auto p-2 shadow-[0px_3px_20px_#0000000b] bg-white border-transparent rounded-md dark:bg-darkmode-600 dark:border-transparent"
        >
          <div class="flex flex-col gap-4">
            <template v-for="group in filteredIcons" :key="group.name">
              <div v-if="!!group.icons.length" class="icon-grid__group">
                <span class="icon-grid__group-title">{{ group.name }}</span>

                <div class="icon-grid__group-item">
                  <icon
                    v-for="singleicon in group.icons"
                    :key="singleicon"
                    :name="singleicon"
                    large
                    clickable
                    :class="[{ active: singleicon === selectedIcon }]"
                    @click="onUpdateModelValue(singleicon)"
                  />
                </div>
              </div>
            </template>
          </div>
        </HeadlessPopoverPanel>
      </div>
    </transition>
  </Popover>
</template>

<script setup lang="ts">
  import { computed, ref } from "vue";
  import { PopoverPanel as HeadlessPopoverPanel } from "@headlessui/vue";
  import { icon } from "~/shared/ui/Icon";
  import Popover from "~/shared/ui/Popover";
  import { FormInput } from "~/shared/ui/Form";
  import Lucide from "~/shared/ui/Lucide";
  import {
    FieldInterfaceEmitId,
    type FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "../emits";
  import icons from "./icons.json";
  import type { FieldFormInterfaceProps } from "../types";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const searchQuery = ref("");

  const selectedIcon = computed(() => {
    return props.item.getDataProperty(props.field.name);
  });

  const filteredIcons = computed(() => {
    if (searchQuery.value.length === 0) return icons;

    return icons.map((group) => {
      const icons = group.icons.filter((icon) =>
        icon.includes(searchQuery.value.toLowerCase()),
      );

      return {
        name: group.name,
        icons,
      };
    });
  });

  const onUpdateModelValue = (value: string) => {
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: value,
      }),
    );
  };

  const isOpened = ref(false);

  const onFocus = () => {
    isOpened.value = true;
  };

  const onBlur = () => {
    isOpened.value = false;
  };
</script>
