import { type App, type ShallowRef, shallowRef } from "vue";
import {
  getInternalFieldFormInterfaces,
  registerFieldFormInterfaces,
} from "~/api/field-interfaces";
import type { IFieldForm } from "~/entities/field";
import type { FieldDisplayConfig } from "~/api/field-displays/types";
import { getInternalFieldDisplays, registerFieldDisplays } from "~/api/field-displays";

export enum AvailableExtensions {
  FIELD_FORM_INTERFACES = "fieldFormInterfaces",
  FIELD_DISPLAYS = "fieldDisplays",
}

export type ExtensionsConfig = {
  [AvailableExtensions.FIELD_FORM_INTERFACES]: IFieldForm[];
  [AvailableExtensions.FIELD_DISPLAYS]: FieldDisplayConfig[];
};

const extensions: {
  [k in keyof ExtensionsConfig]: ShallowRef<ExtensionsConfig[k]>;
} = {
  [AvailableExtensions.FIELD_FORM_INTERFACES]: shallowRef([]),
  [AvailableExtensions.FIELD_DISPLAYS]: shallowRef([]),
};

/**
 *
 * @param app
 */
export const registerExtensions = (app: App) => {
  const fieldFormInterfaces = getInternalFieldFormInterfaces();
  registerFieldFormInterfaces(fieldFormInterfaces, app);
  extensions[AvailableExtensions.FIELD_FORM_INTERFACES].value = fieldFormInterfaces;

  const internalFieldDisplays = getInternalFieldDisplays();
  registerFieldDisplays(internalFieldDisplays, app);
  extensions[AvailableExtensions.FIELD_DISPLAYS].value = internalFieldDisplays;
};

/**
 *
 * @returns
 */
export const useExtensions = (): typeof extensions => {
  return extensions;
};

export const getDisplayExtensionItem = (id: string): FieldDisplayConfig | undefined => {
  return extensions[AvailableExtensions.FIELD_DISPLAYS].value.find(
    (item) => item.id === id,
  );
};
