import { Notyf } from "notyf";
import { createToastConfig } from "./configs/toasts";

let notyfInstance: Notyf | undefined = undefined;

export const toaster = (): Notyf => {
  if (notyfInstance === undefined) {
    notyfInstance = new Notyf(createToastConfig());
  }

  return notyfInstance;
};
