<template>
  <div class="flex items-center">
    <div
      v-if="isShownAvatar && fieldData"
      class="w-9 h-9 image-fit zoom-in"
      :class="{ 'rounded-full': hasCircle }"
      @click.stop="() => navigateToUserProfile(fieldData.id)"
    >
      <img
        v-if="fieldData.avatar"
        v-tooltip.right="{
          value: username,
          disabled: !username,
        }"
        :src="avatarUrl"
        class="border-white shadow-[0px_0px_0px_2px_#fff,_1px_1px_5px_rgba(0,0,0,0.32)] dark:shadow-[0px_0px_0px_2px_#3f4865,_1px_1px_5px_rgba(0,0,0,0.32)]"
        :class="{
          'rounded-lg': !hasCircle,
          'rounded-full': hasCircle,
        }"
        :alt="username"
      />
      <UserAvatarPlaceholder v-else :userName="username"></UserAvatarPlaceholder>
    </div>

    <div
      v-if="isShownName"
      :class="{
        'ml-4': isShownAvatar,
      }"
    >
      <span v-if="username" class="font-medium whitespace-nowrap">
        {{ username }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { useRouter, type RouteLocationRaw } from "vue-router";
  import { RouteParameterNames, RoutePathNames } from "~/app/router.options";
  import UserAvatarPlaceholder from "~/shared/ui/UserAvatarPlaceholder";
  import { getDatastudioAssetUrl } from "~/entities/file";
  import type { UserID } from "~/entities/user";
  import { logger } from "~/service/logger/logger";
  import { type FieldDisplayProp } from "../types";
  import { DisplayType } from "./model/display-type";

  const props = defineProps<FieldDisplayProp>();

  const router = useRouter();

  const fieldData = computed(() => props.item.getDataProperty(props.fieldKey));

  const username = computed<string | undefined>(() => {
    if (!fieldData.value) {
      return undefined;
    }
    return `${fieldData.value.first_name} ${fieldData.value.last_name}`;
  });

  const avatarUrl = computed<string | undefined>(() => {
    if (!fieldData.value) return undefined;

    return !!fieldData.value?.avatar?.id
      ? getDatastudioAssetUrl(fieldData.value.avatar.id)
      : undefined;
  });

  const displayType = computed<DisplayType>(
    () =>
      (props.fieldInfo?.meta.displayOptions?.display as DisplayType) || DisplayType.BOTH,
  );

  const hasCircle = computed<boolean>(
    () => !!props.fieldInfo?.meta.displayOptions?.circle,
  );

  const isShownAvatar = computed<boolean>(() =>
    [DisplayType.BOTH, DisplayType.AVATAR].includes(displayType.value),
  );

  const isShownName = computed<boolean>(() =>
    [DisplayType.BOTH, DisplayType.NAME].includes(displayType.value),
  );

  async function navigateToUserProfile(userId: UserID) {
    const userRoute: RouteLocationRaw = {
      name: RoutePathNames.PROFILE,
      params: {
        [RouteParameterNames.COLLECTION_ITEM_ID]: userId,
      },
    };

    const resolvedRoute = router.resolve(userRoute);
    if (!resolvedRoute) {
      logger().warn(
        { route: userRoute },
        `[user-display]: unable to resolve user route. Navigation is impossible.`,
      );
      return;
    }

    window.open(resolvedRoute.href, "_blank");
  }
</script>
