import { inject, injectable } from "inversify";
import { type IField } from "~/entities/field";
import {
  GrafanaDynamicHeightHandler,
  GrafanaHidePanelTitleMenusHandler,
} from "~/shared/lib/iframe";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { AppType } from "../../model/app-type";
import { AbstractIFrameFieldAppHandler } from "./abstract-app-handler.abstract";

@injectable()
export class GrafanaAppHandler extends AbstractIFrameFieldAppHandler {
  constructor(
    @inject(INJECT_SYMBOLS.IFrameGrafanaDynamicHeightHandler)
    private readonly _dynamicHeightHandler: GrafanaDynamicHeightHandler,
    @inject(INJECT_SYMBOLS.IFrameGrafanaHidePanelTitleMenusHandler)
    private readonly _hidePanelTitleMenusHandler: GrafanaHidePanelTitleMenusHandler,
  ) {
    super();
  }

  handle(iframeElement: HTMLIFrameElement, field: IField): void {
    const options = field.meta?.options;
    if (!options) {
      return super.handle(iframeElement, field);
    }

    const appType = options?.embeddedApp;
    if (!appType) {
      return super.handle(iframeElement, field);
    }

    if (appType === AppType.GRAFANA) {
      if (options.hasDynamicHeight) {
        iframeElement.addEventListener("load", () => {
          this._dynamicHeightHandler.handle(iframeElement);
        });
      }

      if (options.hasDynamicHeight && !!options.initialHeight) {
        iframeElement.height = options.initialHeight.toString();
      }

      if (!!options.isTitleMenusHidden) {
        iframeElement.addEventListener("load", () => {
          this._hidePanelTitleMenusHandler.handle(iframeElement);
        });
      }
    }

    return super.handle(iframeElement, field);
  }
}
