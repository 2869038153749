import { type ComputedRef, ref, unref } from "vue";
import { watchDebounced } from "@vueuse/shared";
import isEqual from "lodash/isEqual";
import { iocContainer } from "~/inversify.config";
import { useCollecitonsStore } from "~/stores/collections";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import {
  type QueryFilter,
  QueryFilterLogicalEnum,
  type QueryMany,
} from "~/api/data-queries/types";
import { logger } from "~/service/logger/logger";
import type { IItem } from "~/entities/item";
import { type RelationManyInterface, RelationTypes } from "../entity/RelationInterface";
import { RelationsGateway } from "../gateway/RelationsGateway";

/**
 * Fetching Items data for Multiple relations. Need for receive item with junction and related primary fields with data.
 * After receiving that data we can:
 *
 * - Matching received items by junctionPrimaryId to relatedPrimaryId with available related options for correct output to render templates,
 *  when we have only junctionPrimaryId data
 *
 * @param relation
 * @param query
 * @returns
 * @todo AbortController
 */
export const useRelationMultiple = (
  parentItemId: ComputedRef<string | number>,
  relation: ComputedRef<RelationManyInterface | undefined>,
  query: ComputedRef<QueryMany<unknown>>,
  isManually: boolean = false,
) => {
  const relationsGateway = iocContainer.get<RelationsGateway>(
    INJECT_SYMBOLS.RelationsGateway,
  );

  const fetchedItems = ref<IItem[]>([]);
  const isLoading = ref<boolean>(false);
  const isFetchedOnce = ref<boolean>(false);

  const updateRelationItems = async (): Promise<IItem[] | undefined> => {
    let requestInfo: RelationRequestInfo | null = null;

    const fixedRelation = unref(relation);
    if (!fixedRelation) return undefined;

    switch (fixedRelation.type) {
      case RelationTypes.MANY_TO_MANY:
        requestInfo = handleM2MFetch(fixedRelation, unref(query)) || null;
        break;

      case RelationTypes.ONE_TO_MANY:
        requestInfo = handleO2MFetch(fixedRelation, unref(query)) || null;
        break;

      default:
        logger().warn(
          {
            relation: fixedRelation,
          },
          `unable process items. No available processings for relation type`,
        );
    }

    if (!requestInfo) return undefined;

    const parentItemPrimaryFieldName = fixedRelation.reverseJunctionField?.name as string;

    const filter: QueryFilter<unknown> = {
      [QueryFilterLogicalEnum._and]: [
        {
          [parentItemPrimaryFieldName]: parentItemId.value,
        } as unknown as QueryFilter<unknown>,
      ],
    };

    const finalQuery = {
      ...requestInfo.query,
      filter,
    };

    isLoading.value = true;

    // refactor: проверить, что подтягиваются корректные поля
    //
    // const fieldsStore = useFieldsStore();
    // const requestFields =
    //   finalQuery.fields
    //     ?.map((fieldExpression) => {
    //       return fieldsStore.getField(
    //         requestInfo!.collectionName,
    //         getRootFromFieldExpression(fieldExpression),
    //       );
    //     })
    //     .filter((field): field is IField => !!field) ?? [];

    const response = await relationsGateway.getManyByQuery(
      requestInfo.collectionName,
      finalQuery,
    );

    fetchedItems.value = response.data as IItem[];

    isLoading.value = false;
    isFetchedOnce.value = true;
  };

  watchDebounced(
    () => [parentItemId.value, relation.value, query.value],
    async (after, before) => {
      if (isManually) return;

      const [newItemId, newRelation, newQuery] = after as [
        string | number,
        RelationManyInterface | undefined,
        QueryMany<unknown>,
      ];
      const [prevItemId, prevRelation, prevQuery] = (before as [
        string | number | null,
        RelationManyInterface | undefined,
        QueryMany<unknown> | null,
      ]) || [null, undefined, null];

      if (
        isFetchedOnce.value &&
        isEqual(newItemId, prevItemId) &&
        isEqualRelation(newRelation, prevRelation) &&
        isEqual(newQuery, prevQuery)
      ) {
        return;
      }

      if (!newRelation) {
        fetchedItems.value = [];
        return;
      }

      await updateRelationItems();
    },
    {
      immediate: true,
      debounce: 500,
    },
  );

  return { fetchedItems, isLoading, updateRelationItems };

  /**
   *
   */
  function isEqualRelation(
    newRelation: RelationManyInterface | undefined,
    prevRelation: RelationManyInterface | undefined,
  ): boolean {
    return (
      newRelation?.type === prevRelation?.type &&
      newRelation?.junctionField === prevRelation?.junctionField &&
      newRelation?.reverseJunctionField === prevRelation?.reverseJunctionField
    );
  }
};

type RelationRequestInfo = {
  collectionName: string;
  query: QueryMany<unknown>;
};

function defineRelationRequestInfo(info: RelationRequestInfo): RelationRequestInfo {
  return info;
}

/**
 *
 */
function handleM2MFetch(
  relation: RelationManyInterface,
  initialQuery: QueryMany<unknown>,
): RelationRequestInfo | undefined {
  const collectionsStore = useCollecitonsStore();

  const collectionNameForRequest = relation.junctionCollection?.id;
  if (!collectionNameForRequest) return undefined;

  const fieldsForRequest = new Set(initialQuery.fields ?? []);

  const junctionPrimaryFieldName = relation.junctionCollection?.fieldsInfo.find(
    (fieldInfo) => fieldInfo.meta.isPrimaryKey,
  )?.name;

  if (!!junctionPrimaryFieldName) {
    fieldsForRequest.add(junctionPrimaryFieldName);
  }

  if (!!relation.relatedCollection) {
    const relatedCollection = collectionsStore.getCollection(relation.relatedCollection);

    if (relatedCollection === undefined) return undefined;

    const relatedPrimaryFieldName =
      relatedCollection.getPrimaryFieldInfo()?.name ?? undefined;

    if (relatedPrimaryFieldName !== undefined) {
      const relatedPrimaryFieldExpression = `${relation.junctionField?.name}.${relatedPrimaryFieldName}`;
      fieldsForRequest.add(relatedPrimaryFieldExpression);
    }
  }

  return defineRelationRequestInfo({
    collectionName: collectionNameForRequest,
    query: {
      ...initialQuery,
      fields: Array.from(fieldsForRequest),
    },
  });
}

/**
 *
 */
function handleO2MFetch(
  relation: RelationManyInterface,
  query: QueryMany<unknown>,
): RelationRequestInfo | undefined {
  const collectionsStore = useCollecitonsStore();

  const targetCollectionName = relation.collectionName;
  const targetCollection = collectionsStore.getCollection(targetCollectionName!);
  const primaryKey = targetCollection?.getPrimaryFieldInfo()!.name;

  const fields = new Set(query.fields);
  fields.add(primaryKey!);

  return defineRelationRequestInfo({
    collectionName: targetCollectionName!,
    query: {
      ...query,
      fields: Array.from(fields),
    },
  });
}
