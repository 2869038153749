import type { FirebaseOptions } from "@firebase/app";
import type { GetTokenOptions } from "@firebase/messaging";

export const tokenConfigCompiled: GetTokenOptions = {
  vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
};

export const appConfigCompiled: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};
