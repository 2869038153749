<template>
  <div class="flex flex-col gap-2">
    <AutoComplete
      ref="refAutoComplete"
      :modelValue="fieldValue"
      :suggestions="results"
      :readonly="props.field.meta.isReadonly"
      :inputClass="[
        'form-control',
        {
          'border-danger': !isValid && isTouched,
        },
      ]"
      class="w-full"
      :placeholder="getFieldPlaceholder(field)"
      optionLabel="text"
      :delay="props.field.meta.options?.rate ?? 500"
      @blur="setTouched"
      @complete="(event) => fetchResults(event.query)"
      @item-select="handleUpdateResultedFieldValue"
      @update:model-value="onUpdateModelValue"
    >
    </AutoComplete>

    <span v-if="hasRequestError" class="text-blue-600/100 p-1"
      >{{ $t("server_error_fetch") }} {{ $t("can_enter_custom_value") }}</span
    >

    <ValidationMessage v-if="!!errors.length" :errors="errors" />
  </div>
</template>

<script setup lang="ts">
  import { useRuntimeConfig } from "#app";
  import { ref, computed } from "vue";
  import axios from "axios";
  import AutoComplete, { type AutoCompleteItemSelectEvent } from "primevue/autocomplete";
  import { render } from "micromustache";
  import debounce from "lodash/debounce";
  import throttle from "lodash/throttle";
  import get from "lodash/get";
  import {
    FieldInterfaceEmitId,
    type FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "~/api/field-interfaces/emits";
  import ValidationMessage from "~/shared/ui/ValidationMessage";
  import { useFieldValidation, getFieldPlaceholder } from "~/entities/field";
  import { useAuthStore } from "~/stores/auth";
  import { logger } from "~/service/logger/logger";
  import type { FieldFormInterfaceProps } from "../types";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const runtimeConfig = useRuntimeConfig();

  const refAutoComplete = ref();

  const hasRequestError = ref<boolean>(false);

  const fieldValue = computed(() => {
    return props.item.getDataProperty(props.field.name);
  });

  const authStore = useAuthStore();
  const accessToken = computed(() => authStore.accessToken);

  const getFetchRequestHeaders = (url: string) => {
    if (url.startsWith(runtimeConfig.public.dataStudioApiUrl as string)) {
      return {
        Authorization: `Bearer ${accessToken.value}`,
      };
    }
    return {};
  };

  type FetchedOption = {
    value: unknown;
    text?: unknown;
  };

  const results = ref<FetchedOption[]>([]);

  const mapResultsToAutocompleteOptions = (
    result: Record<string, unknown>,
  ): FetchedOption => {
    const { textPath, valuePath } = props.field.meta.options!;

    if (textPath && valuePath) {
      return {
        text: get(result, textPath),
        value: get(result, valuePath),
      };
    } else if (valuePath) {
      return { value: get(result, valuePath) };
    } else {
      return { value: result };
    }
  };

  const fetchResultsRaw = async (value: string | null) => {
    if (!value) {
      results.value = [];
      return;
    }
    hasRequestError.value = false;

    const { resultsPath } = props.field.meta.options!;

    const url = render(props.field.meta.options?.url as string, { value });

    try {
      const response = await axios.get(url, {
        method: "GET",
        headers: getFetchRequestHeaders(url),
      });

      const resultsArray = resultsPath ? get(response.data, resultsPath) : response.data;

      if (!Array.isArray(resultsArray)) {
        logger().error(
          { resultsArray },
          `[InputAutocompleteInterface]: Unable to parse results. Results should be Array, but received ${typeof resultsArray}`,
        );

        return;
      }

      results.value = resultsArray.map((result: Record<string, unknown>) => {
        return mapResultsToAutocompleteOptions(result);
      });
    } catch (err: any) {
      hasRequestError.value = true;

      logger().error({ err }, "[InputAutocompleteInterface] Error on fetchResultsRaw");
    } finally {
      if (!results.value.length) {
        refAutoComplete.value.searching = false;
      }
    }
  };

  const fetchResults =
    props.field.meta.options?.trigger === "debounce"
      ? debounce(fetchResultsRaw, Number(props.field.meta.options?.rate ?? 500))
      : throttle(fetchResultsRaw, Number(props.field.meta.options?.rate ?? 500));

  const handleUpdateResultedFieldValue = (event: AutoCompleteItemSelectEvent) => {
    const updatedData = event.value["value"] ? event.value["value"] : null;

    emitUpdateFieldValue(updatedData);
  };

  const onUpdateModelValue = (value: string) => {
    emitUpdateFieldValue(value.length ? value : null);
  };

  function emitUpdateFieldValue(data: unknown) {
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: data,
      }),
    );
  }

  const { isValid, errors, isTouched, setTouched } = useFieldValidation(
    computed(() => props.item),
    computed(() => props.field),
  );
</script>

<style scoped></style>
