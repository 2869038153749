/* eslint-disable no-console */
import type { INotificationHandler, PushNotificationClickable } from "../types";

export class OpenItemViewNotificationHandler implements INotificationHandler {
  constructor() {}

  async handle(notification: PushNotificationClickable): Promise<void> {
    const handledURL = this._getItemViewPageUrl(notification.data);

    if (!handledURL) {
      console.warn(
        { notification },
        `[open-item-view-notification-handler]: Can't handle navigate for that path.`,
      );
      return;
    }

    const isAppAction = this._isAppPath(handledURL);

    if (typeof window !== "undefined") {
      const newURL = `${window.location.origin}/${handledURL}`;

      window.open(newURL, "_blank");
      return;
    } else {
      // service worker
      if (self && self instanceof ServiceWorkerGlobalScope) {
        const clientList = await self.clients.matchAll({
          type: "window",
        });

        let appClient: WindowClient | null = null;

        for (const client of clientList) {
          if (!isAppAction) continue;

          const clientURL = new URL(client.url);

          if (clientURL.pathname === handledURL) {
            appClient = client;
            return;
          }
        }
        try {
          if (!appClient) {
            await self.clients.openWindow(handledURL);
            return;
          }

          (appClient as WindowClient).focus();
          return;
        } catch (error) {
          if (error instanceof Error) {
            console.error(
              `[open-item-view-notification-handler : sw]: Unable to perform action on WindowClient. Reason: ${error.message} `,
            );
          } else {
            console.error(
              `[open-item-view-notification-handler : sw]: Unexpected error. Reason: ${error} `,
            );
          }
        }
      }
    }
  }

  private _isAppPath(pathOrUrl: string): boolean {
    return /^\/.*/gm.test(pathOrUrl);
  }

  private _getItemViewPageUrl(data: Record<string, any>): string | false {
    if (!data.collection || !data.record_id) {
      console.error(`Missed 'collection' or 'record_id' option for navigate from push.`);
      return false;
    }
    return `c/${data.collection}/${data.record_id}`;
  }
}
