import { injectable } from "inversify";
import { Observable, Subject, Subscription, filter, tap } from "rxjs";
import type { AppEvent } from "./types";

@injectable()
export class AppEventBus {
  private _events: Subject<AppEvent>;

  constructor() {
    this._events = new Subject();
  }

  listen(
    matcher: (event: AppEvent) => boolean,
    handler: (event: AppEvent) => void,
  ): Subscription {
    return this._events.asObservable().pipe(filter(matcher), tap(handler)).subscribe();
  }

  observe(): Observable<AppEvent> {
    return this._events.asObservable();
  }

  dispatch(event: AppEvent) {
    this._events.next(event);
  }
}
