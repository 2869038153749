import { defineNuxtPlugin } from "#app";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";

export default defineNuxtPlugin({
  async setup(nuxtApp) {
    nuxtApp.vueApp.use(PrimeVue).use(ConfirmationService).directive("tooltip", Tooltip);
  },
});
