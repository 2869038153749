import revive_payload_client_4sVQNw7RlN from "/var/intelvision/digibms-portal/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/intelvision/digibms-portal/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/intelvision/digibms-portal/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/intelvision/digibms-portal/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/intelvision/digibms-portal/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/intelvision/digibms-portal/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/intelvision/digibms-portal/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/var/intelvision/digibms-portal/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_yfWm7jX06p from "/var/intelvision/digibms-portal/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/intelvision/digibms-portal/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_ioc_register_plugin_9rs8ERc1sV from "/var/intelvision/digibms-portal/src/plugins/0.ioc-register.plugin.ts";
import _1_errorHandlerPlugin_iDaMKS3wdI from "/var/intelvision/digibms-portal/src/plugins/1.errorHandlerPlugin.ts";
import _2_appPluginsPlugin_CV3MolEjCi from "/var/intelvision/digibms-portal/src/plugins/2.appPluginsPlugin.ts";
import _3_processEnvFallbackPlugin_WzmraN1FIj from "/var/intelvision/digibms-portal/src/plugins/3.processEnvFallbackPlugin.ts";
import _4_initialPlugin_kAtlcOStit from "/var/intelvision/digibms-portal/src/plugins/4.initialPlugin.ts";
import _5_openTelemetryPlugin_n3uOAKCd4j from "/var/intelvision/digibms-portal/src/plugins/5.openTelemetryPlugin.ts";
import _6_fieldTransformers_lymHdOHzBT from "/var/intelvision/digibms-portal/src/plugins/6.fieldTransformers.ts";
import firebaseMessaging_gzlJx1za2g from "/var/intelvision/digibms-portal/src/plugins/firebaseMessaging.ts";
import registerFeaturesPlugin_l1Owguvq4w from "/var/intelvision/digibms-portal/src/plugins/registerFeaturesPlugin.ts";
import registerPiniaSubscribers_EiVY7vugWt from "/var/intelvision/digibms-portal/src/plugins/registerPiniaSubscribers.ts";
import registerSystemReactions_GHioKpkAdq from "/var/intelvision/digibms-portal/src/plugins/registerSystemReactions.ts";
import socketConnectionsPlugin_BfNign1i6V from "/var/intelvision/digibms-portal/src/plugins/socketConnectionsPlugin.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _0_ioc_register_plugin_9rs8ERc1sV,
  _1_errorHandlerPlugin_iDaMKS3wdI,
  _2_appPluginsPlugin_CV3MolEjCi,
  _3_processEnvFallbackPlugin_WzmraN1FIj,
  _4_initialPlugin_kAtlcOStit,
  _5_openTelemetryPlugin_n3uOAKCd4j,
  _6_fieldTransformers_lymHdOHzBT,
  firebaseMessaging_gzlJx1za2g,
  registerFeaturesPlugin_l1Owguvq4w,
  registerPiniaSubscribers_EiVY7vugWt,
  registerSystemReactions_GHioKpkAdq,
  socketConnectionsPlugin_BfNign1i6V
]