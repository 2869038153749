import { type RestClient, readSingleton } from "@directus/sdk";
import { inject, injectable } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { type IItem, castDirectusItemToEntity } from "~/entities/item";
import type { IPageViewsGateway } from "./IPageViewsGateway";
import type { Logger } from "pino";

@injectable()
export class PageViewsGateway implements IPageViewsGateway {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private readonly _restClient: RestClient<any>,
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  /**
   *
   * @throws {Error}
   */
  async getViewData(collection: string): Promise<IItem> {
    try {
      const directusItem = await this._restClient.request(readSingleton(collection));
      const item = castDirectusItemToEntity(collection, directusItem);

      return item;
    } catch (err) {
      this._logger.error(
        {
          err,
          collection,
        },
        `[pageviews]: unable to receive view data. See "err" property for details.`,
      );

      throw err;
    }
  }
}
