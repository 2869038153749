import dayjs from "dayjs";
import type { FieldFunction } from "@directus/types";

export const magicFunctions: Record<FieldFunction, (val: any) => any> = {
  year,
  month,
  week,
  day,
  weekday,
  hour,
  minute,
  second,
  count,
};

function year(value: string): number {
  return dayjs(value).year();
}

function month(value: string): number {
  return dayjs(value).month() + 1;
}

function week(value: string): number {
  return dayjs(value).week();
}

function day(value: string): number {
  return dayjs(value).date();
}

function weekday(value: string): number {
  return dayjs(value).day();
}

function hour(value: string): number {
  return dayjs(value).hour();
}

function minute(value: string): number {
  return dayjs(value).minute();
}

function second(value: string): number {
  return dayjs(value).second();
}

function count(value: any): number | null {
  return Array.isArray(value) ? value.length : null;
}
