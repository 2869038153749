import { defineNuxtPlugin } from "#app";
import { registerSystemReactions } from "~/entities/system-reactions";
import { logger } from "~/service/logger/logger";
import getBuildingBasicReactions from "~/app/config/app-variants/buildings-basic/system-reactions";
import getCoreReactions from "~/app/config/core/system-reactions";

export default defineNuxtPlugin(async () => {
  // todo: динамическое получение требуемых app variants
  // todo: проверка на дубликаты по имени
  const reactionsForRegister = (
    await Promise.all([getCoreReactions(), getBuildingBasicReactions()])
  ).flat();

  registerSystemReactions(reactionsForRegister)
    .then(() => logger().debug(`[system-reactions] reactions have registered`))
    .catch((err) =>
      logger().error({ err }, `[system-reactions] reactions hasn't registered`),
    );
});
