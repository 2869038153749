import { defineNuxtPlugin } from "#app";
import { MutationType } from "pinia";
import { getMessaging } from "firebase/messaging";
import { onMessage } from "firebase/messaging";
import { NotyfEvent } from "notyf";
import { logger } from "~/service/logger/logger";
import {
  createNotificationFromMessage,
  getMessagingToken,
  useFirebaseStore,
} from "~/shared/api/firebase";
import { requestNotificationsPermission } from "~/shared/lib/web-permissions/request-notifications-permission";
import { toaster } from "~/service/toaster";
import { registerServiceWorker } from "~/app/lib/service-workers";
import { appConfigCompiled } from "~/shared/api/firebase/api/config";
import { PushNotificationController } from "~/shared/api/firebase";

export default defineNuxtPlugin(() => {
  const firebaseStore = useFirebaseStore();

  firebaseStore.$subscribe(async (mutation) => {
    if (mutation.type === MutationType.patchObject && !!mutation.payload.firebaseApp) {
      const firebaseApp = firebaseStore.currentFirebaseApp;
      const messaging = getMessaging(firebaseApp);

      if (!firebaseApp || !appConfigCompiled) {
        return;
      }

      await requestNotificationsPermission();

      onMessage(messaging, (message) => {
        logger().info(
          { message },
          `[firebase-messaging]: Received foreground push message.`,
        );

        const webPushNotification = createNotificationFromMessage(message);

        if (!webPushNotification) return;

        const toastMessage = `${webPushNotification.title ?? ""}.\n ${
          webPushNotification.body ?? ""
        }`;

        const controller = new PushNotificationController();

        toaster()
          .success(toastMessage)
          .on(NotyfEvent.Click, () => {
            controller.handle(webPushNotification);
          });
      });

      const serviceWorkerRegistration = await registerServiceWorker();

      if (serviceWorkerRegistration !== undefined) {
        try {
          await getMessagingToken(serviceWorkerRegistration);
        } catch (error) {
          // @ts-expect-error
          logger().warn(error.message);
        }
      }
    }
  });
});
