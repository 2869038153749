import { type RouteLocationNormalizedLoaded } from "vue-router";

export const parseRoutePathToParts = (route: RouteLocationNormalizedLoaded): string[] => {
  try {
    const routePathParts: string[] = route.fullPath.split("/");

    const breadbrumbsPath: string[] = [];

    routePathParts
      .filter((part: string) => part !== "")
      .reduce((prevParts, currentPart) => {
        breadbrumbsPath.push(prevParts + currentPart);
        return prevParts.endsWith("/")
          ? prevParts + currentPart + "/"
          : prevParts + "/" + currentPart + "/";
      }, "");

    return breadbrumbsPath;
  } catch (error) {
    throw error;
  }
};
