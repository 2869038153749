import { inject, injectable } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { IFrameSizer, GrafanaDomFinder } from "../../lib";
import type { IFrameHandler } from "../types";
import type { Logger } from "pino";

@injectable()
export class GrafanaDynamicHeightHandler implements IFrameHandler {
  private _dashboardContainerObserver: MutationObserver | null = null;

  constructor(
    @inject(INJECT_SYMBOLS.GrafanaDomFinder)
    private readonly _grafanaDomFinder: GrafanaDomFinder,
    @inject(INJECT_SYMBOLS.IFrameSizer)
    private readonly _iframeSizer: IFrameSizer,
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  handle(iframeElement: HTMLIFrameElement): void {
    const bodyElement = this._grafanaDomFinder.getBodyElement(iframeElement);
    if (!bodyElement) return;

    // todo: требуется проверить утечки памяти при множественном
    //  вызове метода handle.
    const bodyObserver = new MutationObserver((mutationList) =>
      this._updateIFrameHeightOnResizeDashboardContainer(iframeElement, mutationList),
    );
    bodyObserver.observe(bodyElement, {
      attributes: false,
      subtree: true,
      childList: true,
    });

    this._logger.debug(
      `[iframe:grafana:dynamic-height-handler]: registered new body container MutationObserver`,
    );
  }

  private _updateIFrameHeight(
    iframeElement: HTMLIFrameElement,
    containerElement: HTMLElement,
  ) {
    this._iframeSizer.setHeightFromCustomSelector(iframeElement, containerElement, 75);

    this._logger.debug(`[iframe:grafana:dynamic-height-handler]: iframe height updated`);
  }

  /**
   * Обновляем высоту IFrame при изменении размера виджетов
   * внутри контейнера для дэшборда
   */
  private _updateIFrameHeightOnResizeDashboardContainer(
    iframeElement: HTMLIFrameElement,
    mutationList: MutationRecord[],
  ) {
    const mutationWithDashboardContainer = mutationList
      .filter((mutation) => mutation.type === "childList")
      .find((mutation) => {
        return !!this._getDashboardContainerElementFromMutation(mutation);
      });
    if (!mutationWithDashboardContainer) return;

    if (!!this._dashboardContainerObserver) {
      this._dashboardContainerObserver.disconnect();

      this._logger.debug(
        `[iframe:grafana:dynamic-height-handler]: previous dashboard container MutationObserver disconnected`,
      );
    }

    const dashboardContainerElement = this._getDashboardContainerElementFromMutation(
      mutationWithDashboardContainer,
    ) as HTMLElement;

    this._updateIFrameHeight(iframeElement, dashboardContainerElement);

    this._dashboardContainerObserver = new MutationObserver(() => {
      this._updateIFrameHeight(iframeElement, dashboardContainerElement);
    });
    this._dashboardContainerObserver.observe(dashboardContainerElement, {
      attributes: true,
      subtree: false,
      childList: false,
    });

    this._logger.debug(
      `[iframe:grafana:dynamic-height-handler]: registered new dashboard container MutationObserver`,
    );
  }

  private _getDashboardContainerElementFromMutation(
    mutation: MutationRecord,
  ): HTMLElement | null {
    for (const el of mutation.addedNodes) {
      const dashboardContainerElement = (<HTMLElement>el).querySelector<HTMLElement>(
        this._grafanaDomFinder.dashboardContainerSelector,
      );

      if (!!dashboardContainerElement) return dashboardContainerElement;
    }

    return null;
  }
}
