import { RouteParameterNames, RoutePathNames } from "~/app/router.options";
import type { RouteLocationRaw } from "vue-router";

export function getItemRoute(
  collectionName: string,
  itemId: string | number,
): RouteLocationRaw {
  return {
    name: RoutePathNames.ITEM_PAGE,
    params: {
      [RouteParameterNames.COLLECTION_NAME]: collectionName,
      [RouteParameterNames.COLLECTION_ITEM_ID]: itemId,
    },
  };
}
