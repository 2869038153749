<template>
  <div>
    <PInlineMessage v-if="notice !== undefined" :severity="notice.severity">
      {{ notice.message }}
    </PInlineMessage>

    <PDropdown
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :disabled="props.field.meta.isReadonly"
      :showClear="props.field.meta.isNullable"
      :inputId="props.field.name"
      :modelValue="$props.item.getDataProperty(props.field.name)"
      :filter="true"
      :filterMatchMode="'contains'"
      :editable="false"
      :resetFilterOnHide="false"
      :tabindex="props.field.meta.sortPosition"
      :aria-label="getFieldLabel(props.field)"
      :class="[
        'w-full',
        {
          '!border-danger': !isValid && isTouched,
        },
      ]"
      :inputClass="['form-select']"
      v-on="eventListeners"
      @blur="setTouched"
    >
    </PDropdown>

    <ValidationMessage v-if="!!errors.length" :errors="errors" />
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from "#i18n";
  import { computed, shallowRef, watch } from "vue";
  import PDropdown, { type DropdownChangeEvent } from "primevue/dropdown";
  import PInlineMessage from "primevue/inlinemessage";
  import { logger } from "~/service/logger/logger";
  import { type NoticeMessage, NoticeSeverityEnum } from "~/service/notice/types";
  import { defineNoticeMessage } from "~/service/notice/notices";
  import { getFieldLabel, useFieldValidation } from "~/entities/field";
  import ValidationMessage from "~/shared/ui/ValidationMessage";
  import { normalizeTranslationString } from "~/shared/lib/string";
  import { FieldInterfaceEmitId, type FieldInterfaceEmits } from "../../emits";
  import type { FieldFormInterfaceProps } from "../../types";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const { t } = useI18n();

  const notice = shallowRef<NoticeMessage | undefined>(undefined);

  const options = computed(() => {
    if (!("choices" in props.field.meta.options)) {
      return [];
    }

    return (
      props.field.meta.options.choices?.map((choice) => ({
        value: choice.value,
        label: normalizeTranslationString(choice.text),
      })) ?? []
    );
  });

  watch(
    () => props.field.meta?.options,
    (options) => {
      if (!("choices" in options)) {
        const errorLogMessage: string = `Not found required choices key in options. Returns array.`;
        const errorMessage: string = t("field_select_error_incorrect_choices");

        logger().error(
          {
            fieldName: props.field.name,
          },
          errorLogMessage,
        );

        notice.value = defineNoticeMessage({
          severity: NoticeSeverityEnum.ERROR,
          message: errorMessage,
        });
      } else {
        notice.value = undefined;
      }
    },
  );

  const { isValid, errors, isTouched, setTouched } = useFieldValidation(
    computed(() => props.item),
    computed(() => props.field),
  );

  const eventListeners = {
    change: (event: DropdownChangeEvent) => {
      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: event.value,
      });
    },
  };
</script>

<style scoped></style>
