import { useNuxtApp } from "#app";
import { type IField } from "../lib/types";

export const getFieldLabel = (fieldInfo: IField): string => {
  const { $i18n } = useNuxtApp();

  if (!fieldInfo.meta.translations) return $i18n.t(fieldInfo.name);

  const translation = fieldInfo.meta.translations.find(
    (translation) => translation.language === $i18n.locale.value,
  );

  return translation === undefined ? $i18n.t(fieldInfo.name) : translation.translation;
};
