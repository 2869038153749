export enum PushNotificationAction {
  OPEN_ITEM_VIEW = "OPEN_ITEM_VIEW",
}

export type PushNotification = Pick<
  Notification,
  "body" | "data" | "icon" | "lang" | "silent" | "tag" | "title"
>;

export interface PushNotificationClickable extends PushNotification {
  data: {
    click_action: PushNotificationAction;
    collection: string;
    record_id: string | number;
  };
}

export interface IPushNotificationController {
  handle(notification: PushNotification): Promise<void>;
}

export interface INotificationHandler {
  handle(notification: PushNotificationClickable): Promise<void>;
}
