import { print } from "graphql";
import { inject, injectable } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { logger } from "~/service/logger/logger";
import { LayoutPresetGraphqlResponseDto } from "../dto/layout-preset-graphql-response.dto";
import { LayoutPresetDto } from "../dto/LayoutPresetDto";
import {
  USER_COLLECTION_LAYOUT_PRESETS_QUERY,
  USER_LAYOUT_PRESETS_QUERY,
} from "../graphql/queries";
import { LayoutPresetsMapper } from "../mappers/layout-presets.mapper";
import type { GraphqlClient } from "@directus/sdk";
import type { LayoutPresetsDataGatewayInterface } from "./LayoutPresetsDataGatewayInterface";

@injectable()
export class LayoutPresetsDataGateway implements LayoutPresetsDataGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioGraphqlClient)
    private _graphqlClient: GraphqlClient<any>,
    @inject(INJECT_SYMBOLS.LayoutPresetsMapper)
    private readonly _layoutPresetsMapper: LayoutPresetsMapper,
  ) {}

  /**
   *
   * @throws {Error}
   */
  async getAllForUserCollection(
    collectionName: string,
    userId: string,
    roleId?: string | undefined,
  ): Promise<LayoutPresetDto[]> {
    try {
      const response = await this._graphqlClient.query<{
        presets: LayoutPresetGraphqlResponseDto[];
      }>(
        print(USER_COLLECTION_LAYOUT_PRESETS_QUERY),
        {
          collectionName,
          userId,
          roleId: roleId || null,
        },
        "system",
      );

      return response.presets.map((preset) =>
        this._layoutPresetsMapper.transformGraphqlToDto(preset),
      );
    } catch (err) {
      logger().error({ err }, `unable to load layout preset`);
      throw err;
    }
  }

  /**
   *
   * @throws {Error}
   */
  async getAllForUser(userId: string, roleId: string): Promise<LayoutPresetDto[]> {
    try {
      const response = await this._graphqlClient.query<{
        presets: LayoutPresetGraphqlResponseDto[];
      }>(
        print(USER_LAYOUT_PRESETS_QUERY),
        {
          userId,
          roleId,
        },
        "system",
      );

      return response.presets.map((preset) =>
        this._layoutPresetsMapper.transformGraphqlToDto(preset),
      );
    } catch (err) {
      logger().error({ err }, `unable to receive all user layout presets.`);
      throw err;
    }
  }
}
