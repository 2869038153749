import { SystemReaction } from "~/entities/system-reactions";
import { type AppEvent, AppEvents } from "~/shared/lib/app-event-bus";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import type { IPushTokenStorageDriver } from "~/shared/api/firebase";

export default new SystemReaction({
  id: "write-push-token-to-local-storage",

  scopes: [AppEvents.PUSH_TOKEN_RECIEVED],

  priority: 1,

  matcher: (event) => {
    const e = event as AppEvent<AppEvents.PUSH_TOKEN_RECIEVED>;
    if (e.event === AppEvents.PUSH_TOKEN_RECIEVED) {
      return true;
    }

    return false;
  },

  action: async (event) => {
    const e = event as AppEvent<AppEvents.PUSH_TOKEN_RECIEVED>;
    const token = e.payload.token;

    const pushTokenStorageDriver = iocContainer.get<IPushTokenStorageDriver>(
      INJECT_SYMBOLS.PushTokenStorageDriver,
    );

    pushTokenStorageDriver.writeToken(token);
  },
});
