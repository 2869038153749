<template>
  <nav
    class="w-[105px] xl:w-[260px] px-5 pb-16 overflow-x-hidden z-50 pt-32 -mt-4 hidden md:block"
  >
    <ul>
      <!-- BEGIN: First Child -->
      <template v-for="(menuItem, menuKey) in formattedMenu" :key="menuKey">
        <li>
          <Menu
            v-if="
              !!menuItem.childrens?.length ||
              (menuItem.url && isUrlPermitted(menuItem.url))
            "
            :menu="menuItem"
            :formattedMenuState="[formattedMenu, setFormattedMenu]"
            level="first"
          ></Menu>

          <!-- BEGIN: Second Child -->
          <Transition @enter="enter" @leave="leave">
            <ul
              v-if="menuItem.childrens && menuItem.activeDropdown"
              :class="[
                'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                { block: menuItem.activeDropdown },
                { hidden: !menuItem.activeDropdown },
              ]"
            >
              <li v-for="(subMenu, subMenuKey) in menuItem.childrens" :key="subMenuKey">
                <Menu
                  v-if="
                    !!subMenu.childrens?.length ||
                    (subMenu.url && isUrlPermitted(subMenu.url))
                  "
                  :menu="subMenu"
                  :formattedMenuState="[formattedMenu, setFormattedMenu]"
                  level="second"
                ></Menu>

                <!-- BEGIN: Third Child -->
                <Transition v-if="subMenu.childrens" @enter="enter" @leave="leave">
                  <ul
                    v-if="subMenu.childrens && subMenu.activeDropdown"
                    :class="[
                      'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                      'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                      { block: subMenu.activeDropdown },
                      { hidden: !subMenu.activeDropdown },
                    ]"
                  >
                    <li
                      v-for="(lastSubMenu, lastSubMenuKey) in subMenu.childrens"
                      :key="lastSubMenuKey"
                    >
                      <Menu
                        v-if="lastSubMenu.url && isUrlPermitted(lastSubMenu.url)"
                        :menu="lastSubMenu"
                        :formattedMenuState="[formattedMenu, setFormattedMenu]"
                        level="third"
                      ></Menu>
                    </li>
                  </ul>
                </Transition>
                <!-- END: Third Child -->
              </li>
            </ul>
          </Transition>
          <!-- END: Second Child -->
        </li>
      </template>
      <!-- END: First Child -->
    </ul>
  </nav>
</template>

<script setup lang="ts">
  import { ref, watch, computed } from "vue";
  import { useRoute } from "vue-router";
  import { FormattedMenu } from "~/api/menu/use-cases/FormattedMenu";
  import { MenuDto } from "~/api/menu/dto/Menu.dto";
  import {
    enter,
    findActiveMenu,
    leave,
    nestedMenu,
    setMenuInactive,
  } from "~/layouts/SideMenu/side-menu";
  import { isUrlPermitted } from "~/router/utils/isUrlPermitted";
  import Menu from "~/layouts/SideMenu/Menu.vue";

  interface Props {
    menu: MenuDto[];
  }

  const props = defineProps<Props>();

  const route = useRoute();

  const formattedMenu = ref<FormattedMenu[]>([]);

  const setFormattedMenu = (computedFormattedMenu: FormattedMenu[]) => {
    Object.assign(formattedMenu.value, computedFormattedMenu);
  };

  watch(
    () => props.menu,
    () => {
      formattedMenu.value = nestedMenu(props.menu, route);
    },
    {
      immediate: true,
    },
  );

  const computedRoute = computed(() => {
    const route = useRoute();
    return route;
  });

  watch(
    computedRoute.value,
    () => {
      setMenuInactive(formattedMenu.value);

      const activeMenu: FormattedMenu | undefined = findActiveMenu(
        formattedMenu.value,
        route,
      );

      if (!!activeMenu) {
        activeMenu.active = true;
      }
    },
    {
      immediate: true,
    },
  );
</script>

<style scoped></style>
