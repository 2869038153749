import { defineStore } from "pinia";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import type { RolesGatewayInterface } from "../api";
import type { RoleInterface } from "./interfaces";

interface State {
  roles: RoleInterface[];
}

const ROLE_STORE_ID = "rolesStore";

export const useRolesStore = defineStore(ROLE_STORE_ID, {
  state(): State {
    return {
      roles: [],
    };
  },

  getters: {
    getFirstById:
      (state) =>
      (id: string): RoleInterface | undefined => {
        return state.roles.find((role) => role.id === id);
      },
  },

  actions: {
    async initialize(): Promise<void> {
      const rolesApi = iocContainer.get<RolesGatewayInterface>(
        INJECT_SYMBOLS.RolesGatewayInterface,
      );

      this.$patch({
        roles: await rolesApi.getAll(),
      });
    },

    async deinitialize(): Promise<void> {
      this.$reset();
    },
  },
});
