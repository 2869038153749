import { navigateTo, useNuxtApp } from "#app";
import { defineStore } from "pinia";
import { Subscription, interval } from "rxjs";
import { type AuthCredentials } from "~/service/auth/types/AuthCredentials";
import { RoutePathNames } from "~/app/router.options";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { deinitialize } from "~/service/app/initialization";

type AuthState = {
  accessToken: string | undefined;
};

const AuthStoreId: string = "authStore";

let refreshTokenSub: Subscription | null = null;

export const useAuthStore = defineStore(AuthStoreId, {
  state: (): AuthState => ({
    accessToken: undefined,
  }),

  actions: {
    async authenticate(credentials: AuthCredentials): Promise<boolean> {
      const { $i18n } = useNuxtApp();
      const { client: dataStudioClient } = useDataStudioClient();

      try {
        const authResult = await dataStudioClient.login(
          credentials.email,
          credentials.password,
          {
            mode: "json",
          },
        );

        this.$patch({
          accessToken: authResult.access_token!,
        });

        logger().info(`User logged in.`);
        toaster().success($i18n.t("user_logged_in"));

        return true;
      } catch (err) {
        logger().error({ err }, `Can't login.`);
        toaster().error($i18n.t("user_login_error"));

        return false;
      }
    },

    async logout(): Promise<void> {
      const { client: dataStudioClient } = useDataStudioClient();

      try {
        await dataStudioClient.logout();

        logger().info("User logged out.");

        await deinitialize();
        await navigateTo({
          name: RoutePathNames.LOGIN,
        });
      } catch (err) {
        logger().error(
          {
            err,
          },
          `Error on user log out.`,
        );
      }
    },

    async refreshAccessToken(): Promise<void> {
      const { $i18n } = useNuxtApp();
      const { client: dataStudioClient } = useDataStudioClient();

      try {
        const accessToken = await dataStudioClient.getToken();

        this.$patch({
          accessToken: accessToken || undefined,
        });

        logger().debug({ accessToken }, `updated access token`);
      } catch (error) {
        throw createError({
          statusCode: 500,
          statusMessage: $i18n.t("error_update_access_token"),
          data: {
            error,
          },
        });
      }
    },

    async initialize(): Promise<void> {
      await this.refreshAccessToken();

      // todo: нужно обновлять токен после истечения accessToken. Смотреть expiresAt в storage
      refreshTokenSub = interval(1000 * 60 * 3).subscribe(async () => {
        await this.refreshAccessToken();
      });
    },

    async deinitialize(): Promise<void> {
      if (refreshTokenSub !== null) {
        refreshTokenSub.unsubscribe();
      }

      this.$reset();
    },
  },
});
