import { MenuDto } from "~/api/menu/dto/Menu.dto";
import { FormattedMenu } from "~/api/menu/use-cases/FormattedMenu";
import { slideUp, slideDown } from "../../utils/helper";
import type { Router, RouteLocationNormalizedLoaded } from "vue-router";

// Setup side menu
export const findActiveMenu = (
  items: FormattedMenu[],
  route: RouteLocationNormalizedLoaded,
): FormattedMenu | undefined => {
  for (const menu of items) {
    if (route.path === menu.url && !menu.ignore) return menu;

    if (!!menu.childrens.length) {
      const foundActive = findActiveMenu(menu.childrens, route);
      if (foundActive) {
        menu.activeDropdown = true;
        return foundActive;
      }

      continue;
    }
  }

  return undefined;
};

const menuSort = (a: FormattedMenu, b: FormattedMenu): number =>
  (a?.sort ?? 0) - (b?.sort ?? 0);

const transformMenuToFormatted = (dto: MenuDto): FormattedMenu => ({
  id: dto.uuid,
  label: dto.label,
  url: dto.url,
  sort: dto.sort,
  iconName: dto.icon,
  active: false,
  activeDropdown: false,
  ignore: false,
  childrens: dto.childrens.map(transformMenuToFormatted).sort(menuSort),
});

const nestedMenu = (
  menu: MenuDto[],
  route: RouteLocationNormalizedLoaded,
): FormattedMenu[] => {
  const formattedMenu: FormattedMenu[] = menu
    .map(transformMenuToFormatted)
    .sort(menuSort);

  const activeMenu: FormattedMenu | undefined = findActiveMenu(formattedMenu, route);
  if (!!activeMenu) {
    activeMenu.active = true;
  }

  return formattedMenu;
};

export const isInternalRoute = (url: string): boolean => {
  const externalProto = ["http", "https"];
  return externalProto.every((proto) => !url.startsWith(proto));
};

export const setMenuInactive = (menu: FormattedMenu[]) => {
  menu.forEach((item: FormattedMenu) => {
    item.active = false;

    if (!item.childrens.length) return;
    item.childrens.forEach((children: FormattedMenu) => {
      setMenuInactive([children]);
    });
  });
};

const linkTo = async (
  menu: FormattedMenu,
  menus: FormattedMenu[],
  route: RouteLocationNormalizedLoaded,
  router: Router,
): Promise<void> => {
  setMenuInactive(menus);

  if (!!menu.childrens.length) {
    menu.activeDropdown = !menu.activeDropdown;
    return;
  }

  if (isInternalRoute(menu.url)) {
    const activeMenu = findActiveMenu(menus, route);
    if (activeMenu) activeMenu.active = false;
    menu.active = true;
  }

  if (isInternalRoute(menu.url)) {
    await router.push(menu.url);
  } else {
    window.open(menu.url, "_blank");
  }
};

const enter = (el: HTMLElement) => {
  slideDown(el, 300);
};

const leave = (el: HTMLElement) => {
  slideUp(el, 300);
};

export { nestedMenu, linkTo, enter, leave };
