import {
  SystemReaction,
  changeContextFieldMetaProperty,
  setContextFieldValidationRule,
} from "~/entities/system-reactions";
import { type AppEvent, AppEvents } from "~/shared/lib/app-event-bus";

export default new SystemReaction({
  id: "validation-on-user-form",

  scopes: [
    AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW,
    AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW,
  ],

  priority: 0,

  matcher: (event) => {
    const e = event as AppEvent<
      AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW | AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW
    >;

    if (e.payload.collectionName === "directus_users") return true;

    return false;
  },

  action: async (event, context) => {
    changeContextFieldMetaProperty(
      {
        fieldName: "first_name",
        metaKey: "isRequired",
        value: true,
      },
      context,
    );

    changeContextFieldMetaProperty(
      {
        fieldName: "last_name",
        metaKey: "isRequired",
        value: true,
      },
      context,
    );

    changeContextFieldMetaProperty(
      {
        fieldName: "password",
        metaKey: "isRequired",
        value: true,
      },
      context,
    );

    const validPassword = {
      password: {
        _regex:
          "(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}",
      },
    };

    setContextFieldValidationRule("password", validPassword, context);

    changeContextFieldMetaProperty(
      {
        fieldName: "email",
        metaKey: "isRequired",
        value: true,
      },
      context,
    );

    const isEmail = {
      email: {
        _regex: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
      },
    };

    setContextFieldValidationRule("email", isEmail, context);
  },
});
