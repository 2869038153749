<template>
  <input
    :type="inputType"
    :placeholder="getFieldPlaceholder(field)"
    autocomplete="off"
    :value="props.item.getDataProperty($props.field.name)"
    :readonly="props.field.meta.isReadonly"
    :class="[
      'form-control',
      {
        'border-danger': !isValid && isTouched,
      },
    ]"
    :lang="locale"
    :step="computedStep"
    :disabled="props.field.meta.isReadonly"
    @blur="setTouched"
    @input="onInput"
    @change="onChange"
  />

  <ValidationMessage v-if="!!errors.length" :errors="errors" />
</template>

<script setup lang="ts">
  import { useNuxtApp } from "#app";
  import { unref } from "vue";
  import { computed } from "vue";
  import {
    FieldInterfaceEmitId,
    type FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "~/api/field-interfaces/emits";
  import ValidationMessage from "~/shared/ui/ValidationMessage";
  import { useFieldValidation, getFieldPlaceholder } from "~/entities/field";
  import type { FieldFormInterfaceProps } from "../types";

  const { $i18n } = useNuxtApp();
  const locale = unref($i18n.locale);

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const computedStep = computed(() => {
    return props.field.meta.options.step ?? "any";
  });

  const onInput = (evt: Event) => {
    const input = evt.target as HTMLInputElement;

    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: input.value,
      }),
    );
  };

  const onChange = (evt: Event) => {
    const input = evt.target as HTMLInputElement;

    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData: input.value,
      }),
    );
  };

  const inputType = computed<string>(() => {
    const numericTypes = ["bigInteger", "integer", "float", "decimal"];

    if (numericTypes.includes(props.field.type)) return "number";
    return "text";
  });

  const { isValid, errors, isTouched, setTouched } = useFieldValidation(
    computed(() => props.item),
    computed(() => props.field),
  );
</script>

<style scoped></style>
