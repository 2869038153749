import type { RelationInterface, RelationMetaInterface } from "./RelationInterface";

export default class Relation implements RelationInterface {
  constructor(
    readonly collectionName: string,
    readonly fieldName: string,
    readonly relatedCollection: string | null = null,
    readonly relatedFieldName: string | null = null,
    readonly meta: RelationMetaInterface | null = null,
  ) {}
}
