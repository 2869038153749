<script lang="ts">
  export default {};
</script>

<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-linejoin="round"
    stroke-miterlimit="2"
  >
    <path
      d="M3 3h2v2H3V3zm4 0h2v2H7V3zm0 4h2v2H7V7zm2 4H7v2h2v-2zm-2 4h2v2H7v-2zm2 4H7v2h2v-2zM5 7H3v2h2V7zm-2 4h2v2H3v-2zm2 4H3v2h2v-2zm-2 4h2v2H3v-2zM13 3h-2v2h2V3zm-2 4h2v2h-2V7zm2 4h-2v2h2v-2zm-2 4h2v2h-2v-2zm2 4h-2v2h2v-2zm2-16h2v2h-2V3zm2 4h-2v2h2V7zm-2 4h2v2h-2v-2zm2 4h-2v2h2v-2zm-2 4h2v2h-2v-2zm6-16h-2v2h2V3zm-2 4h2v2h-2V7zm2 4h-2v2h2v-2zm-2 4h2v2h-2v-2zm2 4h-2v2h2v-2z"
    />
  </svg>
</template>
