import gql from "graphql-tag";

export const REVISIONS_ITEM_QUERY = gql`
  query ItemRevisions($collection: String!, $itemId: String!, $limit: Int! = -1) {
    revisions(
      filter: { collection: { _eq: $collection }, item: { _eq: $itemId } }
      limit: $limit
      sort: ["-activity.timestamp"]
    ) {
      id
      activity {
        id
        action
        item
        timestamp
        user {
          id
          first_name
          last_name
          avatar {
            id
            title
          }
        }
      }
      collection
      item
      delta
      data
    }
  }
`;
