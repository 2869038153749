<template>
  <VFlatPickr
    :modelValue="localeModelValue"
    :class="[
      'flatpickr-vue',
      'w-full',
      'form-control',
      {
        'border-danger': !isValid && isTouched,
      },
    ]"
    :config="config"
    :disabled="props.field.meta.isReadonly"
    @blur="setTouched"
    @update:modelValue="onUpdateModelValue"
  ></VFlatPickr>

  <ValidationMessage v-if="!!errors.length" :errors="errors" />
</template>

<script setup lang="ts">
  import { ref, computed, watch } from "vue";
  import VFlatPickr from "vue-flatpickr-component";
  import {
    FieldInterfaceEmitId,
    type FieldInterfaceEmits,
    defineEmitUpdateItemFieldDataPayload,
  } from "~/api/field-interfaces/emits";
  import { useFieldValidation } from "~/entities/field";
  import ValidationMessage from "~/shared/ui/ValidationMessage";
  import { isISOString } from "~/shared/lib/date";
  import type { FieldFormInterfaceProps } from "../types";

  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();

  const currentData = computed(() => props.item.getDataProperty(props.field.name));

  const localeModelValue = computed<Date>(() => {
    const dateString = currentData.value;
    return !!dateString && isISOString(dateString) ? new Date(dateString) : dateString;
  });

  const type = computed<DatetimeType>(() => props.field.type as DatetimeType);
  const withSeconds = computed<boolean>(
    () => props.field.meta?.options?.includeSeconds ?? false,
  );

  const defaultConfig: FlatpickrOptions = {
    defaultHour: 0,
    defaultMinute: 0,
    disableMobile: false,
    mode: "single",
    time_24hr: true,
    altInput: true,
    dateFormat: "Z",
  };

  const config = ref<FlatpickrOptions>(defaultConfig);

  watch(
    () => [type.value, withSeconds.value],
    ([newType, withSeconds]) => {
      const newConfig: FlatpickrOptions = Object.assign(
        {},
        config.value,
        getExtendedConfig(newType as DatetimeType, withSeconds as boolean),
      );

      config.value = newConfig;
    },
    {
      immediate: true,
    },
  );

  const { isValid, errors, isTouched, setTouched } = useFieldValidation(
    computed(() => props.item),
    computed(() => props.field),
  );

  /**
   *
   * @param {string|null} newData может быть пустой строкой
   */
  const onUpdateModelValue = (newData: string | null) => {
    const updatedData = newData;

    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.name,
        updatedData,
      }),
    );
  };

  function getExtendedConfig(
    type: DatetimeType,
    withSeconds: boolean = false,
  ): FlatpickrOptions {
    switch (type) {
      case "date":
        return getDateConfig();

      case "dateTime":
        return getDateTimeConfig(withSeconds);

      case "time":
        return getTimeConfig(withSeconds);

      case "timestamp":
        return getTimestampConfig(withSeconds);
    }
  }

  function getDateTimeConfig(withSeconds: boolean = false): FlatpickrOptions {
    return {
      enableTime: true,
      enableSeconds: withSeconds,
      altFormat: withSeconds ? "d/m/Y H:i:S" : "d/m/Y H:i",
    };
  }

  function getTimestampConfig(withSeconds: boolean = false): FlatpickrOptions {
    return {
      enableTime: true,
      enableSeconds: withSeconds,
      altFormat: withSeconds ? "d/m/Y H:i:S" : "d/m/Y H:i",
    };
  }

  function getTimeConfig(withSeconds: boolean = false): FlatpickrOptions {
    return {
      enableTime: true,
      noCalendar: true,
      enableSeconds: withSeconds,
      altFormat: withSeconds ? "H:i:S" : "H:i",
      dateFormat: "H:i:S",
    };
  }

  function getDateConfig(): FlatpickrOptions {
    return {
      enableTime: false,
      enableSeconds: false,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
    };
  }

  type FlatpickrOptions = Partial<import("flatpickr/dist/types/options").BaseOptions>;

  type DatetimeType = "dateTime" | "date" | "time" | "timestamp";
</script>

<style scoped></style>
