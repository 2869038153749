import axios from "axios";
import { logger } from "~/service/logger/logger";

export const convertRemoteFileToBlob = async (url: string): Promise<Blob | undefined> => {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    return response.data;
  } catch (err) {
    logger().error({ err }, "Error on convertRemoteFileToBlob");
    return undefined;
  }
};
