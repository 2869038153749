<template>
  <SideMenuTooltip
    as="a"
    :content="props.menu.label"
    :class="[
      'h-[50px] flex items-center pl-5 text-slate-600 mb-1 relative rounded-xl dark:text-slate-300 transition before:transition-all',
      {
        'text-slate-600 dark:text-slate-400':
          !props.menu.active && props.level != 'first',
        'bg-slate-100 dark:bg-transparent': props.menu.active && props.level == 'first',
        'before:content-[\'\'] before:block before:inset-0 before:rounded-xl before:absolute before:border-b-[3px] before:border-solid before:border-black/[0.08] before:dark:border-black/[0.08] before:dark:bg-darkmode-700':
          props.menu.active && props.level == 'first',
        'after:content-[\'\'] after:w-[20px] after:h-[80px] after:mr-[-27px] after:bg-menu-active after:bg-no-repeat after:bg-cover after:absolute after:top-0 after:bottom-0 after:right-0 after:my-auto after:dark:bg-menu-active-dark':
          props.menu.active && props.level == 'first',
        'hover:bg-slate-100 hover:dark:bg-transparent hover:before:content-[\'\'] hover:before:block hover:before:inset-0 hover:before:rounded-xl hover:before:absolute hover:before:z-[-1] hover:before:border-b-[3px] hover:before:border-solid hover:before:border-black/[0.08] hover:before:dark:bg-darkmode-700':
          !props.menu.active && !props.menu.activeDropdown && props.level == 'first',

        // Animation
        'after:-mr-[47px] after:opacity-0 after:animate-[0.4s_ease-in-out_0.1s_active-side-menu-chevron] after:animate-fill-mode-forwards':
          props.menu.active && props.level == 'first',
      },
      props.class,
    ]"
    @click.prevent="onItemClick"
  >
    <div
      :class="{
        'text-primary z-10 dark:text-slate-300':
          props.menu.active && props.level == 'first',
        'text-slate-700 dark:text-slate-300': props.menu.active && props.level != 'first',
        'dark:text-slate-400': !props.menu.active,
      }"
    >
      <icon :name="props.menu.iconName"></icon>
    </div>
    <div
      :class="[
        'w-full ml-3 hidden xl:flex items-center',
        {
          'text-primary font-medium z-10 dark:text-slate-300':
            props.menu.active && props.level == 'first',
          'text-slate-700 font-medium dark:text-slate-300':
            props.menu.active && props.level != 'first',
          'dark:text-slate-400': !props.menu.active,
        },
      ]"
    >
      {{ props.menu.label }}

      <div
        v-if="!!props.menu.childrens.length"
        :class="[
          'transition ease-in duration-100 ml-auto mr-5 hidden xl:block',
          { 'transform rotate-180': props.menu.activeDropdown },
        ]"
      >
        <i class="fa-solid fa-chevron-down"></i>
      </div>

      <div
        v-else-if="!!props.menu.url && !isInternalRoute(props.menu.url)"
        :class="['ml-auto mr-5 xl:block']"
      >
        <i class="fa-solid fa-arrow-up-right-from-square"></i>
      </div>
    </div>
  </SideMenuTooltip>
</template>

<script setup lang="ts">
  import { useRoute, useRouter } from "vue-router";
  import { FormattedMenu } from "~/api/menu/use-cases/FormattedMenu";
  import { icon } from "~/shared/ui/Icon";
  import SideMenuTooltip from "./SideMenuTooltip.vue";
  import { linkTo, isInternalRoute } from "./side-menu";

  interface MenuProps {
    class?: string | object;
    menu: FormattedMenu;
    formattedMenuState: [
      FormattedMenu[],
      (computedFormattedMenu: FormattedMenu[]) => void,
    ];
    level: "first" | "second" | "third";
  }

  const props = defineProps<MenuProps>();

  const router = useRouter();
  const route = useRoute();

  const [formattedMenu, setFormattedMenu] = props.formattedMenuState;

  const onItemClick = async () => {
    await linkTo(props.menu, formattedMenu, route, router);
    setFormattedMenu([...formattedMenu]);
  };
</script>
