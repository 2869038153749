import { inject, injectable } from "inversify";
import { type RestClient, readRoles } from "@directus/sdk";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import type { UserRoleQueryItem } from "~/entities/user";
import { logger } from "~/service/logger/logger";
import { castRoleQueryItemToEntity } from "../api";
import type { RoleInterface } from "../model";
import type { RolesGatewayInterface } from "./interfaces";

@injectable()
export class RolesGateway implements RolesGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private readonly _restClient: RestClient<any>,
  ) {}

  /**
   *
   * @throws {Error}
   */
  async getAll(): Promise<RoleInterface[]> {
    try {
      const response = await this._restClient.request(readRoles());
      return response.map((role) => castRoleQueryItemToEntity(role as UserRoleQueryItem));
    } catch (err) {
      logger().error({ err }, "unable to receive all roles");
      throw err;
    }
  }
}
