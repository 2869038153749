import { useRelationsStore } from "~/stores/relations";
import { getFieldLocalType, FieldLocalType, useFieldsStore } from "~/entities/field";

export type RelatedCollectionData = {
  relatedCollectionName: string;
  junctionCollectionName?: string;
  path?: string[];
};

export function getRelatedCollection(
  collectionName: string,
  fieldName: string,
): RelatedCollectionData | undefined {
  const relationsStore = useRelationsStore();
  const fieldsStore = useFieldsStore();

  const relations = relationsStore.getRelationsByField(collectionName, fieldName);
  if (!relations.length) return undefined;

  const fieldInfo = fieldsStore.getField(collectionName, fieldName);
  if (!fieldInfo) return undefined;

  const fieldLocalType = getFieldLocalType(collectionName, fieldName);
  const o2mTypes = ["o2m", "m2m", "m2a", "translations", "files"];

  if (fieldLocalType && o2mTypes.includes(fieldLocalType)) {
    if (fieldLocalType === FieldLocalType.m2m)
      return {
        relatedCollectionName: relations[1].relatedCollection!,
        junctionCollectionName: relations[0].collectionName,
        path: [relations[1].fieldName],
      };

    return {
      relatedCollectionName: relations[0].collectionName,
    };
  }

  return {
    relatedCollectionName: relations[0].relatedCollection!,
  };
}
